import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logo from "../../../src/assets/images/logo.png";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import "./Footer.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import paymentMethod from "../../../src/assets/images/paymentMethod.png";

const SubscribeButton = styled(Button)({
  fontSize: 16,
  lineHeight: 1.5,
  backgroundColor: "whitesmoke",
  "&:hover": {
    backgroundColor: "whitesmoke",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "whitesmoke",
  },
});

const Footer = () => {
  // const [subscribes, setSubscribes] = useState([]);
  // useEffect(() => {
  //     fetch('http://localhost:5000/subscribes')
  //         .then(res => res.json())
  //         .then(data => setSubscribes(data))

  // }, [])

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    // const subscribeId = subscribes.map(subscribe => subscribe.subscribe);

    if (data.subscribe) {
      axios
        .post(`${process.env.REACT_APP_NODE_API_baseURL}/subscribes`, data)
        .then((res) => {
          if (res.data.insertedId) {
            alert("Added successfully");
            reset();
          }
        });
    } else {
      alert("tomar email ache");
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins", // Replace with your desired font family
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ pt: 10, pb: 4, bgcolor: "#f5f5f5" }}>
        <Container>
          <Grid
            sx={{ justifyContent: "space-between" }}
            container
            spacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <Grid
              item
              sx={{ alignItems: "center" }}
              xs={12}
              sm={6}
              md={6}
              lg={4}
            >
              <Box
                sx={{
                  width: "250px",
                  mt: -5,
                  mb: 2,
                  marginTop: "10px",
                  marginLeft: "50px",
                }}
              >
                <img src={logo} width="200px" alt="" />
              </Box>
              <Typography sx={{ fontWeight: 500, marginLeft: "50px" }}>
                Othooy was founded in 2022 by the skylight Travel Agent LTD
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={2.6}>
            <Typography
              sx={{
                lineHeight: 2,
                fontWeight: 600,
                fontSize: "20px",
                color: "#26272B",
              }}
            >
              Othooy
            </Typography>
            <Typography sx={{ lineHeight: 2 }} className="footer-list">
              B4 (4th Floor), House # 12, Road # 16/A, Gulshan 1, Dhaka - 1212,
              Bangladesh
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              http://othooy.com.bd
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              +8801910000902
            </Typography>
          </Grid> */}
            {/* <Grid item xs={12} sm={6} md={6} lg={2.6}>
            <Typography
              sx={{
                lineHeight: 2,
                fontWeight: 600,
                fontSize: "20px",
                color: "#26272B",
              }}
            >
              Skyligh Travrls Ltd
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              B4 (4th Floor), House # 12, Road # 16/A, Gulshan 1, Dhaka - 1212,
              Bangladesh
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              http://skylight.com.bd
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              +8801910000902
            </Typography>
          </Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={2.6}>
              {/* <Typography
              sx={{
                lineHeight: 2,
                fontWeight: 600,
                fontSize: "20px",
                color: "#26272B",
              }}
            >
              Othooy
            </Typography>
            <Typography sx={{ lineHeight: 2 }} className="footer-list">
              B4 (4th Floor), House # 12, Road # 16/A, Gulshan 1, Dhaka - 1212,
              Bangladesh
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              http://othooy.com.bd
            </Typography>
            <Typography className="footer-list" sx={{ lineHeight: 2 }}>
              +8801910000902
            </Typography> */}
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Grid
            container
            sx={{
              px: 5,
              pb: 5,
              pt: 3,
              mt: 5,
              // bgcolor: '#26272B',
              borderRadius: 2,
              // backgroundImage: `url('https://img.freepik.com/premium-photo/blank-rectangle-white-glowing-light-frame-dreamy-fluffy-cloud-with-aesthetic-green-neon-sky-background-abstract-minimal-natural-luxury-background-with-copy-space_36367-1302.jpg?w=740`,

              // backgroundImage: `url('https://img2.goodfon.com/wallpaper/nbig/e/96/beautiful-clouds-blue-sky.jpg')`,
              backgroundImage: `url('https://media.istockphoto.com/id/835370890/photo/sunset-sunrise-with-clouds-light-rays-and-other-atmospheric-effect.jpg?s=612x612&w=0&k=20&c=zGDOBYVFY74wX2gUgkonYGtNl1zenev5mPotAqUlJbM=')`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
            className="footer-grid"
          >
            <Grid item xs={12} sm={6} md={6} lg={2.6} sx={{ pt: 2 }}>
              <Typography
                sx={{
                  lineHeight: 2,
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "black",
                }}
              >
                Get started
              </Typography>
              <Link className="link-td-none" to="/Home">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Get started
                </Typography>
              </Link>
              <Link className="link-td-none" to="/Services">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Private jet
                </Typography>
              </Link>
              <Link className="link-td-none" to="/SignUp">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Register
                </Typography>
              </Link>
              <Link className="link-td-none" to="/login">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  LogIn
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.6} sx={{ pt: 2 }}>
              <Typography
                sx={{
                  lineHeight: 2,
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "black",
                }}
              >
                How it works
              </Typography>
              <Link className="link-td-none" to="/terms-Condition">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Terms & Condition
                </Typography>
              </Link>
              <Link className="link-td-none" to="/privacy-policy">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Privacy Policy
                </Typography>
              </Link>
              <Link className="link-td-none" to="/refund-policy">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Refund Policy
                </Typography>
              </Link>
              <Link className="link-td-none" to="/return-policy">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Return Policy
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.6} sx={{ pt: 2 }}>
              <Typography
                sx={{
                  lineHeight: 2,
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "black",
                }}
              >
                About us
              </Typography>
              <Link className="link-td-none" to="/Services">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Services
                </Typography>
              </Link>
              <Link className="link-td-none" to="/About">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  About us
                </Typography>
              </Link>
              <Link className="link-td-none" to="/Blogs">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  Blog
                </Typography>
              </Link>
              <Link className="link-td-none" to="/Contact">
                <Typography sx={{ lineHeight: 2 }} className="footer-box">
                  FAQs
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ pt: 2 }}>
              <Typography
                sx={{
                  lineHeight: 2,
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "black",
                }}
              >
                Newsletter
              </Typography>
              <Typography sx={{ color: "black" }}>
                Othooy was founded in 2022 by the skylight Travel Agent LTD.
              </Typography>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <input
                    className="input-field"
                    type="email"
                    name=""
                    id=""
                    placeholder="Enter Email"
                    {...register("subscribe", { required: true })}
                  />
                </Box>
                <Box>
                  <SubscribeButton
                    sx={{ width: "100%", py: "11px", color: "#26272B" }}
                    variant="contained"
                    type="submit"
                  >
                    Subscribe Us
                  </SubscribeButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Grid container spacing={2} sx={{ my: 3 }}>
            <Grid item xs={12} md={6}>
              <img src={paymentMethod} alt="paymentMethod" width={`70%`} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
              <Typography>
                © {new Date().getFullYear()} Othooy. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
