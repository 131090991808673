import { Container, Grid } from '@mui/material';
import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

const RefundAndReturnPolicy = () => {
    return (
        <>
            <Navbar />
            <Container>
                <Grid sx={ { my: 10 } } container spacing={ 4 }>

                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>রিফান্ড এবং রিটার্ন নীতি</h2>
                        আমাদের ফেরত এবং রিটার্ন নীতি 30 দিন স্থায়ী হয়। আপনার কেনাকাটার পর 30 দিন অতিবাহিত হলে, আমরা আপনাকে সম্পূর্ণ অর্থ ফেরত বা বিনিময় অফার করতে পারি না।
                        <br />
                        ফেরত পাওয়ার জন্য যোগ্য হতে, আপনার আইটেমটি অবশ্যই অব্যবহৃত এবং আপনি যে অবস্থায় পেয়েছেন সেই অবস্থায় থাকতে হবে। এটি অবশ্যই মূল প্যাকেজিংয়ে থাকতে হবে।
                        <br />
                        বিভিন্ন ধরণের পণ্য ফেরত থেকে অব্যাহতি দেওয়া হয়েছে। পচনশীল পণ্য যেমন খাদ্য, ফুল, সংবাদপত্র বা ম্যাগাজিন ফেরত দেওয়া যাবে না। আমরা এমন পণ্যগুলিও গ্রহণ করি না যা অন্তরঙ্গ বা স্যানিটারি পণ্য, বিপজ্জনক পদার্থ, বা দাহ্য তরল বা গ্যাস।
                        <br /><br />
                        <h2 style={ { fontWeight: 'bolder', color: '#ff1744', textAlign: 'center' } }>রিফান্ড</h2>
                        একবার আপনার রিটার্ন প্রাপ্ত এবং পরিদর্শন করা হলে, আমরা আপনাকে একটি ইমেল পাঠাব যাতে আপনাকে জানানো হয় যে আমরা আপনার ফেরত আইটেমটি পেয়েছি। আমরা আপনাকে আপনার অর্থ ফেরতের অনুমোদন বা প্রত্যাখ্যান সম্পর্কেও অবহিত করব।

                        আপনি অনুমোদিত হলে, আপনার ফেরত প্রক্রিয়া করা হবে, এবং একটি ক্রেডিট স্বয়ংক্রিয়ভাবে আপনার ক্রেডিট কার্ড বা অর্থপ্রদানের মূল পদ্ধতিতে একটি নির্দিষ্ট দিনের মধ্যে প্রয়োগ করা হবে।


                    </Grid>
                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>Refund and Returns Policy</h2>
                        Our refund and returns policy lasts 30 days. If 30 days have passed since your purchase, we can’t offer you a full refund or exchange.
                        <br />
                        To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
                        <br />
                        Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
                        <br /><br />
                        <h2 style={ { fontWeight: 'bolder', color: '#ff1744', textAlign: 'center' } }>Refund</h2>
                        Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.

                        If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                    </Grid>

                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default RefundAndReturnPolicy;