import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Services from '../pages/Services/Services';
import Contact from '../pages/Contact/Contact';
import AboutUs from '../pages/AboutUs/AboutUs';
import Blogs from '../pages/Blogs/Blogs/Blogs';
import BlogDetails from '../pages/Blogs/BlogDetails/BlogDetails';
import FlightSearchsv4 from '../pages/Flight/FlightSearch/FlightSearchs/FlightSearchsv4';
import Booking from '../pages/Flight/FlightSearch/Booking/Booking';
import UserLogin from '../pages/UserFile/UserLogin/UserLogin';
import UserRegister from '../pages/UserFile/UserRegister/UserRegister';
import NotFound from '../pages/NotFound/NotFound';
import PrivacyPolicy from '../pages/TermsAndCondition/PrivacyPolicy';
import TermsAndCondition from '../pages/TermsAndCondition/TermsAndCondition';
import ReturnPolicy from '../pages/TermsAndCondition/ReturnPolicy';
import RefundAndReturnPolicy from '../pages/TermsAndCondition/RefundAndReturnPolicy';
import PrivateRoute from '../pages/UserFile/PrivateRoute/PrivateRoute';
import DashboardHome from '../pages/Dashboard/DashboardV1/DashboardHomeV1/DashboardHomeV1';
import PaymentSuccessV1 from '../pages/Dashboard/DashboardV1/PaymentModal/PaymentSuccess/PaymentSuccessV1';
import Payment from '../pages/Payments/Payment/Payment';
import PaymentError from '../pages/Dashboard/DashboardV1/PaymentModal/PaymentSuccess/PaymentError';
import AirportSchedule from '../components/AirportSchedule/AirportSchedule';
import AirportScheduleV1 from '../components/AirportSchedule/AirportScheduleV1';
import FlightSearchTest from '../components/FlightSearchMenus/FlightSearch/FlightSearchTest';
import SidebarNavigationV3 from '../pages/Dashboard/DashboardV1/SidebarNavigation/SidebarNavigationV3';

const Routers = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/flighttest" element={<FlightSearchTest />} />
                <Route path="/Schedule" element={<AirportSchedule />} />
                <Route path="/SidebarNavigationV3" element={<SidebarNavigationV3 />} />
                <Route path="/airportsScheduleV1" element={<AirportScheduleV1 />} />
                <Route path="Services" element={<Services />}>  </Route>
                <Route path="About" element={<AboutUs />}></Route>
                <Route path="Contact" element={<Contact />}></Route>
                <Route path="Blogs" element={<Blogs />} />
                <Route path="Blogs/:slug" element={<BlogDetails />} />
                <Route path="Flightv4" element={<FlightSearchsv4 />} />
                <Route path="Booking" element={<Booking />} />
                <Route path="Payment/:id" element={<Payment />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-Condition" element={<TermsAndCondition />} />
                <Route path="return-policy" element={<ReturnPolicy />} />
                <Route path="refund-policy" element={<RefundAndReturnPolicy />} />
                <Route path="payment-success/:id" element={<PaymentSuccessV1 />} />
                <Route path="payment-fail" element={<PaymentError />} />
                <Route path="account">
                    <Route path="signin" element={<UserLogin />} />
                    <Route path="signup" element={<UserRegister />} />
                </Route>
                <Route path="/user/*" element={<PrivateRoute>
                    <DashboardHome />
                </PrivateRoute>}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes >

        </>
    );
};

export default Routers;