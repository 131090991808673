import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Button, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import useValue from '../../../../../../hooks/useValue';

function valuetext(value) {
    return `${value}°C`;
}

const PriceRange = ({ data }) => {

    const {
        setSelectedDepartureTime,
        setSelectedArrivalTime,
        minPrice, setMinPrice,
        maxPrice, setMaxPrice,
        setSelectedStopsCounts,
        setSelectedWeights,
        setSelectedAvailableFlightsFilter,
        lowestFare, setLowestFare, highestFare, setHighestFare,
    } = useValue({});

    // const [lowestFare, setLowestFare] = useState(null);
    // const [highestFare, setHighestFare] = useState(null);


    // const [minPrice, setMinPrice] = useState(null);
    // const [maxPrice, setMaxPrice] = useState(null);
    const handleResetFilters = () => {
        setSelectedAvailableFlightsFilter([])
        setMinPrice(lowestFare);
        setMaxPrice(highestFare);
        setSelectedDepartureTime([])
        setSelectedArrivalTime([])
        setSelectedStopsCounts([])
        setSelectedWeights([])
    }
    // console.log(selectedWeights)
    // console.log(selectedStopsCounts)

    useEffect(() => {
        let lowest = 0;
        let highest = 1000;

        data?.itineraryGroups && data?.itineraryGroups.forEach((fareData) => {
            fareData.pricingInformation.forEach((pricingInfo) => {
                const totalPrice = pricingInfo.fare.totalFare.totalPrice;

                if (lowest === 0 || totalPrice < lowest) {
                    lowest = totalPrice;
                }

                if (highest === 0 || totalPrice > highest) {
                    highest = totalPrice;
                }
            });
        });

        setLowestFare(lowest);
        setHighestFare(highest);

        setMinPrice(lowest);
        setMaxPrice(highest);

    }, [data, setMaxPrice, setMinPrice]);

    const handlePriceRangeChange = (_, newValue) => {
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
    };


    return (
        <Paper sx={ { py: 3 } }>
            <Button sx={ { width: '100%', fontWeight: 'bold' } }
                onClick={ handleResetFilters }
                variant="contained" color="error" size="small">
                Reset Sorts & Filters
            </Button>
            <Typography variant='body1' sx={ {
                p: 1
            } }>
                Price Range
            </Typography>
            <Box sx={ {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2
            } }>
                <Button variant="outlined" >
                    Min:  { minPrice !== null ? minPrice : lowestFare }
                </Button>
                <Button variant="outlined"   >
                    Max: { maxPrice !== null ? maxPrice : highestFare }
                </Button>
            </Box >
            <Slider
                getAriaLabel={ () => 'Minimum distance' }

                value={ [minPrice !== null ? minPrice : lowestFare, maxPrice !== null ? maxPrice : highestFare] }
                min={ lowestFare }
                max={ highestFare }
                onChange={ handlePriceRangeChange }
                valueLabelDisplay="auto"
                getAriaValueText={ valuetext }
                // step={ 100 }
                // marks
                disableSwap
            />
        </Paper >
    );
};

export default PriceRange;