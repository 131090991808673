import { Box, Button, Container, Grid, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../../../context/useFetch';
import axios from '../../../api/axios';
import PaymentFareSummaryAfterBooked from './PaymentFareSummaryAfterBooked';
import Footer from '../../../shared/Footer/Footer';
import PaymentSidebar from './PaymentSidebar/PaymentSidebar';
import BracBankCreditDebitCard from './BracBank/BracBankCreditDebitCard';
import useValue from '../../../hooks/useValue';
import Navbar from '../../../shared/Navbar/Navbar';
import { calculateTotalPrice } from '../../Flight/FlightSearch/FlightDiscount/FlightDiscount';
import UserBalance from './UserBalance/UserBalance';

const linkStyle = {
    marginTop: '17px',
    padding: '10px 15px',
    background: 'rgb(40 229 40 / 75%)',
    color: '#FFF',
    border: '2px solid #FFF',
    borderRadius: '5px',
    fontWeight: 'bold',
    textDecoration: "none",
    boxShadow: `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset`,
    transition: 'transform 0.5s',
    '&:hover': {
        // boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
        // border: '1px solid #333',
        color: '#333',
        transform: 'translateY(-10px)'
    },

};

const Payment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const bookiedId = location.pathname.split('/')[2];
    const { baseFare, taxFare, discountPercentage, aitPercentage } = useValue({})
    // console.log(location.pathname)
    // const { data } = useFetch(`api/v1/utickets/theTicketById?id=${bookiedId}`);
    const { privateData } = useFetch(`api/v1/managetickets/ticket?id=${bookiedId}`);


    const [secretCode, setSecretCode] = useState('')
    // const [bookedData, setBookedData] = useState('')
    const [buttonDisable, setButtonDisable] = useState(true)
    const [loading, setLoading] = useState(false)

    const bookedData = privateData;
    // console.log('data', data)
    // console.log('bookedData', data)
    // console.log(data?.status);

    /*   useEffect(() => {
          const fetchData = async () => {
              setLoading(true)
              try {
                  const response = await axios.get(`api/v1/managetickets/ticket?id=${bookiedId}`);
                  setBookedData(response.data);
                  window.localStorage.removeItem('time');
                  // window.localStorage.removeItem('selectedFlight');
  
              } catch (err) {
                  console.log(err)
              }
              setLoading(false);
          };
          fetchData();
      }, [bookiedId])
   */




    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('BracBankCreditDebitCard')
    // console.log(selectedPaymentMethod)
    const totalFares = calculateTotalPrice(baseFare, taxFare, discountPercentage, aitPercentage)
    // console.log(totalFares)
    return (
        <Box sx={ { my: 10 } }>
            <Navbar />
            <Box>
                <Container fixed>
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 2 }>
                            <Box style={ { backgroundColor: 'rgb(245 245 245 / 19%)', height: '100%' } }>
                                <PaymentSidebar
                                    selectedPaymentMethod={ selectedPaymentMethod }
                                    setSelectedPaymentMethod={ setSelectedPaymentMethod } />
                            </Box>
                        </Grid>
                        <Grid item xs={ 6 }>
                            {
                                selectedPaymentMethod === 'BracBankCreditDebitCard' ? <BracBankCreditDebitCard totalFares={ totalFares } bookiedId={ bookiedId } /> :
                                    selectedPaymentMethod === 'Balance' ? <UserBalance totalFares={ totalFares } bookiedId={ bookiedId } /> :
                                        selectedPaymentMethod === 'Nagod' ? 'Coming soon' : 'no selected'
                            }

                        </Grid>
                        <Grid item xs={ 4 }>
                            <PaymentFareSummaryAfterBooked bookedData={ bookedData } />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default Payment;