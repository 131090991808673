import React from 'react';
import styles from './PaymentSuccess.module.css';
import MyImg from '../../../../../assets/images/plane2.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useValue from '../../../../../hooks/useValue';
import useFetch from '../../../../../context/useFetch';

const PaymentSuccessV1 = () => {
    const location = useLocation();
    const bookiedId = location.pathname.split('/')[2];
    // const { data } = useFetch(`api/v1/managetickets/ticket?id=${bookiedId}`);
    const { data } = useFetch(`api/v1/managetickets/paidfare?id=${bookiedId}`);
    console.log(data.totalPayableFare);
    return (

        <div className={ `${styles.mainBody}` }>
            <section className={ `${styles.side}` }>
                <img className={ `${styles.pic}` } src={ MyImg } alt="" />
            </section>
            <section className={ `${styles.main}` }>
                <div className={ `${styles.successContainer}` }>
                    <p className={ `${styles.title}` }>Success !</p>
                    <div className={ `${styles.separator}` }></div>
                    <p className={ `${styles.welcomeMessage}` }>Your payment has been completed</p>

                    <div className={ `${styles.successForm}` }>
                        <div className={ `${styles.successControl}` }>
                            <p>You paid: &nbsp;&nbsp; { data.totalPayableFare }/= BDT successfully</p>
                        </div>
                        <div className={ `${styles.successControl}` }>
                            <p>Bank: &nbsp;&nbsp;Merchent</p>
                        </div>
                    </div>
                    <button className={ `${styles.submit}` }>
                        <Link to={ '/' }>Go Back </Link> </button>
                </div>
            </section>
        </div>

    );
};

export default PaymentSuccessV1;