import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./Slider.module.css";
import { Box, Container, Grid } from "@mui/material";
import FlightSearchV2 from "../../FlightSearchMenus/FlightSearch/FlightSearchV2";

const Slide = ({ item }) => {
  const styles = {
    backgroundImage: `url(${item.img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className={style.slide} style={styles}>
      <div className={style.slideContent}>
        {/* <h1 style={{ textWrap: "wrap", textAlign: "center" }}>{item.title}</h1> */}
        {/* <button>{item.cta}</button> */}
      </div>
    </div>
  );
};

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const handlseDotClick = (index) => {
    setCurrentIndex(index);
    if (index === 0) {
      setTranslateValue(0);
    } else {
      let t = index * 100;
      setTranslateValue(-t);
    }
  };

  useEffect(() => {
    let slider = setInterval(() => {
      if (currentIndex < slides.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setTranslateValue(-(currentIndex + 1) * 100);
      } else {
        setCurrentIndex(0);
        setTranslateValue(0);
      }
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [currentIndex]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={12} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
          <div className={style.slider}>
            <div
              className={style.sliderWrapper}
              style={{
                transform: `translateX(${translateValue}%)`,
                transition: "transform ease-out 0.4s",
              }}
            >
              {slides.map((item) => {
                return <Slide key={item.id} item={item} />;
              })}
            </div>
            <div className={style.dotsWrapper}>
              {slides.map((item, index) => {
                const dynamicClass = `${style.dot} ${
                  currentIndex === index ? style.active : ""
                }`;
                return (
                  <span
                    key={item.id}
                    // className={`dot ${currentIndex === index ? "active" : ""}`}
                    className={dynamicClass}
                    onClick={() => {
                      handlseDotClick(index);
                    }}
                  >
                    &#8226;
                  </span>
                );
              })}
            </div>
          </div>

          <Container>
            {/* <Box
              sx={{ width: "100%", marginTop: "-100px" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            > */}
            <FlightSearchV2 />
            {/* </Box> */}
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Slider;
