import React from 'react';
import logo from '../../../../assets/images/logo.png';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BookingDetails = () => {
    return (
        <div>
            <div style={ { padding: '10px', overflow: 'hidden', backgroundColor: 'white' } }>

                {/* className={`${styles.inputFieldGroup} ${"mb-3 mx-auto"}`} */ }

                <div className="container shadow" style={ { background: "##FFFFFF" } }>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-12  mt-5">
                                <img src={ logo } width="160px" alt="logo" />
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12  mt-5">
                                <p className='fs-5 fw-bold text-danger'>Ticket & <span className='text-success'>Reservation Number</span></p>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12  mt-5 ">
                                Ticket number: <span className='fw-bold'> 9979667155616</span>
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                Passengers name:
                                <p className='text-start fs-5 fw-bold'>Champ Mahfuz</p>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6" >
                                Issued by / Date
                                <p className='fs-5'>ST / 26 Apr 2023</p>
                            </div>

                        </div>
                    </div>
                    <br />

                    <div className="container p-3">
                        <div className="row">
                            <div className="col-md-12 col-md-12 col-sm-12   fs-5 ">
                                Your Airline reservation number: <span className='fw-bold'>EZEIWU</span>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="container p-4">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <p > Your ticket is stored in our booking system. This voucher is
                                    your record of your ticket and is part of your conditions of
                                    carriage.</p>
                                <p>
                                    For more information you can <u>read the notices and conditions
                                        of contract (Opens a new window).</u>
                                </p>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                You might need to show this voucher to enter the airport or to
                                prove your return or onwards travel to immigration. Check
                                with your departure airport for restrictions on the carriage of
                                liquids, aerosols and gels in hand baggage and check your
                                visa requirements.
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-3 col-lg-3 col-sm-3">
                                <p className='fw-bold'> <u>Check in online</u></p>
                                <p>Check in at the airport. Arrive up to 1 hours before your flight and follow the signs to check-in counters.</p>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-3">
                                <p className='fw-bold'><u>45 minutes</u></p>
                                <p>If you’re checking in bags, go to Airline’s check-in counters at least 45 minutes before your flight.</p>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-3">
                                <p className='fw-bold'><u>30 minutes</u></p>
                                <p>Once you have checked in, go through security. You should do this at least 30 minutes before your flight.</p>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-3">
                                <p className='fw-bold'><u>20 minutes</u></p>
                                <p>Arrive at the boarding gate 20 minutes before departure. The gates close 20 minutes before the flight leaves.</p>
                            </div>
                        </div>

                    </div>

                    <div className='container'>
                        {/* <table className="table table-bordered">
                            <thead>
                                <tr style={{ backgroundColor: '#C7C7C7' }} className='text-center'>
                                    <th scope="col ">Check in online</th>
                                    <th scope="col">45 minutes</th>
                                    <th scope="col ">30 minutes</th>
                                    <th scope="col ">20 minutes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        <p>Check in at the airport. Arrive
                                            up to 1 hours before your
                                            flight and follow the signs to
                                            check-in counters.</p>
                                    </td>
                                    <td>
                                        <p>If you’re checking in bags,
                                            go to Airline’s check-in
                                            counters at least 45 minutes
                                            before your flight.</p>
                                    </td>
                                    <td>
                                        <p>Once you have checked in,
                                            go through security. You
                                            should do this at least 30
                                            minutes before your flight.</p>
                                    </td>
                                    <td><p>
                                        Arrive at the boarding gate
                                        20 minutes before departure.
                                        The gates close 20 minutes
                                        before the flight leaves.</p></td>
                                </tr>
                            </tbody>
                        </table> */}
                        <br />

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12 fw-bold">
                                    Itinerary
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    All times shown are local for each city
                                </div>
                            </div>
                        </div>
                        <br />

                        <div className='container'>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Departing » From Sylhet Osmani, Bangladesh</th>

                                    </tr>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Leg 1 of 1 | Sylhet Osmani (ZYL) to Dhaka (DAC) | Operated by Biman Bangladesh Airlines | Duration: 00h 50m</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='continer'>
                                                <div className='row'>
                                                    <div class="col-md-3 col-lg-3 col-sm-6">
                                                        <p>Biman Bangladesh Airlines | 606 DEHAVILLAND DASH 8|DH8</p>
                                                    </div>
                                                    <div class="col-md-2 col-lg-2 col-sm-6">Check-in Opens
                                                        Sat, 29 Apr 23
                                                        20:50</div>
                                                    <div class="col-md-2 col-lg-2 col-sm-6">
                                                        Departure
                                                        Sat, 29 Apr 23
                                                        22:20
                                                    </div>
                                                    <div class="col-md-2 col-lg-2 col-sm-6">
                                                        < AirplanemodeActiveIcon />
                                                    </div>
                                                    <div class="col-md-3 col-lg-3 col-sm-6"><span className='fs-4 fw-bold'>Sylhet Osmani
                                                    </span> <br /> DepartingZYL, Osmany International Airport</div>
                                                </div>
                                                <br />
                                                <div className='continer'>
                                                    <div className='row'>
                                                        <div class="col-md-3 col-lg-3 col-sm-6">
                                                            <p className='fw-bold'>Economy</p>
                                                            seat

                                                        </div>
                                                        <div class="col-md-2 col-lg-2 col-sm-6">Status
                                                            <p className='fw-bold text-success'>Confirmed</p>
                                                        </div>
                                                        <div class="col-md-2 col-lg-2 col-sm-6">
                                                            Arrival
                                                            Sat, 29 Apr 23
                                                            23:10
                                                        </div>
                                                        <div class="col-md-2 col-lg-2 col-sm-6">
                                                            < AirplanemodeActiveIcon />
                                                        </div>
                                                        <div class="col-md-3 col-lg-3 col-sm-6"><span className='fs-4 fw-bold'>Dhaka
                                                        </span> <br /> Arriving DAC, Hazrat Shahjalal International Airport</div>
                                                    </div>
                                                </div><br />
                                                <p>Coupon validity: not before Sat, 29 Apr 23 / not after Sat, 29 Apr 23 <span className='fw-bold'>Baggage 20 KG</span></p>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br />
                        <div className='container'>
                            <h5 >Hazardous materials and substance control policy</h5>
                            <p>The carriage of certain hazardous materials like aerosols, fireworks and inflammable liquids aboard the aircraft is forbidden. Personal
                                motorised vehicles such as hoverboards, mini-Segways and smart or self-balancing wheels, are also forbidden on flights as they
                                contain large lithium batteries. For safety reasons, airlines can’t accept these as part of checked-in baggage or as hand luggage. If
                                you do not understand this restriction, further information may be obtained from your airline.</p>

                            <p>All the countries around the world has a very strict, zero-tolerance, anti-drugs policy. All airports conduct thorough searches using
                                highly sensitive equipment. Possession of any amounts of illegal drugs by travellers entering or transiting through any country will be
                                subject to punishment.</p>
                            <br />
                            <br />
                            <h5>Airline’s standard cabin baggage allowances</h5>
                            <p className='fw-bold'>Economy Class</p>
                            One piece of carry-on baggage is permitted at all airlines with maximum dimensions: 55 x 38 x 20cm (22 x 15 x 8 inches) and
                            maximum weight: 7kg (15lb).
                            Note: If you’re boarding in India, your carry-on baggage may not exceed 115cm or 45.3 inches (length + width + height). If your
                            itinerary originates from Brazil, you’re allowed a carry-on weighing 10kg (22lb).

                            <br />
                            <br />
                            <h5>First Class and Business Class:</h5>
                            Generally two pieces of carry-on baggage permitted at all airlines: one briefcase plus either one handbag or one garment bag. The
                            briefcase may not exceed 45 x 35 x 20cm (18 x 14 x 8 inches); the handbag may not exceed 55 x 38 x 20cm (22 x 15 x 8 inches); the
                            garment bag can be no more than 20cm (8 inches) thick when folded. The weight of each piece must not exceed 7kg (15lb). The total
                            combined weight of both pieces may not be more than 14kg (30lb). Infants in all cabin classes are permitted one checked-in bag that
                            may not exceed 55 x 38 x 20 cm (22 x 15 x 8 inches) in size and 23kg (50lb) where the piece concept applies, or 10kg (22lb) where
                            the weight concept applies. In addition, customers travelling with infants (and without a child seat) are permitted to bring one carry-cot
                            or one fully collapsible stroller into the cabin if there is room. If there is no space for these items in the cabin, they will have to be
                            checked. However, if checked, they will not count against your baggage allowance.
                            <br /> <br /> <br />

                            <h5>Airline’s checked baggage notification</h5>
                            Checked baggage allowances vary from airline to airline and by fare type and class of travel. Check your baggage allowance,
                            however please note that any individual item weighing more than 32kg cannot be accepted at any airline, for health and safety
                            reasons.
                        </div>
                        <br />

                        <div className="container p-4">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <p className='fw-bold'> Requirements:</p>
                                    <ul>
                                        <li>International passengers need to carry valid passport (180 days from
                                            the flight date) with them.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at
                                            the check-in counter.</li>
                                    </ul>

                                    <p className='fw-bold'> Othooy Service Charge (Refund / Re-issue):</p>
                                    <ul>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at
                                            the check-in counter.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at
                                            the check-in counter.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at
                                            the check-in counter.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at the check-in counter.</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <p className='fw-bold'>General Terms & Conditions:</p>
                                    <ul>
                                        <li>Due to Covid-19, Airline has the authority to cancel and/or reschedule flight any time.</li>
                                        <li>The destination you are travelling to might be under Covid-19 travel restriction.</li>
                                        <li>Please check health advisories before you travel.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at the check-in counter.</li>
                                        <li>Client can book tickets to worldwide using ShareTrip App and website. Client can use local debit or credit card to pay for the flight booked from ShareTrip.</li>
                                        <li>In case of fare increase by airline, client has to pay the additional amount during booking or can take refund.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at the check-in counter.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="container">
                            <div className="row">
                                <div className="col text-start">
                                    <p className='fw-bold'> Requirements:</p>
                                    <ul>
                                        <li>International passengers need to carry valid passport (180 days from
                                            the flight date) with them.</li>
                                        <li>Domestic passengers need to show a valid Govt. issued ID card at
                                            the check-in counter.</li>
                                    </ul>
                                    <p className='fw-bold'> Othooy Service Charge (Refund / Re-issue):</p>
                                    <ul>
                                        <li>Date change fee for domestic ticket: BDT 200 per Ticket.</li>
                                        <li>Date change fee for international ticket: BDT 500 per Ticket.</li>
                                        <li>Refund charge for domestic ticket: BDT 300 per Ticket.</li>
                                        <li>Refund charge for international ticket: BDT 1500 per Ticket.</li>
                                    </ul>
                                </div>
                                <div className="col text-start">
                                    <p className='fw-bold'>General Terms & Conditions:</p>
                                    <ul>
                                        <li>Due to Covid-19, Airline has the authority to cancel and/or reschedule
                                            flight any time.</li>
                                        <li>The destination you are travelling to might be under Covid-19 travel
                                            restriction.</li>
                                        <li>Please check health advisories before you travel.</li>
                                        <li>Client can book tickets to worldwide using ShareTrip App and
                                            website. Client can use local debit or credit card to pay for the flight
                                            booked from ShareTrip.</li>
                                        <li>In case of fare increase by airline, client has to pay the additional
                                            amount during booking or can take refund.</li>
                                        <li>ShareTrip does not issue one way tickets for Air India and Saudi
                                            Airlines, Malaysia & Middle Eastern Countries (labor tickets).</li>
                                        <li>ShareTrip convenience fee is non-refundable for online purchases.</li>
                                        <li>Mandatory health declaration form should be filled out within 72 hours
                                            prior to Departure time for all passengers entering Bangladesh as a
                                            part of health screening at the port of entry -
                                            https://healthdeclaration.dghs.gov.bd</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <br />
                        <br />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BookingDetails;