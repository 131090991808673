import React, { useEffect } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TablePagination from "@mui/material/TablePagination";
import { Container, Typography } from "@mui/material";
// import suppliers from "../../assets/supplier.json";
import styles from './AirportSchedule.module.css'
import { Box } from '@mui/system';
import axios from '../../api/axios';
import Loading from '../Loading/Loading';
import Loader from '../../shared/Loader';


function convertUnixTimestampToAMPM(timestamp) {
    // Convert the timestamp to milliseconds
    const timestampInMilliseconds = timestamp * 1000;

    // Create a new Date object using the timestamp
    const date = new Date(timestampInMilliseconds);

    // Get hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Convert hours to AM/PM format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12

    // Create the formatted time string
    const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    return formattedTime;
}





function Row(props) {
    const { supplier } = props;

    return (
        <React.Fragment>
            <TableRow sx={ { "&:last-child td, &:last-child th": { border: 0 } } } className={ `${styles.tableHover}` }>
                <TableCell align="center">{ convertUnixTimestampToAMPM(supplier.flight.time.scheduled.arrival) }</TableCell>
                <TableCell align="center">{ supplier.flight.identification.number.default }</TableCell>
                <TableCell align="center">{ supplier.flight.airport.origin.position.region.city }</TableCell>

                <TableCell align="center">{ supplier.flight.airline?.name }</TableCell>
                <TableCell align="center">{ supplier.flight.aircraft.model.code }</TableCell>
                <TableCell align="center">{ supplier.flight.status.text }</TableCell>
                {/* <TableCell align="center">{ supplier._id }</TableCell>
                <TableCell align="center">{ supplier.balance }</TableCell>
                <TableCell align="center">{ supplier.contact }</TableCell>
                <TableCell align="center">{ supplier.address }</TableCell>
                <TableCell align="center">{ supplier.address }</TableCell> */}

            </TableRow>
        </React.Fragment>
    );
}
const AirportSchedule = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [airportSchedule, setAirportSchedule] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const airportScheduleData = airportSchedule?.airport?.pluginData?.schedule.arrivals.data;
    // console.log(airportSchedule?.airport?.pluginData?.schedule.arrivals.data)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`/api/v1/airport-schedule`);
                setAirportSchedule(response.data);
                setLoading(false)
                window.localStorage.removeItem('time');
                // window.localStorage.removeItem('selectedFlight');

            } catch (err) {
                // console.log(err)
            }
        };
        fetchData();

    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            {
                loading ?
                    <Loader /> :
                    <Container sx={ { width: "100%" } }>
                        <Box className={ `${styles.topContainer}` } sx={ { display: "flex", my: 3 } }>
                            <Typography>
                                <AssignmentIcon className={ `${styles.assignmentIcon}` } />{ " " }
                            </Typography>
                            <Typography>
                                <span style={ { fontSize: "26px" } }>
                                    Airport Sehedule</span> <br />{ " " }
                                <span style={ { color: "#969494" } }>Airport Information of</span>
                                <span style={ { color: "#969494" } }> <b>DAC/VGHS
                                    Dhaka Shahjalal International Airport
                                    Bangladesh</b></span>
                            </Typography>
                        </Box>

                        <Box className={ `${styles.tableContainer}` }>
                            <Typography sx={ { fontWeight: "bold", textAlign: "left" } }>Arrival TO DAC</Typography>
                            <hr />
                            <TableContainer component={ Paper }>
                                <Table aria-label="simple table">
                                    <TableHead className={ `${styles.tableHeader}` }>
                                        <TableRow hover>
                                            <TableCell className={ `${styles.tableCell}` } align="center">Time</TableCell>
                                            <TableCell className={ `${styles.tableCell}` } align="center">Flight</TableCell>
                                            <TableCell className={ `${styles.tableCell}` } align="center">From</TableCell>
                                            <TableCell className={ `${styles.tableCell}` } align="center">Airline</TableCell>
                                            <TableCell className={ `${styles.tableCell}` } align="center">AirCraft</TableCell>
                                            <TableCell className={ `${styles.tableCell}` } align="center">Status</TableCell>

                                        </TableRow>
                                    </TableHead>


                                    <TableBody>
                                        { airportScheduleData && airportScheduleData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((supplier) => (
                                                <Row key={ supplier._id } supplier={ supplier } />
                                            )) }
                                        <TableRow >
                                        </TableRow>

                                    </TableBody>

                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={ [5, 10, 15] }
                                    component="div"
                                    count={ airportScheduleData && airportScheduleData.length }
                                    rowsPerPage={ rowsPerPage }
                                    page={ page }
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                />
                            </TableContainer>

                        </Box>
                    </Container>
            }
        </>

    );
};

export default AirportSchedule;