import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

const ReturnPolicy = () => {
    return (
        <Container>
            <>
                <Navbar />
                <Grid sx={ { mt: '100px' } } container spacing={ 2 }>

                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>রিফান্ড এবং রিটার্ন নীতি</h2>
                        <h6>রিফান্ড এবং রিটার্ন নীতি</h6>
                        <Typography>
                            Othooy এয়ারলাইন্সের বাতিলকরণ এবং পুনঃইস্যু নীতি অনুসরণ করে।
                            বাতিল/পুনরায় ইস্যু করার জন্য, ভ্রমণকারীদের ভ্রমণের তারিখের 48 ঘন্টা আগে Othooy-কে নিশ্চিত করার পরামর্শ দেওয়া হচ্ছে। অন্যথায়, এয়ারলাইন্সের নিয়মের উপর নির্ভর করে নো-শো চার্জ প্রযোজ্য হতে পারে।
                            বাতিল/পুনরায় ইস্যু করতে, ভ্রমণকারীদের +8801910000902 নম্বরে কল করতে হবে বা মেসেঞ্জারে নক করতে হবে। আমাদের  একটি ইমেল শুরু করবে এবং গ্রাহকের কাছ থেকে নিশ্চিত হওয়ার পরে সমস্যাটি সমাধান করবে।
                            <br />
                            এয়ারলাইন্স নীতি অনুযায়ী কোনো পরিবর্তনের ক্ষেত্রে বাতিলকরণ ফি/পুনঃইস্যু ফি এবং একটি স্ট্যান্ডার্ড সার্ভিস চার্জ প্রযোজ্য হতে পারে। বাতিলকরণ ফি/পুনঃ ইস্যু এয়ারলাইন্স দ্বারা ভাগ করা হয় এবং যে কোনো সময় পরিবর্তন হতে পারে।
                            Othooy এয়ারলাইনের উদ্ধৃত ফি ব্যতীত কোনো অতিরিক্ত সার্ভিস চার্জ নেবে না। EMI পদ্ধতির জন্য, প্রদেয় পরিমাণ থেকে প্রযোজ্য EMI চার্জ কেটে নেওয়া হবে।
                            একটি সফল বাতিলকরণের পরে ফেরত শুরু করা হবে (যদি প্রযোজ্য হয়)। ফেরত পাঠানো হবে একই চ্যানেলের মাধ্যমে যা ভ্রমণকারী অর্থপ্রদানের জন্য ব্যবহার করেছেন। চ্যানেলের প্রকারের উপর নির্ভর করে, অর্থপ্রদান অ্যাকাউন্টে প্রতিফলিত হতে এক থেকে পাঁচ কার্যদিবস লাগতে পারে।
                            *সুবিধা চার্জ অ-ফেরতযোগ্য।

                        </Typography>
                    </Grid>
                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>Refund/ Re-issue Policy</h2>
                        <h6>Flight</h6>
                        <Typography>
                            Othooy follows airline's cancellation and reissue policy.
                            To cancel/ reissue, travelers are advised to confirm Othooy 48 hours prior to the travel date. Otherwise, no-show charge might be applicable depending on the airline's rule.
                            To cancel/ reissue, travelers need to call at +8801910000902 or knock on Messenger . Our team will initiate an email and solve the issue upon confirmation from the customer.
                            Cancellation fee/ reissue fee and a Standard service charge may apply in case of any change as per airlines policy. Cancellation fee/ reissue is shared by the airlines and may get changed at any time.
                            Othooy won't charge any additional service charge apart from the airline quoted fees. For the EMI method, the applicable EMI charge will be deducted from the payable amount.
                            Refunds will be initiated (if applicable) after a successful cancellation. The refund will be sent through the same channel that the traveler has used for payment. Depending on the channel type, the payment may take one to five working days to be reflected in the account.
                            *Convenience charge is non-refundable.
                        </Typography>
                    </Grid>

                </Grid>
                <Footer />
            </>
        </Container>
    );
};

export default ReturnPolicy;