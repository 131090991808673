import React from 'react';
import styles from './InvoiceOthooy.module.css';
import logo from '../../../../../assets/images/logov2.png'
import icon1 from '../../../../../assets/images/logov2.png'
import icon2 from '../../../../../assets/images/logov2.png'

const InvoiceOthooy = () => {
    return (
        <div>
            <section className={ `${styles.mainPdWrapper}` }>
                <main>
                    <div className={ `${styles.topper}` }>
                        <div className={ `${styles.subTop1}` }>
                            <img src={ logo } alt="" />
                            <p>www.othooy.com</p>
                            <p>Skylight Travels Limited</p>
                            <p>16/A Gulsan 1</p>
                            <p>Dhaka 1212</p>
                            <br />
                        </div>

                        <div className={ `${styles.subTop2}` }>
                            <p>
                                <span style={ { color: 'rgb(255, 0, 43)' } }>Booking Id</span>: FB-SV23040124
                            </p>
                            <p>
                                <span style={ { color: 'rgb(255,0,43' } }>Date</span>:Mon 13 Apr, 23
                            </p>
                            <br />
                        </div>
                    </div>

                    <div class={ `${styles.topperNext}` }>
                        {/* <h5 >Receipt :</h5> */ }
                        <p>Receipt :</p>
                        <p >INVZGF8765</p>
                    </div>

                    <p className={ `${styles.titleBar}` }>Customer Name & Address</p>

                    {/* passenger info start */ }
                    <div className={ `${styles.passengerInfo}` }>
                        <div className={ `${styles.passengerInfoLeft}` }>
                            <p>Name</p>
                            <p>Email</p>
                            <p>Payment mode</p>
                        </div>
                        <div className={ `${styles.passengerInfoRight}` }>
                            <p>Md Rahman</p>
                            <p>rahman64@gmail.com</p>
                            <p>Online</p>
                        </div>
                    </div>
                    <br />
                    {/* passenger info end */ }

                    {/* flight info start */ }
                    <div className={ `${styles.flightHeader}` }>
                        <p>Item</p>
                        <p>Item Details</p>
                        <p>Total Amount</p>
                    </div>
                    <hr />
                    <div className={ `${styles.flightDescription}` }>
                        <div>Dhaka-Chittagong</div>
                        <div style={ { marginRight: '40px' } }>Pnr airline Journey</div>
                        <div style={ { marginRight: '50px' } }>BDT-2509</div>
                    </div>
                    <br />
                    <hr />
                    <br /><br />
                    <hr />

                    <div>

                        <div className={ `${styles.totalAmount}` }>
                            <p style={ { marginRight: '100px' } }>Sub Total</p>
                            <p className={ `${styles.amount}` }>BDT: 25,039</p>
                            {/* <p>Convenience Charge:<span>BDT 501</span></p> */ }
                        </div>

                        <div className={ `${styles.totalAmount}` }>
                            <p >Convenience Charge:</p>
                            <p className={ `${styles.amount}` }> <span style={ { marginRight: '30px' } }>BDT 501</span></p>
                        </div>

                        <hr />
                        <div className={ `${styles.totalAmount}` }>
                            <p>Total Payment :</p>
                            <p className={ `${styles.amount}` }>BDT 5059</p>
                        </div>

                    </div>

                    {/* <div className={`${styles.subTotal}`}>
                        <div></div>

                        <div >
                            <p>Sub Total <span>BDT: 25,039</span> </p>
                            <p>Convenience Charge:<span>BDT 501</span></p>
                            <br />
                            <hr />
                            <p>Total Payment</p>
                            <span>BDT 5059</span>
                        </div>
                    </div> */}
                    {/* flight info end */ }

                </main>
                <br />

                <h6 style={ { textAlign: 'center', fontWeight: 'bold', color: 'rgb(4,9,11)' } }>
                    Need help?
                </h6>

                <footer>
                    <div className={ `${styles.footer}` }>
                        <div style={ { display: 'flex' } }>
                            <img className={ `${styles.icon}` } src={ icon1 } alt="" />
                            <p style={ { marginTop: '6px' } }>+8876578684874</p>
                        </div>
                        <div style={ { display: 'flex' } }>
                            <img className={ `${styles.icon}` } src={ icon2 } alt="" />
                            <p style={ { marginTop: '6px' } }>othooy.com</p>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                            <img className={`${styles.icon}`} src={icon3} alt="" />
                            <p style={{ marginTop: '6px' }}>Skylight Travels</p>
                        </div> */}
                    </div>
                </footer>
            </section>
        </div>
    );
};

export default InvoiceOthooy;
