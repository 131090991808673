import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

const TermsAndCondition = () => {

    return (
        <>
            <Navbar />
            <Container>
                <Grid sx={ { mt: '100px' } } container spacing={ 2 }>
                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>শর্তাবলী</h2>
                        <Typography>
                            othooy.com-এ স্বাগতম। Othooy হল Skyliht Travels একটি অঙ্গপ্রতিষ্ঠান। এই সাইটটি শুধুমাত্র গ্রাহকদের ভ্রমণ সংক্রান্ত তথ্য, ট্যুর প্যাকেজ সংগ্রহ এবং ভ্রমণ-সম্পর্কিত পণ্য ও পরিষেবার প্রাপ্যতা নির্ধারণে, বৈধ রিজার্ভেশন  ভ্রমণ সরবরাহকারীদের সাথে ব্যবসা লেনদেন করতে সহায়তা করার জন্য সাইটের ব্যবহার নিম্নলিখিত নিয়ম ও শর্তাবলী দ্বারা নিয়ন্ত্রিত হয়।সাইটটি ব্যবহার করে, তথ্য পর্যালোচনা করা হোক বা ভ্রমণ সংরক্ষণ বুক করা হোক (এই সাইট বা টেলিফোন বা আমাদের কল সেন্টারে ইমেলের মাধ্যমে), আমাদের হেল্পলাইন নম্বর হল +8801910000902 ।বা অন্য কোনও উদ্দেশ্যে, আপনি যদি এই শর্তাবলীর সাথে এবং সম্মত হন আমাদের সাথে করা যেকোনো ভ্রমণ করতে পারেন
                            । আপনি যদি এই শর্তাবলীর কোনো অংশের সাথে একমত না হন, তাহলে আপনি অবশ্যই আমাদের সাথে সাইট ব্যবহার করবেন না বা ভ্রমণ রিজার্ভেশন বুক করবেন না। আমরা পূর্ব ঘোষণা ছাড়াই যে কোনো সময় এই শর্তাবলী সংশোধন করার অধিকার সংরক্ষণ করি। সমস্ত সংশোধিত শর্তাবলী স্বয়ংক্রিয়ভাবে কার্যকর হয় যখন এই শর্তাবলী আপডেট করা হয়। নিয়ম ও শর্তাবলীতে পরিবর্তন পোস্ট করার পরে আপনার সাইটটির ক্রমাগত ব্যবহারের অর্থ হল আপনি সেই পরিবর্তনগুলিকে স্বীকার করেছেন৷ যেকোনো পরিবর্তন পর্যালোচনা করতে অনুগ্রহ করে পর্যায়ক্রমে এই পৃষ্ঠায় ফিরে যান। othooy.com।
                        </Typography>
                    </Grid>
                    <Grid item md={ 6 } xs={ 12 }>
                        <h2 style={ { textAlign: 'center', fontWeight: 'bolder', color: '#ff1744' } }>Terms and Conditions</h2>
                        <Typography>
                            Welcome to othooy.com.Othooy is a sister concern of Skyliht Travels Our website (collectively, the "Site"). This Site is provided by us solely to assist customers in gathering travel information,tour packages, determining the availability of travel-related goods and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for no other purposes. Use of the Site is governed by the following Terms & Conditions. By using the Site, whether to review information or to book travel reservations (through this Site or telephone or email with our call center),Our helpline number is +8801910000902  or for any other purpose, you agree to these Terms & Conditions and agree they control any travel reservations made with us. If you do not agree with any part of these Terms & Conditions, you must not use the Site or book travel reservations with us. We reserve the right to amend these Terms & Conditions at any time without prior notice. All amended terms automatically take effect when these Terms & Conditions are updated. Your continued use of the Site following the posting of changes to the Terms & Conditions will mean you accept those changes. Please return to this page periodically to review any changes. othooy.com. operates with its own brand name “Othooy” having its motto “all about your journey”.
                            <br />
                            Any and all statements made in these Terms & Conditions related to Othooy Ltd. are also made on behalf of our affiliates, subsidiaries and parent companies, including all disclaimers.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default TermsAndCondition;