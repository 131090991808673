import React from 'react';
import PriceRange from '../SearchSidebar/PriceRange/PriceRange';
import Schedules from '../SearchSidebar/PriceRange/Schedule/Schedules/Schedules';
import SortAndFilter from '../SearchSidebar/SortAndFilter/SortAndFilter';
import CountdownTimerV3 from '../../../../../components/TimerComponents/CountdownTimerV3';

// selectedDepartureTime, setSelectedDepartureTime,
//             selectedArrivalTime, setSelectedArrivalTime,
//             minPrice, setMinPrice,
//             maxPrice, setMaxPrice

const SearchSidebar = ({ data }) => {
    /* const {
        selectedDepartureTime, setSelectedDepartureTime,
        selectedArrivalTime, setSelectedArrivalTime,
        minPrice, setMinPrice,
        maxPrice, setMaxPrice
    } = useValue({}); */

    return (
        <div>
            {/* <Clock /> */ }
            {/* {
                data?.itineraryGroups && <TimeCountdownv2 />
            } */}
            {
                data?.itineraryGroups && <CountdownTimerV3 />
            }
            <PriceRange data={ data } />
            <Schedules data={ data } />
            <SortAndFilter data={ data } />
        </div>
    );
};

export default SearchSidebar;
