import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import {
  Card,
  CardMedia,
  Container,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

const ExploreBangladesh = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Poppins", // Replace with your desired font family
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ marginBottom: "100px" }}>
        <Box sx={{ flexGrow: 1, marginTop: "50px" }}>
          <Typography>
            <h4 style={{ fontSize: "2rem", fontWeight: "bold" }}>
              Explore The world
            </h4>
            <p style={{ fontSize: "15px" }}>
              Prepare to experience Bangladesh's rich culture and explore the
              majestic beauties of Cox’s Bazar, Sylhet, Bandarban, Sajek Valley,
              Rangamati etc. Plan your trip now!
            </p>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  cursor: "pointer",
                  position: "relative",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://i.ibb.co/1v5DMSQ/package5.jpg`}
                  alt="Paella dish"
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: 2,
                    // background: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  India Gate
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  cursor: "pointer",
                  position: "relative",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://i.ibb.co/B20P9mF/album3.webp`}
                  alt="Paella dish"
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: 2,
                    // background: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Shylet
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  cursor: "pointer",
                  position: "relative",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://i.ibb.co/FngQy46/package8.webp`}
                  alt="Paella dish"
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: 2,
                    // background: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Maldives
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Card
                sx={{
                  maxWidth: 345,
                  cursor: "pointer",
                  position: "relative",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={`https://i.ibb.co/2hj2ZNG/paackage4.webp`}
                  alt="Paella dish"
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: 2,
                    // background: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Bali
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ExploreBangladesh;
