import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './UserRegister.module.css';
import { Alert, Button, CircularProgress, Container, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useEffect } from 'react';
import Navbar from '../../../shared/Navbar/Navbar';
import Loading from '../../../components/Loading/Loading';
import useAuth from '../../../hooks/useAuth';
const UserRegister = () => {

    const [loginData, setLoginData] = useState({});

    const { user, registeredUser, isLoading, signInWithGoogle, authError } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    let from = location?.state?.from?.pathname || '/';
    useEffect(() => {
        if (user?.email) {
            navigate(from, { replace: true });
        }
    }, [user, from, navigate])


    const handleOnBlur = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = { ...loginData };
        newLoginData[field] = value;
        console.log(newLoginData);
        setLoginData(newLoginData);
    }
    const handleLoginSubmit = e => {
        if (loginData.password !== loginData.password2) {
            alert('Password did not match')
            return
        }
        registeredUser(loginData.email, loginData.password, loginData.name, navigate);
        e.preventDefault();
    }

    const handleGoogleSignIn = () => {
        signInWithGoogle(location, navigate)
    }


    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            <Navbar />
            <Container className={ `${'my-5'} ${styles.body1}` }>
                <div className={ `${styles.registerContainer}` }>
                    <div className={ `${styles.registerBox}` }>
                        <div className={ `${styles.left1}` }></div>
                        <div className={ `${styles.right1}` }>
                            <h2>Register</h2>
                            { !isLoading && <form onSubmit={ handleLoginSubmit }>
                                <input
                                    name="name" onBlur={ handleOnBlur }
                                    type="text" className={ `${styles.field}` } placeholder="Name" />

                                <Typography sx={ { mb: '5px' } }>

                                </Typography>

                                {/* Email */ }
                                <input
                                    name="email" onBlur={ handleOnBlur }
                                    type="email" className={ `${styles.field}` } placeholder="Your Email " />

                                <Typography sx={ { mb: '5px' } }>

                                </Typography>

                                {/* password */ }
                                <input
                                    name="password" onBlur={ handleOnBlur }
                                    type="password" className={ `${styles.field}` } placeholder="Your Password " />

                                <Typography sx={ { mb: '5px' } }>

                                </Typography>
                                <input
                                    name="password2" onBlur={ handleOnBlur }
                                    type="password" className={ `${styles.field}` } placeholder="Re-Type Password " />

                                <Typography sx={ { mb: '5px' } }>
                                    {/* {errors.password?.type === 'required' && <Alert severity="error">{errors.password.message}</Alert>}
                                    {errors.password?.type === 'minLength' && <Alert severity="error">{errors.password.message}</Alert>} */}
                                </Typography>


                                <Button sx={ { mt: '20px' } } type="submit" value='Register' variant="contained" color="success">Register</Button>
                                <br />
                                <br />
                                <Button
                                    variant="contained" onClick={ handleGoogleSignIn } startIcon={ <GoogleIcon /> }>
                                    Google SignUp
                                </Button>

                            </form> }
                            { isLoading && <CircularProgress /> }
                            { user?.email && <Alert severity="success">User Created Successfully</Alert> }
                            { authError && <Alert severity="error">{ authError }</Alert> }
                            <br />
                            {/* <Button
                                onClick={() => signInWithGoogle()}
                                variant="contained" startIcon={<GoogleIcon />}>
                                Google SignIn
                            </Button> */}
                            <Link to="/account/signin"><Button variant="text" className={ `${styles.linkButton}` }>
                                Already register?Please Login
                            </Button></Link>
                            <hr />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};


export default UserRegister;

