import { Avatar, Box, Card, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import useValue from "../../../../hooks/useValue";
import useFetch from "../../../../context/useFetch";

const BookingShcedules = () => {
  const { setIsInternationalFlight } = useValue()
  const { data, airportData, airlinesIata } = useFetch(
    `api/v1/airportlists/dac`
  );
  // const theFlight = selectedFlight?.selectedFlight || selectData;
  // const allFlightData = selectedFlight?.allData || allFData;
  // console.log(data)

  const selectedFlight = JSON.parse(
    window.localStorage.getItem("selectedFlight")
  );
  const allData = JSON.parse(window.localStorage.getItem("allData"));
  const theFlight = selectedFlight;
  const allFlightData = allData;

  const legDescs = allFlightData?.groupedItineraryResponse?.legDescs;
  const scheduleDescs = allFlightData?.groupedItineraryResponse?.scheduleDescs;

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
    return rhours + " h : " + rminutes + " m.";
  }
  function diff(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24;

    return (
      (hours <= 9 ? "0" : "") +
      hours +
      ":" +
      (minutes <= 9 ? "0" : "") +
      minutes
    );
  }
  useEffect(() => {
    theFlight?.legs.map((legsData, index) => (
      legsData.schedules.schedules.map((schedule, schedulesIndex) => (
        schedule.departure.country !== schedule.arrival.country ? setIsInternationalFlight(true) : setIsInternationalFlight(false)
      ))
    ))
  }, [legDescs, scheduleDescs, theFlight?.legs, setIsInternationalFlight])
  return (
    <Box>
      { theFlight?.legs.map((legsData, index) => (
        <Card sx={ { my: 2 } } key={ index }>
          { legsData.schedules.schedules.map((schedule, schedulesIndex) => (
            <Grid
              key={ schedulesIndex + 1 }
              container
              rowSpacing={ 1 }
              columnSpacing={ { xs: 1, sm: 2, md: 3 } }
              sx={ {
                p: 1,
                display: "flex",
                alignItems: "center",
                fontSize: "1em",
              } }
            >
              <Grid item xs={ 3 }>
                {
                  schedule.departure.airport
                }
                (
                { schedule.departure.time.split("+")[0]
                  .slice(0, 5) }
                ) <br />
                { `${airportData.find(
                  (airport) =>
                    airport.name.split("(")[1].slice(0, 3) ===
                    schedule.departure.airport
                )?.name
                  }` }
                <br />
                <br />
              </Grid>
              <Grid item xs={ 3 }>
                Non Stop<br></br>
                -----------<br></br>
                { timeConvert(
                  `${schedule.elapsedTime
                  }`
                ) }
              </Grid>

              <Grid item xs={ 3 }>
                {
                  schedule.arrival.airport
                }
                (
                { schedule.arrival.time.split("+")[0]
                  .slice(0, 5) }
                ) <br />
                { `${airportData.find(
                  (airport) =>
                    airport.name.split("(")[1].slice(0, 3) ===
                    schedule.arrival.airport
                )?.name
                  }` }
              </Grid>
              <Grid item xs={ 3 }>
                {/* <Avatar alt="Remy Sharp" src={ `https://daisycon.io/images/airline/?width=250&height=150&color=ffffff&iata=${scheduleDescs.find(scheduleDescsData => scheduleDescsData.id === schedule.ref).carrier.marketing}` } variant="square" sx={ { width: '70%', height: '70%' } } /> */ }
                <Avatar
                  alt="Remy Sharp"
                  src={ `https://pics.avs.io/250/80/${schedule.carrier.marketing
                    }@2x.png` }
                  variant="square"
                  sx={ { width: "70%", height: "70%" } }
                />
                {
                  schedule.carrier.marketing
                }{ " " }
                {
                  schedule.carrier.marketingFlightNumber
                }{ " " }
                |{ " " }
                {
                  airlinesIata
                    .find(
                      (airlineIataCode) =>
                        airlineIataCode?.iataCode ===
                        schedule.carrier.equipment.code
                    )
                    ?.description.split(" ")[0]
                }{ " " }
                {
                  schedule.carrier.equipment.code
                }
                <br />
              </Grid>
            </Grid>
          )) }
        </Card>
      )) }
    </Box>
  );
};

export default BookingShcedules;
