import React from 'react';
import nft from "../../../../assets/images/nft1.jpg";
import styles from './BookingTimeOutModal.module.css';
import { Link } from 'react-router-dom';
const BookingTimeOutModal = ({ open, onClose }) => {
    // if (!open) return null;
    /*  const targetTimestampKey = 'targetTimestamp';
 
     const { setTargetTimestamp } = useValue({}) */
    /*  const fetchData = () => {
 
         const timestamp = Date.now() + 1 * 60 * 1000; // Assuming the API response includes a 'timestamp' field
         // setTargetTimestamp(timestamp);
         localStorage.removeItem(targetTimestampKey)
         localStorage.setItem(targetTimestampKey, timestamp.toString());
 
         const storedTimestamp = localStorage.getItem(targetTimestampKey);
         setTargetTimestamp(storedTimestamp)
 
     }; */
    return (
        <div className={ `${styles.overlay}` }>
            <div
                onClick={ (e) => {
                    e.stopPropagation();
                } }
                className={ `${styles.modalContainer}` }
            >
                <img className={ `${styles.modalImg}` } src={ nft } alt="/" />
                <div className={ `${styles.modalRight}` }>
                    {/* <p className={`${styles.closeBtn}`} onClick={onClose}>
                        X
                    </p> */}
                    <div className={ `${styles.content}` }>
                        <h1>Sorry !</h1>
                        <h2>Your session have been expired</h2>
                    </div>
                    <div className={ `${styles.btnContainer}` }>

                        <Link to='/'>
                            <button className={ `${styles.btnPrimary}` }>
                                <span className={ `${styles.bold}` }>Search Again</span>
                            </button>
                        </Link>

                        {/* <Link to='/user/addTraveller'>
                    <Button sx={{ textTransform: 'unset' }} startIcon={<AddIcon />}>Add Traveller</Button>
                </Link> */}
                        {/* <button className={`${styles.btnOutline}`}>
                            <span className={`${styles.bold}`}>No Thanks</span>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default BookingTimeOutModal;