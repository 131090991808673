import { Button, CircularProgress, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SendIcon from "@mui/icons-material/Send";

// import logov2 from '../../../src/assets/images/logov2.png';
import othooyLogo from '../../../../assets/images/logo.png'
import axios, { axiosPrivate } from '../../../../api/axios';
import { Navigate } from 'react-router-dom';


const BracBankCreditDebitCard = ({ totalFares, bookiedId }) => {

    const [loading, setLoading] = useState(false);
    const [failMassage, setFailMassage] = useState('');

    const bracBankCreditDebitCardPayment = async () => {
        setLoading(true);
        try {
            // const gdsPnrResponse = await axios.get(`api/createPNR?passengersTotalData=${passengersTotalData}&travellerInfo=${travellerInfo}&flightSegment=${flightSegment}`);
            const gdsPnrResponse = await axiosPrivate.get(`/api/v1/payment?bookiedId=${bookiedId} `
            );
            // console.log(auth);
            console.log(gdsPnrResponse.data[0].result);
            if (gdsPnrResponse.data[0].result) {
                // Navigate(`/PaymentSuccess`);
                window.location.href = gdsPnrResponse.data[0].result;
            } else {
                setFailMassage("The Ticket creation is fail, Please try again")
            }

            // setLoadingMassage("Creating Airline PNR");
        } catch (error) {
            console.error("error", error);
        }
        setLoading(false);
    }
    return (
        <div>
            <Paper elevation={ 3 } sx={ { p: 5 } }>
                <p>You will be redirected to Brac Bank payment page to complete your purchase.</p>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 4 } sx={ { textAlign: 'right', my: 7 } }>
                        <img src={ othooyLogo } alt="othooyLogo" width='100%' />
                    </Grid>
                    <Grid item xs={ 4 } sx={ { textAlign: 'center', my: 7 } }>
                        <CompareArrowsIcon sx={ { fontSize: '60px' } } />
                    </Grid>
                    <Grid item xs={ 4 } sx={ { textAlign: 'left', my: 7 } }>

                        {/* <img src={ 'https://1.bp.blogspot.com/-C0dIOLQbYNk/X5CFGVhc-0I/AAAAAAAAJcY/ThrvX8Z6jeYeRadXAjSOV-duzv5DZTKiQCPcBGAYYCw/s0/brac%2Bbank_jobeduexam.png' } alt="othooyLogo" width='70%' /> */ }
                        <img src={ 'https://www.tpsworldwide.com/wp-content/uploads/2017/10/brac-bank-Limited-logo-Bangladesh.jpg' } alt="othooyLogo" width='80%' />
                    </Grid>
                </Grid>
                <Grid container spacing={ 2 } sx={ { textAlign: 'center' } }>
                    <Grid item xs={ 12 } sx={ { p: 7 } }>
                        <p>By continuing to pay, I understand and agree with the follwoing privacy pioicy and terms of condition of Othooy</p>
                        <Grid sx={ { mt: 5 } }>
                            {/* <Button onClick={ bracBankCreditDebitCardPayment } variant="contained" color="success">Proceed to Purchase</Button> */ }
                            <Button
                                onClick={ bracBankCreditDebitCardPayment }
                                variant="contained"
                                color="success"
                                disabled={ loading ? true : false }
                            // endIcon={ }
                            // fullWidth
                            >
                                {
                                    loading ? <CircularProgress size={ 20 } /> :
                                        <>  Proceed to Purchase <SendIcon />  </>
                                }
                            </Button>
                        </Grid>
                        <Grid sx={ { mt: 5 } }>
                            Total Payable: { totalFares.totalPayableFare.toLocaleString() }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

        </div >
    );
};

export default BracBankCreditDebitCard;