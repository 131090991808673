import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import useFetch from '../../../../context/useFetch';
import useAuth from '../../../../hooks/useAuth';



const basicStyle = {
    backgroundColor: '#0080000f',
    borderRadius: '5px',
    transition: 'background-color 0.3s', // Add a smooth transition for the hover effect
    '&:hover': {
        backgroundColor: 'red', // Define the background color for the hover state
    },
}
const selectedStyle = {
    backgroundColor: 'rgb(0 128 0 / 30%)',
    borderRadius: '5px'
}
const PaymentSidebar = ({ setSelectedPaymentMethod, selectedPaymentMethod }) => {
    // console.log(selectedPaymentMethod)
    const { user, } = useAuth();

    const { privateData } = useFetch(`/api/v1/balance/show?email=${user?.email}`);

    const userBalance = privateData;
    // console.log(userBalance);

    return (
        <div>
            <MenuItem style={ selectedPaymentMethod === 'BracBankCreditDebitCard' ? selectedStyle : basicStyle } onClick={ () => { setSelectedPaymentMethod('BracBankCreditDebitCard') } }><CreditCardIcon />  Credit/Debit Card</MenuItem>
            { userBalance?.balance &&
                <MenuItem style={ selectedPaymentMethod === 'Balance' ? selectedStyle : basicStyle } onClick={ () => { setSelectedPaymentMethod('Balance') } }><PaymentsIcon />  Balance</MenuItem>
            }
            <MenuItem style={ selectedPaymentMethod === 'Astha' ? selectedStyle : basicStyle } onClick={ () => { setSelectedPaymentMethod('Astha') } }><CreditCardIcon />  Astha</MenuItem>
            <MenuItem style={ selectedPaymentMethod === 'Bkash' ? selectedStyle : basicStyle } onClick={ () => { setSelectedPaymentMethod('Bkash') } }><CreditCardIcon />  Bkash</MenuItem>
            <MenuItem style={ selectedPaymentMethod === 'Nagad' ? selectedStyle : basicStyle } onClick={ () => { setSelectedPaymentMethod('Nagad') } }><CreditCardIcon /> Nagad</MenuItem>

        </div>
    );
};

export default PaymentSidebar;