import { Box, Button, Container, Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { createRef, useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AccessibleIcon from "@mui/icons-material/Accessible";
import { Link } from "react-router-dom";
import PassengerAndClasses from "../PassengerAndClasses/PassengerAndClasses";
import useValue from "../../../hooks/useValue";
import FlightTypeOption from "../FlightTypeOption/FlightTypeOption";
import "./FlightSearchV2.css";

const linkStyle = {
  marginTop: "17px",
  padding: "10px 15px",
  background: "rgb(40 229 40 / 75%)",
  color: "#FFF",
  border: "2px solid #FFF",
  borderRadius: "5px",
  fontWeight: "bold",
  textDecoration: "none",
  boxShadow: `rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset`,
  transition: "transform 0.5s",
  "&:hover": {
    // boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`,
    // border: '1px solid #333',
    color: "#333",
    transform: "translateY(-10px) rotate(360deg)",
  },
};

const REACT_APP_NODE_API_baseURL = process.env.REACT_APP_NODE_API_baseURL;
const FlightSearchV2 = () => {
  const {
    setPassengerAndClassOpen,
    flightType,
    setFlightType,
    cities,
    setCities,
    showFromInput,
    setShowFromInput,
    showToInput,
    setShowToInput,
    showJourneyDate,
    setShowJourneyDate,
    showReturnDate,
    setShowReturnDate,
    fromCityQuery,
    setFromCityQuery,
    toCityQuery,
    setToCityQuery,
  } = useValue({});
  const inputFromRef = createRef();
  const inputToRef = createRef();
  const [fromCityResults, setFromCityResults] = useState(region);
  const [toCityResults, setToCityResults] = useState(region);

  const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 24 hours
  // -----------------
  const dateForJourney = new Date(
    new Date().getTime() + 1 * 24 * 60 * 60 * 1000
  );
  const dateForReturn = new Date(
    new Date().getTime() + 2 * 24 * 60 * 60 * 1000
  );
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [openIndex, setOpenIndex] = useState(null);
  const theJourneyDate = new Date(
    dateForJourney.getTime() - dateForJourney.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];
  const theReturnDate = new Date(
    dateForReturn.getTime() - dateForReturn.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];

  useEffect(() => {
    if (!fromCityQuery) {
      return;
    }
    // fetch(`http://localhost:7000/api/airportsearch/${fromCityQuery}`)
    fetch(
      `https://api.sharetrip.net/api/v1/flight/search/airport?name=${fromCityQuery}`
    )
      .then((response) => response.json())
      .then((data) => setFromCityResults(data.response));
  }, [fromCityQuery]);
  useEffect(() => {
    if (!toCityQuery) {
      return;
    }
    // fetch(`http://localhost:5000/api/airportsearch/${toCityQuery}`)
    fetch(
      `https://api.sharetrip.net/api/v1/flight/search/airport?name=${toCityQuery}`
    )
      .then((response) => response.json())
      .then((data) => setToCityResults(data.response));
  }, [toCityQuery]);
  useEffect(() => {
    if (!toCityQuery) {
      return;
    }
    // fetch(`http://localhost:5000/api/airportsearch/${toCityQuery}`) https://production.gozayaan.com/api/location/autocomplete/?region=BD&keyword=med
    fetch(
      `https://production.gozayaan.com/api/location/autocomplete/?region=BD&keyword=${toCityQuery}`
    )
      .then((response) => response.json())
      .then((data) => setToCityResults(data.result));
  }, [toCityQuery]);

  // console.log(toCityResults)
  // console.log(cities)

  function handleFromClick(index, e) {
    setShowFromInput(!showFromInput);
    // inputFromRef.current.focus();

    setOpenIndex(index);
    showToInput && setShowToInput(!showToInput);
    setFromCityQuery("");
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function handleToClick(index, e) {
    setShowToInput(!showToInput);
    // inputToRef.current.focus();
    setOpenIndex(index);
    showFromInput && setShowFromInput(!showFromInput);
    setToCityQuery("");
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function handleJourneyDateClick(index, e) {
    setShowJourneyDate(!showJourneyDate);
    // inputToRef.current.focus();
    setOpenIndex(index);
    showReturnDate && setShowReturnDate(!showReturnDate);
    setToCityQuery("");
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function handleReturnDateClick(index, e) {
    setShowReturnDate(!showReturnDate);
    // inputToRef.current.focus();
    setOpenIndex(index);
    showJourneyDate && setShowJourneyDate(!showJourneyDate);
    setToCityQuery("");
    setFlightType("roundTrip");
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  useEffect(() => {
    showFromInput && inputFromRef.current.focus();
  }, [inputFromRef, showFromInput]);
  useEffect(() => {
    showToInput && inputToRef.current.focus();
  }, [inputToRef, showToInput]);

  // console.log('citiesData', citiesData);
  // const [cities, setCities] = useState([{
  //     fromSelectedItem: 'Dhaka,Hazrat Shahjalal International Airport (DAC)',
  //     toSelectedItem: 'Dubai,Dubai Intl Arpt (DXB)',
  //     journeyDate: dateForJourney,
  // }]);

  useEffect(() => {
    const selectedCities = JSON.parse(localStorage.getItem("cities"));
    let citiesData =
      flightType === "multiCity"
        ? [
          {
            fromSelectedItem:
              selectedCities[0].fromSelectedItem ||
              "Dhaka,Hazrat Shahjalal International Airport (DAC)",
            toSelectedItem:
              selectedCities[0].toSelectedItem ||
              "Dubai,Dubai Intl Arpt (DXB)",
            journeyDate: selectedCities[0].journeyDate || theJourneyDate,
            returnDate: selectedCities[0].returnDate || theReturnDate,
          },
          {
            fromSelectedItem: "",
            toSelectedItem: "",
            journeyDate: null,
            returnDate: null,
          },
        ]
        : [
          {
            fromSelectedItem:
              selectedCities[0].fromSelectedItem ||
              "Dhaka,Hazrat Shahjalal International Airport (DAC)",
            toSelectedItem:
              selectedCities[0].toSelectedItem ||
              "Dubai,Dubai Intl Arpt (DXB)",
            journeyDate: selectedCities[0].journeyDate || theJourneyDate,
            returnDate: selectedCities[0].returnDate || theReturnDate,
          },
        ];
    setCities(citiesData);
  }, [flightType, setCities, theJourneyDate, theReturnDate]);
  // console.log('cities', cities);
  function handleFromSelect(index, item) {
    // item && setFromSelectedItem(item);
    setShowFromInput(!showFromInput);
    const newCities = [...cities];
    newCities[index].fromSelectedItem = item;
    setCities(newCities);
    setFromCityQuery("");
    // handleToSelect();
    showFromInput && setShowToInput(!showToInput);
    // if (item === toSelectedItem) {
    //     console.log('From and To can not be same!');
    //     handleToSelect()
    // }
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function handleToSelect(index, item) {
    // item && setToSelectedItem(item);
    setShowToInput(!showToInput);
    // console.log(item)
    const newCities = [...cities];
    newCities[index].toSelectedItem = item;
    setCities(newCities);

    setToCityQuery("");

    // showToInput && setShowFromInput(!showFromInput);
    showToInput && setShowJourneyDate(!showJourneyDate);
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }

  function handleJourneyDateChange(index, date) {
    const newCities = [...cities];
    const dateForJourney = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )

    newCities[index].journeyDate = dateForJourney.toISOString()
      .split("T")[0];

    const dateForReturn = new Date(dateForJourney);
    dateForReturn.setDate(dateForJourney.getDate() + 2);

    newCities[index].returnDate = dateForReturn.toISOString()
      .split("T")[0];

    setCities(newCities);
    showJourneyDate && setShowJourneyDate(!showJourneyDate);
    flightType === "roundTrip"
      ? showJourneyDate && setShowReturnDate(!showReturnDate)
      : index === 0 &&
      showReturnDate &&
      setPassengerAndClassOpen((prev) => !prev);
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function handleReturnDateChange(index, date) {
    const newCities = [...cities];
    newCities[index].returnDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    setCities(newCities);

    showReturnDate && setShowReturnDate(!showReturnDate);
    index === 0 && showReturnDate && setPassengerAndClassOpen((prev) => !prev);
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  const handleCloseIfOpen = () => {
    showFromInput && setShowFromInput(!showFromInput);
    showToInput && setShowToInput(!showToInput);
    showJourneyDate && setShowJourneyDate(!showJourneyDate);
    showReturnDate && setShowReturnDate(!showReturnDate);
    setFromCityQuery("");
    setToCityQuery("");
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  };

  function addCity() {
    cities.length >= 2 &&
      cities.length <= 4 &&
      setCities([
        ...cities,
        {
          fromSelectedItem: "",
          toSelectedItem: "",
          journeyDate: null,
          returnDate: null,
        },
      ]);
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  function removeCity(index) {
    const newCities = [...cities];
    cities.length > 2 && newCities.splice(index, 1);
    setCities(newCities);
    localStorage.setItem('citiesExpirationTime', expirationTime.toString());
  }
  // console.log(cities)
  // const today = new Date().toISOString().slice(0, 10);

  return (
    <Box sx={ { marginTop: "-50px" } } onClick={ handleCloseIfOpen }>
      <Container
        sx={ {
          background: "white",
          zIndex: "999",
          // "linear-gradient(rgba(255,255,255,-0.3), rgba(255,255,255,-0.3))",
          // background: 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))',
          boxShadow: "0px 0px 5px 2px rgb(0 0 0 / 65%)",
          // boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
          py: 1,
          borderRadius: "5px",
        } }
      >
        <FlightTypeOption />
        { cities.map((city, index) => (
          <Grid container spacing={ 1 } key={ index } sx={ { my: 0.5 } }>
            {/* <Grid item xs={ 12 } md={ flightType === 'multiCity' ? 6 : 5 } >
                            <Grid container spacing={ 1 }> */}
            <Grid item xs={ 12 } sm={ 6 } md={ 2.75 } sx={ { position: "relative" } }>
              <Paper
                onClick={ (e) => handleFromClick(index, e) }
                sx={ { px: 2, display: "block", fontSize: "12px" } }
              >
                From:<br></br>
                <strong>
                  { city?.fromSelectedItem?.city ? (
                    <span>
                      { city?.fromSelectedItem?.city }(
                      {
                        // city?.fromSelectedItem ?
                        city?.fromSelectedItem?.iata_code ||
                        city?.fromSelectedItem?.iata
                      }
                      )
                    </span>
                  ) : (
                    "Select a city"
                  ) }
                  {/* : "Select a city" } */ }
                </strong>
                <br></br>
                { city?.fromSelectedItem?.name ? (
                  <small>
                    {
                      // city?.fromSelectedItem &&
                      city?.fromSelectedItem?.name.slice(0, 25)
                    }
                    ...
                  </small>
                ) : (
                  "Choose an airport now"
                ) }
              </Paper>
              { openIndex === index && (
                <Paper
                  sx={ {
                    display: showFromInput ? "inline-block" : "none",
                    position: "absolute",
                    zIndex: 7 - index,
                    fontSize: "12px",
                  } }
                >
                  <input
                    key={ index }
                    type="text"
                    width="100%"
                    required
                    placeholder="Search..."
                    value={ fromCityQuery }
                    name="fromSelectedItem"
                    onClick={ (e) => e.stopPropagation() }
                    onChange={ (event) => setFromCityQuery(event.target.value) }
                    ref={ inputFromRef }
                    style={ {
                      width: "100%",
                      border: "1px solid #333",
                      height: "48px",
                      padding: "10px 26px",
                    } }
                    id="filled-basic"
                    label="Filled"
                    variant="filled"
                  />
                  <ol
                    style={ {
                      maxHeight: "200px",
                      width: "350px",
                      overflowX: "scroll",
                      padding: "0 10px",
                    } }
                  >
                    { fromCityResults.map((item) => (
                      <li
                        key={ item._id }
                        style={ { listStyle: "none" } }
                        className="liEffect"
                        onClick={ () => handleFromSelect(index, item) }
                      >
                        {/* { item.name } */ }
                        <p>
                          { item.city }, ({ item.iata_code || item.iata }) <br></br>
                          <span
                            style={ {
                              fontSize: "11px",
                            } }
                          >
                            { item.name }, { item.country }
                          </span>
                        </p>
                      </li>
                    )) }
                  </ol>
                </Paper>
              ) }
              {/* ) } */ }
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } md={ 2.75 } sx={ { position: "relative" } }>
              <Paper
                onClick={ (e) => handleToClick(index, e) }
                sx={ { px: 2, display: "block", fontSize: "12px" } }
              >
                To:<br></br>
                <strong>
                  { city?.toSelectedItem?.name ? (
                    <span>
                      { city?.toSelectedItem?.city }(
                      { city?.toSelectedItem?.iata_code ||
                        city?.toSelectedItem?.iata }
                      )
                    </span>
                  ) : (
                    "Select a city"
                  ) }
                </strong>
                <br></br>
                { city?.toSelectedItem?.name ? (
                  <small>
                    {
                      // city?.toSelectedItem &&
                      city?.toSelectedItem?.name.slice(0, 25)
                    }
                    ...
                  </small>
                ) : (
                  "Choose an airport now"
                ) }
              </Paper>
              { openIndex === index && (
                <Paper
                  sx={ {
                    display: showToInput ? "inline-block" : "none",
                    position: "absolute",
                    zIndex: 7 - index,
                    fontSize: "12px",
                  } }
                >
                  <input
                    type="text"
                    required
                    placeholder="Search..."
                    value={ toCityQuery }
                    onClick={ (e) => e.stopPropagation() }
                    name="toSelectedItem"
                    onChange={ (event) => setToCityQuery(event.target.value) }
                    ref={ inputToRef }
                    style={ {
                      width: "100%",
                      border: "1px solid #333",
                      height: "48px",
                      padding: "10px 26px",
                    } }
                    id="filled-basic"
                    label="Filled"
                    variant="filled"
                  />
                  <ol
                    style={ {
                      maxHeight: "200px",
                      width: "350px",
                      overflowX: "scroll",
                      padding: "0 10px",
                    } }
                  >
                    { toCityResults.map((item) => (
                      <li
                        key={ item._id }
                        style={ { padding: "0 5px", listStyle: "none" } }
                        className="liEffect"
                        onClick={ () => handleToSelect(index, item) }
                      >
                        <p>
                          { item.city }, ({ item.iata_code || item.iata }) <br></br>
                          <span
                            style={ {
                              fontSize: "11px",
                            } }
                          >
                            { item.name }, { item.country }
                          </span>
                        </p>
                      </li>
                    )) }
                  </ol>
                </Paper>
              ) }
            </Grid>

            {/* </Grid>
                        </Grid> */}
            {/* <Grid item xs={ 12 } md={ flightType === 'multiCity' ? 3 : 4.5 } >
                            <Grid container spacing={ 0 }> */}
            <Grid
              item
              xs={ 12 }
              sm={ 6 }
              md={ flightType === "multiCity" ? 3 : 2 }
              sx={ { position: "relative" } }
            >
              <Paper
                onClick={ (e) => handleJourneyDateClick(index, e) }
                sx={ { px: 2, display: "block", fontSize: "12px" } }
              >
                Journey Date:<br></br>
                <span
                  style={ {
                    color: "#000",
                  } }
                >
                  { city?.journeyDate
                    ? new Date(city?.journeyDate)
                      .toLocaleDateString("en-GB", options)
                      .split(",")[1]
                    : "Select Journey Date" }
                </span>
                <br></br>
                { city?.journeyDate
                  ? new Date(city?.journeyDate)
                    .toLocaleDateString("en-GB", options)
                    .split(",")[0]
                  : "Weekday" }
              </Paper>
              { openIndex === index && (
                <Paper
                  onClick={ (e) => e.stopPropagation() }
                  sx={ {
                    display: showJourneyDate ? "inline-block" : "none",
                    position: "absolute",
                    zIndex: 7 - index,
                  } }
                >
                  <LocalizationProvider dateAdapter={ AdapterDateFns }>
                    <DatePicker
                      open={ showJourneyDate ? true : false }
                      components={ {
                        OpenPickerIcon: AccessibleIcon,
                      } }
                      label="" //Journey Date
                      value={ city.journeyDate }
                      // inputFormat="yyyy-MMM-dd "
                      required
                      minDate={ new Date() }
                      onChange={ (date) => handleJourneyDateChange(index, date) }
                      renderInput={ (params) => (
                        <TextField
                          sx={ {
                            // width: { sm: 200, md: 300 },
                            "& .MuiInputBase-root": {
                              // height: 73
                            },
                          } }
                          { ...params }
                        />
                      ) }
                    />
                  </LocalizationProvider>
                </Paper>
              ) }
            </Grid>
            { flightType !== "multiCity" && (
              <Grid item xs={ 12 } sm={ 6 } md={ 2 } sx={ { position: "relative" } }>
                <Paper
                  onClick={ (e) => handleReturnDateClick(index, e) }
                  sx={ { px: 2, display: "block", fontSize: "12px" } }
                >
                  Return Date:<br></br>
                  <span
                    style={ {
                      color: "#000",
                    } }
                  >
                    { city?.returnDate && flightType === "roundTrip"
                      ? new Date(city?.returnDate)
                        .toLocaleDateString("en-GB", options)
                        .split(",")[1]
                      : "Save more" }
                  </span>
                  <br></br>
                  { city?.returnDate && flightType === "roundTrip"
                    ? new Date(city?.returnDate)
                      .toLocaleDateString("en-GB", options)
                      .split(",")[0]
                    : "on return flight" }
                </Paper>
                { openIndex === index && (
                  <Paper
                    onClick={ (e) => e.stopPropagation() }
                    sx={ {
                      display: showReturnDate ? "inline-block" : "none",
                      position: "absolute",
                      zIndex: 7 - index,
                    } }
                  >
                    <LocalizationProvider dateAdapter={ AdapterDateFns }>
                      <DatePicker
                        sx={ { py: 3 } }
                        label="" //Return Date
                        // views={ ['year', 'month'] }
                        // maxDate={ new Date('2022-06-01') }
                        open={ showReturnDate ? true : false }
                        required
                        // onClick={ e => setFlightType('roundTrip') }
                        value={ city.returnDate }
                        // inputFormat="yyyy-MMM-dd "
                        // disabled={  flightType === 'roundTrip' ? false : true }
                        minDate={ new Date(city.journeyDate) }
                        onChange={ (date) => handleReturnDateChange(index, date) }
                        renderInput={ (params) => <TextField { ...params } /> }
                      />
                      {/* {
                                                console.log(new Date())

                                            }
                                            {
                                                console.log(new Date(city.journeyDate))

                                            } */}
                    </LocalizationProvider>
                  </Paper>
                ) }
              </Grid>
            ) }

            {/* </Grid>
                        </Grid> */}
            <Grid
              item
              xs={ 12 }
              sm={ 6 }
              md={ flightType === "multiCity" ? 3 : 2.5 }
              sx={ {} }
            >
              { index === 0 ? (
                <PassengerAndClasses />
              ) : (
                <Paper sx={ {} }>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={ addCity }
                  >
                    Add City
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    onClick={ () => removeCity(index) }
                  >
                    Remove City
                  </Button>
                </Paper>
              ) }
            </Grid>
          </Grid>
        )) }
      </Container>
      <Grid item sm={ 12 } sx={ { textAlign: "center", py: 1 } }>
        <Link to={ `/Flightv4` } variant="outlined" style={ linkStyle }>
          Search Flights
        </Link>
      </Grid>
    </Box>
  );
};

export default FlightSearchV2;

const region = [
  {
    name: "Hazrat Shahjalal International Airport",
    iata_code: "DAC",
    city: "Dhaka",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "Cox's Bazar Airport",
    iata_code: "CXB",
    city: "Cox's Bazar",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "Jessore Airport",
    iata_code: "JSR",
    city: "Jessore",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "Shah Amanat International Airport",
    iata_code: "CGP",
    city: "Chittagong",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "Saidpur Airport",
    iata_code: "SPD",
    city: "Saidpur",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "Osmany International Airport",
    iata_code: "ZYL",
    city: "Sylhet Osmani",
    country: "Bangladesh",
    country_code: "BD",
  },
  {
    name: "London Heathrow Airport",
    iata_code: "LHR",
    city: "London",
    country: "United Kingdom",
    country_code: "GB",
  },
  {
    name: "John F Kennedy International Airport",
    iata_code: "JFK",
    city: "New York",
    country: "United States",
    country_code: "US",
  },
];
