import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Packages from '../../components/Packages/Packages';
import Footer from '../../shared/Footer/Footer';
import Offer from '../../components/TestComponent/Offer';
import ExploreBangladesh from '../../components/TestComponent/ExploreBangladesh';
import TopAirlines from '../../components/TestComponent/TopAirlines/TopAirlines';
import Slider from '../../components/TestComponent/BannerSlider/Slider';
import { slidesData } from '../../components/TestComponent/BannerSlider/slidesData';
import useAuth from '../../hooks/useAuth';
import Loader from '../../shared/Loader';

const Home = () => {
    const { isLoading } = useAuth();

    if (isLoading) {
        return <Loader />;
    }
    return (
        <div>
            <Navbar />
            <Slider slides={ slidesData } />
            {/* <LightningDeals /> */ }
            <Packages />
            <ExploreBangladesh />
            <Offer />
            <TopAirlines />
            <Footer />
        </div>

    );
};

export default Home;