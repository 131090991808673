import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Loading from '../../../components/Loading/Loading';

const PrivateRoute = ({ children }) => {
    const { user, isLoading } = useAuth();
    const location = useLocation();
    if (isLoading) {
        return <Loading></Loading>
    }
    if (!user?.email) {
        return <Navigate to='/account/signin' state={ { from: location } } replace></Navigate>
    }
    return children;

};

export default PrivateRoute;