import React from "react";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import style from "./Offer.module.css";

const cardData = [
  {
    id: 1,
    title: "Card 1",
    image: "https://i.ibb.co/zsxYZtJ/login-Banner.jpg",
  },
  { id: 2, title: "Card 2", image: "https://i.ibb.co/ZxHK6mg/download.jpg" },
  { id: 3, title: "Card 3", image: "https://i.ibb.co/HCxJk96/package2.webp" },
];

const hoverData1 = [
  { id: 1, text: "Save On BH Lounch " },
  { id: 2, text: "Date Changed" },
  { id: 3, text: "Exclusive Offer" },
];
const hoverData2 = [
  { id: 1, text: "Flight Refund" },
  { id: 2, text: "Enjoy Extra 5% discount" },
  { id: 3, text: "View details" },
];
const hoverData3 = [
  { id: 1, text: "Flight All Offer" },
  { id: 2, text: "Flat 10% discount" },
  { id: 3, text: "Exclusive Offer" },
];

const Offer = () => {
  return (
    <Container sx={{ marginTop: "50px", marginBottom: "50px" }}>
      <h2 style={{ fontWeight: "bolder" }}>Exclusive Offers</h2>
      <Grid container spacing={2}>
        {/* {cardData.map((card) => ( */}
        {/* <Grid item xs={12} sm={12} md={4} lg={4} key={card.id}> */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card
            className={style.card}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              position: "relative",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
              // transition: "transform 0.3s ease-in-out",
              // "&:hover": {
              //   transform: "scale(1.05)",
              // },
            }}
          >
            <CardMedia
              className={style.cardImg}
              component="img"
              height="200"
              image={`https://i.ibb.co/zsxYZtJ/login-Banner.jpg`}
              alt="Paella dish"
            />
            <Box className={style.cardBody1}>
              <Typography
                variant="h10"
                component="div"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  padding: 2,
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {hoverData1.map((data) => (
                  <p
                    key={data.id}
                    style={{ color: "white", marginTop: "20px" }}
                  >
                    {data.text}
                  </p>
                ))}
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card
            className={style.card}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              position: "relative",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
              // transition: "transform 0.3s ease-in-out",
              // "&:hover": {
              //   transform: "scale(1.05)",
              // },
            }}
          >
            <CardMedia
              className={style.cardImg}
              component="img"
              height="200"
              image={`https://i.ibb.co/ZxHK6mg/download.jpg`}
              alt="Paella dish"
            />
            <Box className={style.cardBody1}>
              <Typography
                variant="h10"
                component="div"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  padding: 2,
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {hoverData2.map((data) => (
                  <p
                    key={data.id}
                    style={{ color: "white", marginTop: "20px" }}
                  >
                    {data.text}
                  </p>
                ))}
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card
            className={style.card}
            sx={{
              maxWidth: 345,
              cursor: "pointer",
              position: "relative",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
              // transition: "transform 0.3s ease-in-out",
              // "&:hover": {
              //   transform: "scale(1.05)",
              // },
            }}
          >
            <CardMedia
              className={style.cardImg}
              component="img"
              height="200"
              image={`https://i.ibb.co/HCxJk96/package2.webp`}
              alt="Paella dish"
            />
            <Box className={style.cardBody1}>
              <Typography
                variant="h10"
                component="div"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  padding: 2,
                  color: "#fff",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {hoverData3.map((data) => (
                  <p
                    key={data.id}
                    style={{ color: "white", marginTop: "20px" }}
                  >
                    {data.text}
                  </p>
                ))}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Offer;
