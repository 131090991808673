import { Box, Divider, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { calculateTotalPrice } from '../FlightDiscount/FlightDiscount';
import CountdownTimerV3 from '../../../../components/TimerComponents/CountdownTimerV3';
import useValue from '../../../../hooks/useValue';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton { ...other } />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const FareSummary = () => {
    const [expandedSummary, setExpandedSummary] = React.useState(false);

    const { baseFare, setBaseFare, taxFare, setTaxFare, subTotalPrice, setSubTotalPrice, discountPercentage, aitPercentage, calculatedFares, setCalculatedFares } = useValue({});

    /*  const [baseFare, setBaseFare] = React.useState(0);
     const [taxFare, setTaxFare] = React.useState(0);
     const [subTotalPrice, setSubTotalPrice] = React.useState(0);
     const [discountPercentage, setDiscountPercentage] = React.useState(5);
     const [aitPercentage, setAitPercentage] = React.useState(0.3);
     const [calculatedFares, setCalculatedFares] = useState({}) */

    const handleExpandClickSummary = () => {
        setExpandedSummary(!expandedSummary);
    };


    useEffect(() => {

        setCalculatedFares(calculateTotalPrice(baseFare, taxFare, discountPercentage, aitPercentage))
    }, [baseFare, taxFare, discountPercentage, aitPercentage, setCalculatedFares]);
    // const totalFares = calculateTotalPrice(baseFare, taxFare, discountPercentage, aitPercentage)
    // console.log('totalFares', calculatedFares)
    // console.log('subTotalPrice', subTotalPrice)


    // const allData = JSON.parse(window.localStorage.getItem('allData'));
    const theFlight = JSON.parse(window.localStorage.getItem('selectedFlight'));
    // console.log(theFlight)
    // const allFlightData = allData;  pricingInformationData.fare.totalFare.totalTaxAmount
    useEffect(() => {
        theFlight?.pricingInformation.map((pricingInformationData, index) => (
            setBaseFare(parseInt(pricingInformationData.fare.totalFare.equivalentAmount))
        ))
        theFlight?.pricingInformation.map((pricingInformationData, index) => (
            setTaxFare(parseInt(pricingInformationData.fare.totalFare.totalTaxAmount))
        ))
        theFlight?.pricingInformation.map((pricingInformationData, index) => (
            setSubTotalPrice(parseInt(pricingInformationData.fare.totalFare.totalPrice))
        ))

    }, [theFlight?.pricingInformation])

    const discountPriceCalculate = parseInt((baseFare * discountPercentage) / 100);
    const discountPrice = parseInt(baseFare - discountPriceCalculate);
    const aitPriceCalculate = parseInt((discountPrice + taxFare) * 0.03);
    const totalPayable = parseInt(discountPrice + taxFare + aitPriceCalculate);

    // 'baseFare', baseFare, 'taxFare', taxFare, 'totalPrice', totalPrice, 'discountPercentage', discountPercentage, 
    // console.log('aitPriceCalculate', aitPriceCalculate, 'discountPriceCalculate', discountPriceCalculate, 'discountPrice', discountPrice)
    // console.log((discountPrice + taxFare + aitPriceCalculate))
    const targetTimestamp = Date.now() + 1 * 60 * 1000;
    return (
        <Box sx={ { px: 1, position: 'sticky', top: 55, } }>
            <Paper variant="outlined" sx={ { px: 1, my: 2 } }>
                {/* <Clock /> */ }
                {/* <TimeCountdownv2 /> */ }
                {/* <CountdownTimer /> */ }
                {/* <CountdownTimerV2 targetTimestamp={ targetTimestamp } /> */ }
                <CountdownTimerV3 />
            </Paper>
            <Paper variant="outlined" sx={ { px: 1 } }>
                <Card sx={ {} }>

                    <Grid container rowSpacing={ 0 } columnSpacing={ { xs: 1, sm: 2, md: 3 } } sx={ { px: 1, alignItems: 'center', fontSize: '1em' } } >
                        <Grid item xs={ 6 }>
                            {/* <Avatar alt="Remy Sharp" src={ `https://daisycon.io/images/airline/?width=250&height=150&color=ffffff&iata=${theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                    pricingInformationData.fare.validatingCarrierCode
                                ))}` } variant="square" sx={ { width: '70%', height: '70%' } } aria-label="recipe" /> */}

                            <Avatar alt="Remy Sharp" src={ `https://pics.avs.io/250/80/${theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                pricingInformationData.fare.validatingCarrierCode
                            ))}@2x.png` } variant="square" sx={ { width: '70%', height: '70%' } } aria-label="recipe" />

                        </Grid>
                        <Grid item xs={ 6 }>
                            {
                                theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                    pricingInformationData.fare.passengerInfoList[0].passengerInfo.fareComponents.map((fareComponentsData, index) => (
                                        <Box style={ { display: 'flex' } } key={ index }>
                                            <p>{ `${fareComponentsData.beginAirport}-` }</p>
                                            <p>{ `${fareComponentsData.endAirport}` },</p>
                                        </Box>
                                    ))
                                )) }
                        </Grid>
                    </Grid>
                    <Divider />
                    <CardActions disableSpacing
                        onClick={ handleExpandClickSummary }
                        aria-expanded={ expandedSummary }
                        aria-label="show more"
                    >

                        <p style={ {} }>
                            <b> Fare Summary</b>
                        </p>


                        <ExpandMore >
                            <ExpandMoreIcon />
                        </ExpandMore>


                    </CardActions>
                    <Collapse in={ expandedSummary } timeout="auto" unmountOnExit>
                        <CardContent>

                            {
                                theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                    pricingInformationData.fare.passengerInfoList.map((passengerInfoListData, index) => (

                                        `${passengerInfoListData.passengerInfo.passengerType} (${passengerInfoListData.passengerInfo.passengerNumber}), `
                                    ))
                                )) }

                            <Grid container rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 2, md: 3 } } sx={ { display: 'flex', alignItems: 'center', fontSize: '1em' } } >
                                <Grid item xs={ 6 } sx={ { textAlign: 'left' } }>
                                    <p>Base Fare</p>
                                    <p>Tax</p>
                                    {/* <Divider /> */ }
                                </Grid>
                                <Grid item xs={ 6 } sx={ { textAlign: 'right' } }>
                                    <p>
                                        {/* { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                pricingInformationData.fare.totalFare.equivalentAmount.toLocaleString()
            )) } */}
                                        <span style={ { fontSize: '13px' } }>  BDT</span>
                                        <span style={ { fontWeight: '700' } }> { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                            pricingInformationData.fare.totalFare.equivalentAmount?.toLocaleString()
                                        )) }</span> /=
                                    </p>
                                    <p>
                                        {/* { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                pricingInformationData.fare.totalFare.totalTaxAmount.toLocaleString()
            )) }  */}
                                        <span style={ { fontSize: '13px' } }>  BDT</span>
                                        <span style={ { fontWeight: '700' } } >  { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                            pricingInformationData.fare.totalFare.totalTaxAmount?.toLocaleString()
                                        )) } </span> /=

                                    </p>
                                </Grid>

                            </Grid>

                        </CardContent>

                        <Divider />
                    </Collapse>

                    <CardContent>

                        <Grid container>
                            <Grid item xs={ 6 } sx={ { textAlign: 'left' } }>
                                <p>SubTotal:</p>
                                <p>Discount:</p>
                                <p>AIT:</p>
                                <p> <span style={ { fontWeight: '700' } } > Total Payable: </span></p>
                            </Grid>
                            <Grid item xs={ 6 } sx={ { textAlign: 'right' } }>
                                <p>
                                    {/*  { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                pricingInformationData.fare.totalFare.totalPrice.toLocaleString()
            )) } */}
                                    <span style={ { fontSize: '13px' } }>  BDT </span>
                                    <span style={ { fontWeight: '700' } } >  { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                        pricingInformationData.fare.totalFare.totalPrice?.toLocaleString()
                                    )) }</span> /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  BDT </span>
                                    <span style={ { fontWeight: '700' } } >  - { calculatedFares?.discount?.toLocaleString() }  </span>  /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  BDT </span>
                                    <span style={ { fontWeight: '700' } } > { calculatedFares?.aitPrice?.toLocaleString() }   </span> /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  BDT </span>
                                    <span style={ { fontWeight: '700' } } >  { calculatedFares?.totalPayableFare?.toLocaleString() }</span> /=
                                </p>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
            </Paper>
        </Box>
    );
};

export default FareSummary;