import React from 'react';
import img1 from './img/card1.jpg';
import img2 from './img/card2.jpg';
import img3 from './img/card3.jpg';
import styles from './AboutUs.module.css';
import Footer from '../../shared/Footer/Footer';
import useAuth from '../../hooks/useAuth';
import Loading from '../../components/Loading/Loading';
import Navbar from '../../shared/Navbar/Navbar';
import Loader from '../../shared/Loader';
const AboutUs = () => {

    const { isLoading } = useAuth();

    if (isLoading) {
        return <Loader />
    }
    return (
        <>
            <Navbar />
            <div className='container'>
                <div className={`${styles.aboutUs} ${'row d-flex  items-center'}`}>
                    < div className={`${'col-md-7'}`} >
                        <div className={`${'row'}`}>
                            <div className={`${'col-md-5'}`}>
                                <img
                                    className={`${'w-100'}`}
                                    src={img1}
                                    alt=""
                                />
                            </div>
                            <div className={`${'col-md-7'}`}>
                                <div className={`${'row'}`}>
                                    <div className={`${'col-md-12'}`}>
                                        <img
                                            className={`${'w-100 mt-2'}`}
                                            src={img2}
                                            alt=""
                                        />
                                    </div>
                                    <div className={`${'mt-3 col-md-12'}`}>
                                        <img
                                            className={`${'w-100'}`}
                                            src={img3}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className={`${'col-md-5 p-3 d-flex flex-column justify-center mt-5'}`}>
                        <h4>About us</h4>
                        <h2>Why do you choose us?</h2>
                        <p className={`${styles.aboutUs} ${'px-3'}`}>
                            Established in 2022-Othooy has the story of success and expansion. Readily recognized as the leading travel house in Bangladesh the company is owned and managed by a highly professional team. It has the GSA of Himalaya Airlines (H9) and PSA of VietjetAir (VJ).

                        </p>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    );
};

export default AboutUs;