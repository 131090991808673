import React from 'react';
import { Container, Grid } from '@mui/material';
import Blog from '../Blog/Blog';
import BlogSideber from '../BlogSidebar/BlogSidebar';
import Footer from '../../../shared/Footer/Footer';
import Navbar from '../../../shared/Navbar/Navbar';


const Blogs = () => {

    return (
        <div>
            <Navbar />
            <Container maxWidth="lg"
                sx={ {
                    py: 7
                } }>
                <Grid>
                    <Grid container spacing={ 2 }>

                        <Grid item xs={ 12 } sm={ 6 } md={ 9 }>
                            <Blog />
                        </Grid>

                        <Grid item xs={ 12 } sm={ 6 } md={ 3 } >
                            <BlogSideber />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </div>
    );
};

export default Blogs;