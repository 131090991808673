import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import HikingIcon from '@mui/icons-material/Hiking';
import NoteIcon from '@mui/icons-material/Note';
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import styles from './SidebarNavigationV2.module.css';
import useAuth from '../../../../hooks/useAuth';


const SidebarNavigationV2 = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <SpeedIcon />,

        },
        {
            path: "/user/profile",
            name: "Profile",
            icon: <PersonIcon />,
        },

        {
            path: "/user/traveller",
            name: "My Traveller",
            icon: <HikingIcon />,
        },
        {
            path: "/user/MyBooking",
            name: "My Booking",
            icon: <NoteIcon />,
        },
        // {
        //     path: "/user/AllBooking",
        //     name: "All Booking",
        //     icon: <NoteAltIcon />,
        // },
        // {
        //     path: "/user/Review",
        //     name: " Review",
        //     icon: <ReviewsIcon />,
        // },
        {
            path: "/user/Seetings",
            name: " Seetings",
            icon: <SettingsApplicationsIcon />,
        },
        {
            path: "/",
            name: " LogOut",
            icon: <LogoutIcon />,
        },
    ]

    const { logout, admin } = useAuth();

    return (
        <>
            <div className={`${styles.sidebar}`}>

                {menuItem.map((item, index) => (
                    <NavLink
                        to={item.path}
                        key={index}
                        className={`${styles.link}`}
                        activeclassName="active"
                    >
                        <div className="icon">{item.icon}</div>
                        <div
                            style={{ display: isOpen ? "block" : "none" }}
                            className={`${styles.linkText}`}
                        >
                            {item.name}
                        </div>
                    </NavLink>
                ))}
            </div>
            <main>{children}</main>
        </>
    )
};

export default SidebarNavigationV2;



// const AdminSidebarNavigation = () => {
//     // const { logout } = useAuth();
//     // const [admin, setAdmin] = React.useState(true);
//     const { auth } = useAdminAuth();
//     // block comment start
//     const [isOpen, setIsOpen] = useState(false);
//     const toggle = () => setIsOpen(!isOpen);


//     // block comment end
//     const navigate = useNavigate();
//     const adminLogout = useLogout();
//     const adminSignOut = async () => {
//         await adminLogout();
//         navigate('/')

//     }
//     return (
//         <Box
//             sx={{
//                 background: "#003366 !important",
//                 color: "#fff !important",
//             }}
//         >

//             <>
//                 {/*======= Dashboard Nav Menu Start ======*/}
//                 {
//                     auth?.roles?.find(role => [2001]?.includes(role)) &&

//                     <Accordion
//                         TransitionProps={{ unmountOnExit: true }}
//                         sx={{
//                             background: "#003366 !important",
//                             boxShadow: "none !important",
//                         }}
//                     >
//                         <AccordionSummary>
//                             <NavLink
//                                 activeclassName="active"
//                                 style={{ textDecoration: "none", marginTop: "0 !important" }}
//                                 to={`/`}
//                             >
//                                 <Button
//                                     sx={{
//                                         color: "#fff !important",
//                                         textTransform: "capitalize",
//                                         fontWeight: "300",
//                                         fontSize: "14px",
//                                     }}
//                                 >
//                                     <SpeedIcon sx={{ mr: 2, fontSize: "24px" }} />
//                                     Home
//                                 </Button>
//                             </NavLink>
//                         </AccordionSummary>
//                     </Accordion>
//                 }
//                 {/*======= Dashboard Nav Menu End ======*/}

//                 {/* Profile nav menu start */}
//                 <Accordion
//                     // TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                         marginTop: "0 !important",
//                         marginLeft: "15px"
//                     }}
//                 >
//                     {/* <AccordionSummary> */}
//                     <NavLink
//                         style={{ textDecoration: "none", marginTop: "0 !important" }}
//                         to={`/admin/adminProfile`}
//                     >
//                         {/* <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                                 paddingLeft: "50px",

//                             }}
//                         >
//                             <PersonIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             My Profile
//                         </Button> */}
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",


//                             }}
//                         >
//                             <PersonIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             My Profile
//                         </Button>
//                     </NavLink>
//                     {/* </AccordionSummary> */}
//                 </Accordion>
//                 {/* Profile nav menu end */}

//                 {/*======= Sell Nav Menu Start ======*/}
//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                     }}
//                 >
//                     <AccordionSummary
//                         expandIcon={
//                             <ArrowBackIosNewIcon
//                                 sx={{ color: "#fff !important", fontSize: "12px" }}
//                             />
//                         }
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                         sx={{
//                             "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//                                 transform: "rotate(-90deg)",
//                                 marginBottom: 0,
//                             },
//                         }}
//                     >
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                             }}
//                         >
//                             <PointOfSaleIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             Sell
//                         </Button>
//                     </AccordionSummary>
//                     <AccordionDetails sx={{ background: "#012C56 !important" }}>
//                         <NavLink
//                             className={`${'styles.active'}`}
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/AllBooking`}
//                         >
//                             <Button
//                                 // style={{ display: isOpen ? "block" : "none" }}
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                     // width: "239px"
//                                 }}
//                             // className='submenu'
//                             >
//                                 <Filter1Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> All Booking
//                             </Button>
//                         </NavLink>

//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/FindByPnr`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                     // width: "239px"
//                                 }}
//                             // className='submenu'
//                             >
//                                 <Filter3Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Find By Pnr
//                             </Button>
//                         </NavLink>

//                     </AccordionDetails>
//                 </Accordion>
//                 {/*======= Sell Nav Menu End ======*/}

//                 {/* Package nav menu starts */}
//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                     }}
//                 >
//                     <AccordionSummary
//                         expandIcon={
//                             <ArrowBackIosNewIcon
//                                 sx={{ color: "#fff !important", fontSize: "12px" }}
//                             />
//                         }
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                         sx={{
//                             "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//                                 transform: "rotate(-90deg)",
//                                 marginBottom: 0,
//                             },
//                         }}
//                     >
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                             }}
//                         >
//                             <Inventory2Icon sx={{ mr: 2, fontSize: "24px" }} />
//                             Package
//                         </Button>
//                     </AccordionSummary>
//                     <AccordionDetails sx={{ background: "#012C56 !important" }}>
//                         {/* <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/Category`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                     width: "100%"
//                                 }}
//                             >
//                                 <Filter1Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Category
//                             </Button>
//                         </NavLink> */}

//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/UploadPackage`}
//                         >
//                             {/*   to={`/admin/AddNewPackageProduct`} */}
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter3Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Add Package
//                             </Button>
//                         </NavLink>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/ManagePackage`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter4Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Manage Package
//                             </Button>
//                         </NavLink>
//                     </AccordionDetails>
//                 </Accordion>
//                 {/* Package nav menu ends */}

//                 {/* My Account nav menu start */}
//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                     }}
//                 >
//                     <AccordionSummary
//                         expandIcon={
//                             <ArrowBackIosNewIcon
//                                 sx={{ color: "#fff !important", fontSize: "12px" }}
//                             />
//                         }
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                         sx={{
//                             "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//                                 transform: "rotate(-90deg)",
//                                 marginBottom: 0,
//                             },
//                         }}
//                     >
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                             }}
//                         >
//                             <CurrencyExchangeIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             Accounts
//                         </Button>
//                     </AccordionSummary>
//                     <AccordionDetails sx={{ background: "#012C56 !important" }}>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/ManagePayment`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter1Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Payment
//                             </Button>
//                         </NavLink>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/ManageTransaction`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter2Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Manage Transaction
//                             </Button>
//                         </NavLink>


//                     </AccordionDetails>
//                 </Accordion>
//                 {/* My Account nav menu end */}

//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                     }}
//                 >
//                     <AccordionSummary
//                         expandIcon={
//                             <ArrowBackIosNewIcon
//                                 sx={{ color: "#fff !important", fontSize: "12px" }}
//                             />
//                         }
//                         aria-controls="panel2a-content"
//                         id="panel2a-header"
//                         sx={{
//                             "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//                                 transform: "rotate(-90deg)",
//                                 marginBottom: 0,
//                             },
//                         }}
//                     >
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                             }}
//                         >
//                             <PaidIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             Expense
//                         </Button>
//                     </AccordionSummary>
//                     <AccordionDetails sx={{ background: "#012C56 !important" }}>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/ManageExpense`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter1Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Manage Expense
//                             </Button>
//                         </NavLink>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to={`/admin/AddExpense`}
//                         >
//                             <Button
//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "700",
//                                     fontSize: "12px",
//                                 }}
//                             >
//                                 <Filter2Icon sx={{ mr: 1, ml: 1, color: "yellow" }} /> Add Expense
//                             </Button>
//                         </NavLink>


//                     </AccordionDetails>
//                 </Accordion>

//                 {/* My Expenxe nav menu ends */}

//                 {/* Seetings nav menu starts here  */}
//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                         marginTop: "0 !important",
//                         marginLeft: "15px"
//                     }}
//                 >
//                     <NavLink
//                         style={{ textDecoration: "none", marginTop: "0 !important" }}
//                         to={`/admin/adminSeetings`}
//                     >
//                         <Button
//                             sx={{
//                                 color: "#fff !important",
//                                 textTransform: "capitalize",
//                                 fontWeight: "300",
//                                 fontSize: "16px",
//                             }}
//                         >
//                             <SettingsApplicationsIcon sx={{ mr: 2, fontSize: "24px" }} />
//                             Seetings
//                         </Button>
//                     </NavLink>
//                 </Accordion>

//                 {/* Seetings nav menu ends here  */}


//                 {/*======= Logout Nav Menu Start ======*/}
//                 <Accordion
//                     TransitionProps={{ unmountOnExit: true }}
//                     sx={{
//                         background: "#003366 !important",
//                         boxShadow: "none !important",
//                         marginTop: "0 !important",
//                     }}
//                 >
//                     <AccordionSummary>
//                         <NavLink
//                             style={{ textDecoration: "none", marginTop: "0 !important" }}
//                             to="/"
//                         >
//                             <Button

//                                 sx={{
//                                     color: "#fff !important",
//                                     textTransform: "capitalize",
//                                     fontWeight: "300",
//                                     fontSize: "16px",
//                                 }}
//                                 onClick={adminSignOut}
//                             >
//                                 <LogoutIcon sx={{ mr: 2, fontSize: "24px" }} />
//                                 LogOutV2
//                             </Button>
//                         </NavLink>
//                     </AccordionSummary>
//                 </Accordion>
//                 {/*======= Logout Nav Menu End ======*/}
//             </>

//         </Box>
//     );
// };