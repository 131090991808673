import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import HikingIcon from '@mui/icons-material/Hiking';
import NoteIcon from '@mui/icons-material/Note';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import styles from './SidebarNavigationV2.module.css';
import useAuth from '../../../../hooks/useAuth';


const SidebarNavigationV3 = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <SpeedIcon />,

        },
        {
            path: "/user/profile",
            name: "Profile",
            icon: <PersonIcon />,
        },

        {
            path: "/user/traveller",
            name: "My Traveller",
            icon: <HikingIcon />,
        },
        {
            path: "/user/MyBooking",
            name: "My Booking",
            icon: <NoteIcon />,
        },
        {
            path: "/user/AllBooking",
            name: "All Booking",
            icon: <NoteAltIcon />,
        },
        {
            path: "/user/Review",
            name: " Review",
            icon: <ReviewsIcon />,
        },
        {
            path: "/user/Seetings",
            name: " Seetings",
            icon: <SettingsApplicationsIcon />,
        },
        {
            path: "/",
            name: " LogOut",
            icon: <LogoutIcon />,
        },
    ]

    const { logout, admin } = useAuth();

    return (
        <>
            <div className={`${styles.sidebar}`}>

                {menuItem.map((item, index) => (
                    <NavLink
                        to={item.path}
                        key={index}
                        className={`${styles.link}`}
                        activeclassName="active"
                    >
                        <div className="icon">{item.icon}</div>
                        <div
                            style={{ display: isOpen ? "block" : "none" }}
                            className={`${styles.linkText}`}
                        >
                            {item.name}
                        </div>
                    </NavLink>
                ))}
            </div>
            {/* <main>{children}</main> */}
        </>
    )
};

export default SidebarNavigationV3;