import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import logov2 from "../../../../images/logov2.png"

import logov2 from "../../../src/assets/images/logov2.png";
import styles from "./Navbar.module.css";
// import useAdminAuth from "../../../../hooks/useAdminAuth";
// import useLogout from "../../../../hooks/useLogout";
import useAuth from "../../hooks/useAuth";

const Navbar = () => {
  const location = useLocation();
  const pathDir = location.pathname;
  const { user, logout } = useAuth();

  // console.log(pathDir)
  // const { auth } = useAdminAuth();
  const navigate = useNavigate();
  // const adminLogout = useLogout();
  // const adminSignOut = async () => {
  //     await adminLogout();
  //     navigate('/')

  // }
  // console.log(auth);

  const pages = [
    "Home",
    "Services",
    "About",
    "Contact",
    "Schedule",
    // "Blogs",
  ];
  // const settings = [""];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [scrollForAppBar, setScrollForAppBar] = React.useState(true);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var scrollPosition = window.scrollY;
      if (scrollPosition >= 150) {
        setScrollForAppBar(false); // scrollForAppBar,
      } else {
        setScrollForAppBar(true); // do something else when scroll position is less than 150px
      }
    });
  }, []);





  const theme = createTheme({
    typography: {
      fontFamily: "Poppins", // Replace with your desired font family
    },
  });

  return (
    <ThemeProvider theme={ theme }>
      <Box
        sx={ {
          mb: pathDir !== "/" && pathDir !== "/Home" ? "35px" : "0px",
        } }
      >
        <AppBar
          // scrollForAppBar
          sx={ {
            background: "white",
            color: "green",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 10px",
          } }
        >
          <Container>
            <Toolbar disableGutters sx={ { p: 0, m: 0 } }>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={ { mr: 2, display: { xs: "none", md: "flex" } } }
              >
                <Link to="/">
                  <img src={ logov2 } width="180px" alt="logo" />
                </Link>
              </Typography>

              <Box sx={ { flexGrow: 1, display: { xs: "flex", md: "none" } } }>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={ handleOpenNavMenu }
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={ anchorElNav }
                  anchorOrigin={ {
                    vertical: "bottom",
                    horizontal: "left",
                  } }
                  keepMounted
                  transformOrigin={ {
                    vertical: "top",
                    horizontal: "left",
                  } }
                  open={ Boolean(anchorElNav) }
                  onClose={ handleCloseNavMenu }
                  sx={ {
                    display: { xs: "block", md: "none" },
                  } }
                >
                  { pages.map((page) => (
                    <Typography
                      key={ page }
                      onClick={ handleCloseNavMenu }
                      sx={ { ml: 2, color: "purple", display: "block" } }
                    >
                      <Link
                        style={ {
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 600,
                        } }
                        className={ `${styles.navbarLink}` }
                        to={ `/${page}` }
                      >
                        <span
                          style={ { textDecoration: "none", fontWeight: 600 } }
                        >
                          { page }
                        </span>
                      </Link>
                    </Typography>
                  )) }
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={ { flexGrow: 1, display: { xs: "flex", md: "none" } } }
              >
                <Link to="/">
                  <img
                    className={ `${styles.hero}` }
                    src={ logov2 }
                    width="120px"
                    alt="logo"
                  />
                </Link>
              </Typography>
              <Box
                sx={ {
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: { md: "flex-end" },
                } }
              >
                { pages.map((page) => (
                  <Typography
                    key={ page }
                    onClick={ handleCloseNavMenu }
                    sx={ { ml: 2, color: "white", display: "block" } }
                  >
                    <Link
                      style={ {
                        textDecoration: "none",
                        color:
                          pathDir === "/" && scrollForAppBar
                            ? "#81c784"
                            : pathDir === "/Home" && scrollForAppBar
                              ? "#81c784"
                              : "#388e3c",
                      } }
                      className={ `${styles.navbarLink}` }
                      to={ `/${page}` }
                    >
                      <span style={ { textDecoration: "none" } }>{ page }</span>
                    </Link>
                  </Typography>
                )) }
              </Box>
              <Typography
                sx={ {
                  ml: 2,
                  color:
                    pathDir === "/" && scrollForAppBar
                      ? "#81c784"
                      : pathDir === "/Home" && scrollForAppBar
                        ? "#81c784"
                        : "#388e3c",
                  display: "block",
                } }
              >
                { user?.displayName }
              </Typography>
              <Box
                sx={ {
                  flexGrow: 0,
                } }
              >
                <Tooltip title="Open settings">
                  <IconButton onClick={ handleOpenUserMenu }>
                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */ }
                    {/* <Avatar alt="Remy Sharp" /> */ }
                    <Avatar alt="Remy Sharp" src={ user?.photoURL } />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={ anchorElUser }
                  anchorOrigin={ {
                    vertical: "top",
                    horizontal: "right",
                  } }
                  keepMounted
                  transformOrigin={ {
                    vertical: "top",
                    horizontal: "right",
                  } }
                  open={ Boolean(anchorElUser) }
                  onClose={ handleCloseUserMenu }
                >
                  {/* {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>

                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                                fontWeight: 600,
                                            }}
                                            to={`/${setting}`}
                                        >
                                            {setting}
                                        </Link>
                                    </MenuItem>
                                ))} */}

                  { user?.email && (
                    <MenuItem>
                      <Link
                        style={ {
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 600,
                        } }
                        to="/user/profile"
                      >
                        Profile
                      </Link>
                    </MenuItem>
                  ) }
                  <MenuItem>
                    { user?.email ? (
                      <Typography
                        sx={ {
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 600,
                        } }
                        onClick={ logout }
                      >
                        SignOut
                      </Typography>
                    ) : (
                      <Link
                        style={ {
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 600,
                        } }
                        to="/account/signin"
                      >
                        SignIn
                      </Link>
                    ) }
                  </MenuItem>

                  {/* {
                                    user.email && <MenuItem>
                                        <Link
                                            style={ {
                                                textDecoration: "none",
                                                color: "black",
                                                fontWeight: 600,
                                            } }
                                            to="/user/profile"
                                        >
                                            Dashboard
                                        </Link>
                                    </MenuItem>
                                } */}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};

export default Navbar;
