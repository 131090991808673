import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChangePasswordList from '../pages/Dashboard/MyProfileOption/MySeetingsOption/ChangePasswordList';
import ManageProfileList from '../pages/Dashboard/MyProfileOption/ManageProfileList';
import ManageTravellerList from '../pages/Dashboard/MyProfileOption/Traveller/ManageTravellerList';
import ManageAddTravellerList from '../pages/Dashboard/MyProfileOption/Traveller/ManageAddTravellerList';
import MySeetingsList from '../pages/Dashboard/MyProfileOption/MySeetingsOption/MySeetingsList';
import Sweetalert from '../pages/Dashboard/DashboardV1/Sweetalert/Sweetalert';
import TravellerTest from '../pages/Dashboard/MyProfileOption/Traveller/TravellerTest/TravellerTest';
import AdminRoute from '../pages/UserFile/UserLogin/AdminRoute/AdminRoute';
import EditProfileList from '../pages/Dashboard/MyProfileOption/EditProfileOption/EditProfileList';
import BookingDetails from '../pages/Dashboard/DashboardV1/BookingDetails/BookingDetails';
import InvoiceOthooy from '../pages/Dashboard/DashboardV1/DashboardNavbarV1/PaymentOthooy/InvoiceOthooy';
import ETicket from '../pages/Dashboard/DashboardV1/DashboardHomeV1/ETicket/ETicket';
import ManageBookingList from '../pages/Dashboard/UserDashboardHome/MyBookingOption/ManageBookingList';

const UserDashboardRoutes = () => {
    return (
        <Routes>
            {/* User Dashboard v1 starts */}
            <Route path="ChangePassword" element={<ChangePasswordList />}></Route>
            <Route path="profile" element={<ManageProfileList />}></Route>
            <Route path="traveller" element={<ManageTravellerList />}></Route>
            <Route path="addTraveller" element={<ManageAddTravellerList />}></Route>
            <Route path="MyBooking" element={<ManageBookingList />}></Route>
            <Route path="Seetings" element={<MySeetingsList />}></Route>
            <Route path="alert" element={<Sweetalert />}></Route>
            <Route path="test" element={<TravellerTest />}></Route>
            <Route path="EditProfile" element={<AdminRoute>
                <EditProfileList />
            </AdminRoute>} />
            <Route path="bookingDetails" element={<BookingDetails />}></Route>
            <Route path="invoice" element={<InvoiceOthooy />}></Route>
            <Route path="eticket" element={<ETicket />}></Route>
            {/* User Dashboard v1 end */}
        </Routes>
    );
};

export default UserDashboardRoutes;