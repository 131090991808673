import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

// import logov2 from '../../../src/assets/images/logov2.png';
import othooyLogo from "../../../../assets/images/logo.png";
import axios from "../../../../api/axios";
import { Navigate, useParams } from "react-router-dom";
import useFetch from "../../../../context/useFetch";
import useAuth from "../../../../hooks/useAuth";

const UserBalance = ({ totalFares, bookiedId }) => {
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [failMassage, setFailMassage] = useState("");
  const path = useParams();
  // console.log('path', path.id)
  // const { data } = useFetch(`/api/v1/balance/show?email=${'champmahfuz@gmail.com'}`);
  const { privateData } = useFetch(`/api/v1/balance/show?email=${user?.email}`);
  const userBalance = privateData;

  // console.log(user.email);

  const userBalancePayment = async () => {
    setLoading(true);

    setLoading(false);

    try {
      // const response = await fetch(`http://localhost:7000/api/v1/balance/debit`, {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_API_baseURL}/api/v1/balance/debit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // "email": "champmahfuz@gmail.com",
            email: user.email,
            // "email": user.email,
            amount: 50,
            bookiedId: path.id,
          }),
        }
      );

      if (response.ok) {
        // console.log(response);
        console.log("Post created successfully");
        window.location.replace(`/payment-success/${path.id}`);
      } else {
        console.error("Failed to create post");
        setFailMassage(
          "Fail to procced, please try again or contact with admin"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setFailMassage(
        "Something went wrong, please try again or contact with admin"
      );
    }
  };
  return (
    <div>
      <Paper elevation={ 3 } sx={ { p: 5 } }>
        <p>The Fare will be adjust from you balance</p>
        <Grid container spacing={ 2 }>
          { failMassage }
          <Grid item xs={ 6 } sx={ { textAlign: "right", my: 7 } }>
            {/* <img src={ othooyLogo } alt="othooyLogo" width='100%' /> */ }
            <h6>Your Balance is: { userBalance?.balance }</h6>
          </Grid>
          {/* <Grid item xs={ 4 } sx={ { textAlign: 'center', my: 7 } }> */ }
          {/* <CompareArrowsIcon sx={ { fontSize: '60px' } } /> */ }
          {/* </Grid> */ }

          <Grid item xs={ 6 } sx={ { textAlign: "left", my: 7 } }>
            <h6>
              { " " }
              Total Payable: { totalFares.totalPayableFare.toLocaleString() }
            </h6>
            {/* <img src={ 'https://1.bp.blogspot.com/-C0dIOLQbYNk/X5CFGVhc-0I/AAAAAAAAJcY/ThrvX8Z6jeYeRadXAjSOV-duzv5DZTKiQCPcBGAYYCw/s0/brac%2Bbank_jobeduexam.png' } alt="othooyLogo" width='70%' /> */ }
            {/* <img src={ 'https://www.tpsworldwide.com/wp-content/uploads/2017/10/brac-bank-Limited-logo-Bangladesh.jpg' } alt="othooyLogo" width='80%' /> */ }
          </Grid>
        </Grid>
        <Grid container spacing={ 2 } sx={ { textAlign: "center" } }>
          <Grid item xs={ 12 } sx={ { p: 7 } }>
            <p>
              By continuing to pay, I understand and agree with the follwoing
              privacy pioicy and terms of condition of Othooy
            </p>
            <Grid sx={ { mt: 5 } }>
              {/* <Button onClick={ userBalancePayment } variant="contained" color="success">Proceed to Purchase</Button> */ }
              { userBalance?.balance > totalFares.totalPayableFare && (
                <Button
                  onClick={ userBalancePayment }
                  variant="contained"
                  color="success"
                  disabled={ loading ? true : false }
                // endIcon={ }
                // fullWidth
                >
                  { loading ? (
                    <CircularProgress size={ 20 } />
                  ) : (
                    <>
                      { " " }
                      Proceed to Purchase <SendIcon />{ " " }
                    </>
                  ) }
                </Button>
              ) }
            </Grid>
            <Grid sx={ { mt: 5 } }></Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default UserBalance;
