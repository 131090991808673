/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import HikingIcon from '@mui/icons-material/Hiking';
import styles from './ManageAddTravellerList.module.css';
import Loading from '../../../../components/Loading/Loading';
import useAuth from '../../../../hooks/useAuth';
import useFetch from '../../../../context/useFetch';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import BookingModal from './TravellerTest/BookingModal/BookingModal';

import axios, { axiosPrivate } from '../../../../api/axios';
import Cookies from 'js-cookie';

const tdAndThStyle = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px'
}
const tdAndThStyle2 = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    cursor: 'pointer'
}
// const showAlert = () => {
//     swal({
//         title: "Are you sure?",
//         text: "Once deleted, you will not be able to recover this imaginary file!",
//         icon: "warning",
//         buttons: true,
//         dangerMode: true,
//     })
//         .then((willDelete) => {
//             if (willDelete) {
//                 swal("Poof! Your imaginary file has been deleted!", {
//                     icon: "success",
//                 });
//             } else {
//                 swal("Your imaginary file is safe!");
//             }
//         });
// };



const showAlert = () => {
    swal({
        title: "Edit your profile?",
        content: {
            element: "div",
            attributes: {
                className: "inputs-container",
                innerHTML: `
                    <input id="input1" type="text" placeholder="Name" style="width: 100%; margin-bottom: 10px; padding:5px">
                    <input id="input2" type="text" placeholder="Email" style="width: 100%; margin-bottom: 10px;padding:5px">
                    <input id="input3" type="text" placeholder="Date of birth" style="width: 100%; margin-bottom: 10px; padding:5px">
                    <input id="input4" type="text" placeholder="Type" style="width: 100%;  padding:5px">
                `
            }
        },

        buttons: true,
        successMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {
                swal("Poof! Your imaginary file has been deleted!", {
                    icon: "success",
                });
            } else {
                swal("Your imaginary file is safe!");
            }
        });
};



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};





const ManageTravellerList = () => {
    const [openBooking, setBookingOpen] = React.useState(false);
    const handleBookingOpen = () => setBookingOpen(true);
    const handleBookingClose = () => setBookingOpen(false);

    const { user, isLoading } = useAuth();
    // const { data } = useFetch(`/api/v1/othooyUsers/userTravellerList/${user.email}`);
    const [travelerData, setTravellerData] = useState([]);
    // const [travelerDataError, setTravellerDataError] = useState(data);
    // console.log(data);
    // console.log(travelerData);
    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axiosPrivate.get(`/api/v1/othooyUsers/userTravellerList/${user.email}`);
                setTravellerData(response.data);
                window.localStorage.removeItem('time');
                // window.localStorage.removeItem('selectedFlight');

            } catch (err) {
                console.log(err)
            }
        };
        fetchData();

    }, [user.email]);
    const handleDeleteSavedPassenger = async (traveller) => {
        // Retrieve access token from cookie
        const accessToken = Cookies.get('accessToken');

        // Set headers with access token
        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };
        try {
            await axiosPrivate.delete(`/api/v1/othooyUsers/deleteUserTraveller/${traveller}`);
            console.log('User deleted successfully.', traveller);
            try {
                const response = await axiosPrivate.get(`/api/v1/othooyUsers/userTravellerList/${user.email}`);
                setTravellerData(response.data);
                window.localStorage.removeItem('time');
                // window.localStorage.removeItem('selectedFlight');

            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
        console.log(travelerData);
    };

    const handleEditSavePassenger = (traveller) => {
        console.log(travelerData);
    }

    if (isLoading) {
        return <Loading></Loading>
    }


    // const handleDeleteSavePassenger = (traveller) => {
    //     console.log(traveller);
    // }

    return (
        <Container >
            <Box className={ `${styles.topContainer}` } sx={ { display: "flex", my: 3 } }>
                <Typography>
                    <HikingIcon className={ `${styles.assignmentIcon}` } />{ " " }
                </Typography>
                <Typography>
                    <span style={ { fontSize: "26px" } }>Traveller</span> <br />{ " " }
                </Typography>
            </Box>

            <Box sx={ { mb: '30px' } } className={ `${styles.tableContainer}` }>
                <Typography sx={ { fontSize: '25px', fontWeight: 'bold' } }>Traveller Info</Typography>
                <p>You have 1 traveler</p>
                <Grid sx={ { display: 'flex' } }>
                    <Grid sx={ { mt: '8px' } } xs={ 12 } md={ 6 }>
                        <Typography>{ user?.displayName && user?.displayName }</Typography>
                        <Typography>{ user?.email }</Typography>
                    </Grid>
                    <Grid sx={ { ml: '20px' } } xs={ 12 } md={ 6 }>
                        <Button sx={ { textTransform: 'unset' } } variant="outlined">Primary Traveller</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={ 2 }>
                    <Grid sx={ { mt: 2 } } item xs={ 12 } md={ 6 }>

                    </Grid>

                </Grid>
                <Link to='/user/addTraveller'>
                    <Button sx={ { textTransform: 'unset' } } startIcon={ <AddIcon /> }>Add Traveller</Button>
                </Link>
            </Box>

            <Box>
                <table style={ {
                    borderCollapse: 'collapse',
                    width: '100%'
                } }>
                    <tr>
                        <th style={ tdAndThStyle }>Title</th>
                        <th style={ tdAndThStyle }>Name</th>
                        <th style={ tdAndThStyle }>Email</th>
                        <th style={ tdAndThStyle }>Date Of Birth</th>
                        <th style={ tdAndThStyle }>Type</th>
                        <th style={ tdAndThStyle }>Edit</th>
                        <th style={ tdAndThStyle }>Delete</th>
                    </tr>
                    {
                        travelerData.map((traveller, index) => (
                            <tr
                                key={ index }
                                style={ {
                                    backgroundColor: index % 2 === 1 ? '#dddddd' : 'inherit',
                                } }
                            >
                                <td style={ tdAndThStyle }>{ traveller.title } </td>
                                <td style={ tdAndThStyle }>{ traveller.firstname }{ traveller.lastname }</td>
                                <td style={ tdAndThStyle }>{ traveller.email }</td>
                                <td style={ tdAndThStyle }>{ traveller.dateOfBirth }</td>
                                <td style={ tdAndThStyle }>{ traveller.PassengerType }</td>
                                <td style={ tdAndThStyle2 }><DriveFileRenameOutlineIcon onClick={ showAlert }  >
                                    <BookingModal />
                                </DriveFileRenameOutlineIcon >

                                </td>
                                <td style={ tdAndThStyle2 } onClick={ handleDeleteSavedPassenger }><DeleteForeverIcon /></td>


                            </tr >

                        ))
                    }


                </table >
            </Box >
            <Box sx={ { py: 5 } }>

            </Box>

        </Container >
    );
};

export default ManageTravellerList;