import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Alert, Grid } from '@mui/material';
import { Paper } from "@mui/material";
import "./ReviewDetails.css";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from '../../../../hooks/useAuth';
import axios, { axiosPrivate } from '../../../../api/axios';
import LoginPopupComponent from '../../../UserFile/UserLogin/LoginPopupComponent';
import useValue from '../../../../hooks/useValue';


const ReviewDetailsV3 = ({ passengerDetailsForm, flightSegments }) => {
    const { user } = useAuth();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: user?.email ? "70%" : 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',        
        maxHeight: '70vh',
        overflow: 'scroll',
        boxShadow: 24,
        p: 4,
    };
    // console.log(isInternationalFlight);
    // console.log(auth);

    const navigate = useNavigate();
    const { passengersTotal, isInternationalFlight,
        baseFare, taxFare, subTotalPrice, discountPercentage, aitPercentage, calculatedFares,
    } = useValue({});

    const calculatedFareInfo = {
        baseFare,
        taxFare,
        subTotalPrice,
        discountPercentage,
        aitPercentage,
        ...calculatedFares
    }
    // console.log(calculatedFareInfo)
    const passengersTotalData = JSON.stringify(passengersTotal);
    const calculatedFareInfoData = JSON.stringify(calculatedFareInfo);

    const [loading, setLoading] = useState(false);
    const [failMassage, setFailMassage] = useState('');
    // console.log(isInternationalFlight);
    // console.log(auth);

    const [loadingMassage, setLoadingMassage] = useState({});

    const selectedFlights = window.localStorage.getItem("selectedFlight");
    // console.log(selectedFlights)

    // navigate(`/Payment/${response.data._id}`);

    const travellerInfo = JSON.stringify(passengerDetailsForm);
    const flightSegment = JSON.stringify(flightSegments);

    const gdsPnrData = async () => {
        try {
            // const gdsPnrResponse = await axios.get(`api/createPNR?passengersTotalData=${passengersTotalData}&travellerInfo=${travellerInfo}&flightSegment=${flightSegment}`);
            const gdsPnrResponse = await axiosPrivate.get(
                `api/v1/createPNR?passengersTotalData=${passengersTotalData}&travellerInfo=${travellerInfo}&flightSegment=${flightSegment}&email=${user.email}&selectedFlights=${selectedFlights}&isInternationalFlight=${isInternationalFlight}&calculatedFareInfoData=${calculatedFareInfoData}`
            );
            // console.log(auth);
            console.log(gdsPnrResponse);
            if (gdsPnrResponse.data.id) {
                navigate(`/Payment/${gdsPnrResponse.data.id}`);
            } else {
                setFailMassage("The Ticket creation is fail, Please try again")
            }

            // setLoadingMassage("Creating Airline PNR");
        } catch (error) {
            console.error("error", error);
        }
        setLoading(false);
    };

    // console.log(user.email)

    const confirmBooking = async (e) => {
        setLoading(true);
        // setLoadingMassage("Creating GDS PNR");
        e.preventDefault();

        // navigate("/Payment/1");
        // const x = 1
        // console.log((x + 1))
        gdsPnrData();
    };
    const passengerDetailsFormDataForCheck = passengerDetailsForm.selectedPassenger || passengerDetailsForm;
    const isFormValid = () => {
        if (isInternationalFlight) {
            return passengerDetailsFormDataForCheck.every(
                (value) =>
                    value.title &&
                    value.firstname &&
                    value.lastname &&
                    value.dateOfBirth &&
                    value.Country &&
                    value.email &&
                    value.phoneNumber &&
                    value.passportnumber &&
                    value.passengerPassportExpirtyDate !== ""
            );

        } else {
            return passengerDetailsFormDataForCheck.every(
                (value) =>
                    value.title &&
                    value.firstname &&
                    value.lastname &&
                    value.dateOfBirth &&
                    value.email &&
                    value.phoneNumber !== ""
            );
        }

    };
    return (
        <div>
            <Button
                disabled={ !isFormValid() }
                onClick={ handleOpen }
                variant="contained"
                color="success"
                fullWidth  >
                { !isFormValid() ? "Fill Up The Form Currectly" : "CONTINUE" }
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ open }
                // onClose={ handleClose }
                closeAfterTransition
                slots={ { backdrop: Backdrop } }
                slotProps={ {
                    backdrop: {
                        timeout: 500,
                    },
                } }
            >
                <Fade in={ open }>
                    <Box sx={ style }>

                        {
                            user?.email ? (
                                <div>
                                    <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                                        <h4>
                                            <b> Review Details</b>

                                        </h4>
                                        <CancelIcon onClick={ handleClose } style={ { fontSize: '45px', marginTop: '-10px', color: 'red', cursor: 'pointer' } } />

                                    </div>
                                    <Alert severity="warning">
                                        <h6 color="red">Important !</h6>
                                        <small style={ { color: "#333" } }>
                                            { " " }
                                            Please re-check the passenger Details{ " " }
                                            <b> (e.g: Name, Date of Birth, Passport Number)</b>{ " " }
                                            otherwise the airlines will penalise you for providing
                                            incorrect information.
                                        </small>
                                    </Alert>
                                    {
                                        failMassage &&
                                        <Alert severity="error">
                                            <h6 color="red">Fail !</h6>
                                            <small style={ {} }>
                                                { failMassage }
                                            </small>
                                        </Alert>
                                    }

                                    <Grid container spacing={ 2 } sx={ { mt: 1 } }>
                                        <Grid item xs={ 12 } md={ 6 }>
                                            <h5>Pessanger Details: </h5>
                                        </Grid>
                                        <Grid item xs={ 12 } md={ 6 }>
                                            Total { passengerDetailsForm.length } Pessanger's
                                        </Grid>
                                    </Grid>
                                    {/*   { loading ? (
                                        `LOADING For ${loadingMassage}`
                                    ) :   ( */ }
                                    <Paper sx={ { p: 1 } }>
                                        { passengerDetailsForm.map(
                                            (passengerDetailsFormData, index) => (
                                                <Grid key={ index } container spacing={ 0 } sx={ { mt: 1 } }>
                                                    <Grid item xs={ 12 } sm={ 6 } md={ 3 } sx={ { p: 0, m: 0 } }>
                                                        <b>Traveler { index + 1 }</b> <br></br>
                                                        <small>Given Name / First Name</small>
                                                        <br></br>
                                                        { passengerDetailsFormData.firstname }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={ 12 }
                                                        sm={ 6 }
                                                        md={ 2.5 }
                                                        sx={ { p: 0, m: 0 } }
                                                    >
                                                        <b> </b>
                                                        { passengerDetailsFormData.PassengerType }
                                                        <br></br>
                                                        <small>Surname / Last Name</small>
                                                        <br></br>
                                                        { passengerDetailsFormData.lastname }
                                                    </Grid>
                                                    <Grid item xs={ 12 } sm={ 4 } md={ 2 } sx={ { p: 0, m: 0 } }>
                                                        <b> </b>
                                                        <br></br>
                                                        <small>Date of Birth</small>
                                                        <br></br>
                                                        { passengerDetailsFormData.dateOfBirth }
                                                    </Grid>

                                                    <Grid item xs={ 12 } sm={ 4 } md={ 2 } sx={ { p: 0, m: 0 } }>
                                                        <b> </b>
                                                        <br></br>
                                                        <small>Passport Number</small>
                                                        <br></br>
                                                        { passengerDetailsFormData.passportnumber }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={ 12 }
                                                        sm={ 4 }
                                                        md={ 2.5 }
                                                        sx={ { p: 0, m: 0 } }
                                                    >
                                                        <b> </b>
                                                        <br></br>
                                                        <small>Passport Expiry Date</small>
                                                        <br></br>
                                                        {
                                                            passengerDetailsFormData.passengerPassportExpirtyDate
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        ) }
                                    </Paper>
                                    {/* )  } */ }

                                    <Grid container spacing={ 2 } sx={ { mt: 1 } }>
                                        <Grid item xs={ 12 } sx={ { textAlign: "center" } }>
                                            <small>
                                                Your booking will be confirmed and held for 20 minutes to
                                                complete payment.
                                            </small>
                                        </Grid>
                                        <Grid item xs={ 12 } md={ 6 }>
                                            <Button
                                                variant="outlined"
                                                color="success"
                                                onClick={ handleClose }
                                                fullWidth
                                                disabled={ loading ? true : false }
                                            >
                                                Edit Details
                                            </Button>
                                        </Grid>
                                        {/* endIcon={ loading ? <CircularProgress style={ { fontSize: '20px' } } color="inherit" /> : <SendIcon /> } */ }
                                        <Grid item xs={ 12 } md={ 6 }>
                                            <Button
                                                onClick={ confirmBooking }
                                                variant="contained"
                                                color="success"
                                                disabled={ loading ? true : false }
                                                // endIcon={ }
                                                fullWidth
                                            >
                                                {
                                                    loading ? <CircularProgress size={ 20 } /> :
                                                        <>  Confirm Booking <SendIcon />  </>
                                                }
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </div>
                            ) : (
                                <div style={ { textAlign: "center" } }>
                                    <LoginPopupComponent handleClose={ handleClose } />
                                </div>
                            )
                        }
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
};

export default ReviewDetailsV3;