import React from 'react';
import styles from './PaymentError.module.css';
import MyImg from '../../../../../assets/images/plane2.png';

const PaymentError = () => {
    return (

        <div className={ `${styles.mainBody}` }>
            <section className={ `${styles.side}` }>
                <img className={ `${styles.pic}` } src={ MyImg } alt="" />
            </section>


            <section className={ `${styles.main}` }>
                <div className={ `${styles.successContainer}` }>
                    <p className={ `${styles.title}` }>Opps ! Failed</p>
                    <div className={ `${styles.separator}` }></div>
                    <p className={ `${styles.welcomeMessage}` }>Your payment has been failed</p>

                    <div className={ `${styles.successForm}` }>
                        <div className={ `${styles.successControl}` }>
                            <p>Try Again</p>
                        </div>
                        <div className={ `${styles.successControl}` }>
                            <p>Thank You</p>
                        </div>
                    </div>
                    <button className={ `${styles.submit}` }>Go Back</button>
                </div>
            </section>
        </div>
    );
};

export default PaymentError;