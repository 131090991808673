import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { useState } from 'react';

const FlightDetails = () => {

    const selectedFlight = JSON.parse(window.localStorage.getItem('selectedFlight'));
    // console.log(selectedFlight);
    const [activeDetails, setActiveDetails] = useState('baggage')

    const weights = selectedFlight.pricingInformation.map(pricingInformationData => (
        pricingInformationData.fare.passengerInfoList.map(passengerInfoListData => (
            passengerInfoListData.passengerInfo
        ))
    ))

    // console.log('weights', weights)
    // console.log(selectedFlight.pricingInformation[0].fare.passengerInfoList.map((passengerInfo, index) => (
    //     passengerInfo.passengerInfo.baggageInformation.map((baggageInfo, index) => (
    //         baggageInfo.allowance.baggageAllowance.weight
    //     ))

    // )))

    const thtdPadding = {
        padding: '5px'
    }


    return (
        <div>
            <div style={ { padding: '0px 0px', borderBottom: '1px solid red' } }>
                <span style={ { padding: ' 0px 5px', color: activeDetails === 'baggage' ? 'green' : '#333', borderBottom: activeDetails === 'baggage' ? '3px solid red' : 'none', fontWeight: 900 } } onClick={ e => setActiveDetails('baggage') }>Baggage</span>

                <span style={ { padding: ' 0px 30px', color: activeDetails === 'fare' ? 'green' : '#333', borderBottom: activeDetails === 'fare' ? '3px solid red' : 'none', fontWeight: 900 } }
                    onClick={ e => setActiveDetails('fare') }>Fare</span>

                <span style={ { padding: ' 0px 30px', color: activeDetails === 'policy' ? 'green' : '#333', borderBottom: activeDetails === 'policy' ? '3px solid red' : 'none', fontWeight: 900 } }
                    onClick={ e => setActiveDetails('policy') }>Policy</span>
            </div>

            {
                activeDetails === 'baggage' ?
                    <table style={ { width: '100%', marginTop: '20px', textAlign: 'left' } }>
                        <tbody>
                            <tr>
                                <th style={ thtdPadding }>Destination</th>
                                <th style={ thtdPadding }>Cabin</th>
                                <th style={ thtdPadding }>Baggage</th>
                            </tr>
                            {
                                selectedFlight?.legs.map((legsData, index) => (
                                    legsData.schedules.schedules.map((schedule, schedulesIndex) => (
                                        <tr key={ schedulesIndex }>
                                            <td style={ thtdPadding }>
                                                { schedule.departure.airport } -  { schedule.arrival.airport }
                                            </td>

                                            <td style={ thtdPadding }>07 KG or (N/A)</td>
                                            <td style={ thtdPadding }>{
                                                selectedFlight.pricingInformation[0].fare.passengerInfoList[0].passengerInfo.baggageInformation[0].allowance.baggageAllowance.weight

                                                /* selectedFlight.pricingInformation[0].fare.passengerInfoList.map((passengerInfo, index) => (
                                                    passengerInfo.passengerInfo.baggageInformation.map((baggageInfo, index) => (
                                                        baggageInfo.allowance.baggageAllowance.weight
                                                    ))

                                                )) */
                                            } KG / PERSON</td>
                                        </tr>
                                    ))
                                ))
                            }
                        </tbody>
                    </table> :
                    activeDetails === 'fare' ? <table style={ { width: '100%', marginTop: '20px', textAlign: 'left' } }>
                        <tbody>
                            <tr>
                                <th style={ thtdPadding }>Fare Summary</th>
                                <th style={ thtdPadding }>Base Fare</th>
                                <th style={ thtdPadding }>Tax</th>
                            </tr>
                            {
                                selectedFlight?.pricingInformation.map((pricingInformationData, index) => (

                                    pricingInformationData.fare.passengerInfoList.map((passengerInfoListData, index) => (
                                        <tr key={ index }>

                                            <td style={ thtdPadding }>{ passengerInfoListData.passengerInfo.passengerType } x { passengerInfoListData.passengerInfo.passengerNumber
                                            } </td>
                                            <td style={ thtdPadding }>
                                                { passengerInfoListData.passengerInfo.passengerNumber * passengerInfoListData.passengerInfo.passengerTotalFare.equivalentAmount

                                                }  BDT
                                            </td>
                                            <td style={ thtdPadding }>{ passengerInfoListData.passengerInfo.passengerNumber * passengerInfoListData.passengerInfo.passengerTotalFare.totalTaxAmount

                                            } BDT</td>
                                        </tr>
                                    ))

                                ))
                            }
                        </tbody>
                    </table> :
                        activeDetails === 'policy' ?
                            <table style={ { width: '100%', marginTop: '20px', textAlign: 'left' } }>
                                <tbody>
                                    <tr>
                                        <th style={ thtdPadding }>Cancellation:</th>
                                        <td style={ thtdPadding }>Refund Amount = Paid Amount - Airline's Cancellation Fee</td>
                                    </tr>
                                    <tr>

                                        <th style={ thtdPadding }>Re-issue:</th>
                                        <td style={ thtdPadding }>Re-issue Fee = Airline's Fee + Fare Difference</td>
                                    </tr>
                                    <tr>

                                        <th style={ thtdPadding }>Note:</th>
                                        <td style={ thtdPadding }>The airline's fee is indicative and per person. Convenience fee is non-refundable.</td>
                                    </tr>
                                </tbody>
                            </table> : ''
            }




        </div>
    );
};

export default FlightDetails;