import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Pagination } from '@mui/material';
import AvailableFlights from '../AvailableFlight/AvailableFlights/AvailableFlights';
import SearchSidebar from '../SearchSidebar/SearchSidebars/SearchSidebar';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import FlightCardsv4 from '../AvailableFlight/FlightCard/FlightCards/FlightCardsv4';
import useValue from '../../../../hooks/useValue';
import useFetch from '../../../../context/useFetch';
import Navbar from '../../../../shared/Navbar/Navbar';
import Footer from '../../../../shared/Footer/Footer';
import BookingTimeOutModal from '../../../../components/TimerComponents/timeOutComponent/bookingTimeOutModal/BookingTimeOutModal';



const BASE_URL = process.env.REACT_APP_NODE_API_baseURL;

const TIMEOUT_DURATION = 30 * 1 * 1000; // 30 minutes in milliseconds 30 * 60 * 1000

const FlightSearchsv4 = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Start the timer when the component mounts
        const timeout = setTimeout(() => {
            setShowPopup(true);
        }, TIMEOUT_DURATION);

        // Clear the timer when the component unmounts or when showPopup becomes true
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleBackHome = () => {
        // Logic to handle going back home
    };

    const { toValue, fromValue, journeyDate, returnDate, selectedStopsCounts, orDiInfo, passengersTotal, selectedAvailableFlightsFilter, selectedWeights, setSelectedAvailableFlightsFilter,
        selectedDepartureTime, setSelectedDepartureTime,
        selectedArrivalTime, setSelectedArrivalTime,
        minPrice, setMinPrice,
        maxPrice, setMaxPrice, setTargetTimestamp, remainingTime


    } = useValue({});
    const arrivalLocation = toValue?.name.split("(")[1].slice(0, 3);
    const departureLocation = fromValue?.name.split("(")[1].slice(0, 3);
    // const startDate = travelDate?.replaceAll("/", "-"); selectedArrivalTime={selectedArrivalTime} setSelectedArrivalTime={setSelectedArrivalTime} selectedArrivalTime, setSelectedArrivalTime
    const theJourneyDate = new Date(journeyDate.getTime() - (journeyDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    const theReturnDate = new Date(returnDate.getTime() - (returnDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
    const OriginDestinationInformations = JSON.stringify(orDiInfo)
    // console.log(OriginDestinationInformations);

    const passengersTotalData = JSON.stringify(passengersTotal)

    // console.log(orDiInfo);
    const { data, loading } = useFetch(`api/v1/flightSearch?OriginDestinationInformations=${OriginDestinationInformations}&passengersTotalData=${passengersTotalData}`);

    console.log(data);
    useEffect(() => {
        setSelectedAvailableFlightsFilter([])
    }, [data, setSelectedAvailableFlightsFilter])


    const targetTimestampKey = 'targetTimestamp';
    const fetchData = () => {

        const timestamp = Date.now() + 30 * 60 * 1000; // Assuming the API response includes a 'timestamp' field
        // setTargetTimestamp(timestamp);
        localStorage.removeItem(targetTimestampKey)
        localStorage.setItem(targetTimestampKey, timestamp.toString());

        const storedTimestamp = localStorage.getItem(targetTimestampKey);
        setTargetTimestamp(storedTimestamp)

    };

    useEffect(() => {
        fetchData();
    }, [data])

    // ===============EDITS
    const itinerariesDatas = data?.itineraryGroups;

    // console.log(data)
    const [filteredArrays, setFilteredArrrays] = useState(itinerariesDatas);


    useEffect(() => {

        const filteredArray = selectedAvailableFlightsFilter.length > 0
            ? itinerariesDatas?.filter(obj => obj.legs.some(leg => leg.schedules.schedules.some(schedule => selectedAvailableFlightsFilter.includes(schedule.carrier.marketing))))
            : itinerariesDatas;

        // setFilteredArrrays(filteredArray);
        const filteredstopsCounts = filteredArray && filteredArray.filter(obj => {
            const stops = obj.legs.map(leg => leg.schedules.schedules.length);
            if (selectedStopsCounts.length === 0) {
                return true; // if stopsCounts is empty, return all objects
            }
            return selectedStopsCounts.some(count => stops.includes(count));
        });

        const filteredWeights = filteredstopsCounts && filteredstopsCounts.filter((itinerary) => {
            const baggageWeight = itinerary.pricingInformation?.[0]?.fare?.passengerInfoList?.[0]?.passengerInfo?.baggageInformation?.[0]?.allowance?.baggageAllowance?.weight;
            if (selectedWeights.length === 0) {
                return true; // Return all itineraries if selectedWeights is empty
            }
            return selectedWeights.includes(baggageWeight);
        });
        const filteredByPriceRangeData = filteredWeights && filteredWeights.filter((fareData) => {
            return fareData.pricingInformation.some((pricingInfo) => {
                const totalPrice = pricingInfo.fare.totalFare.totalPrice;
                return totalPrice >= minPrice && totalPrice <= maxPrice;
            });
        });

        const filteredDepartureTimeData = selectedDepartureTime.length === 0
            ? filteredByPriceRangeData
            : filteredByPriceRangeData.filter((itineraryGroup) => {
                return itineraryGroup.legs.every((leg) => {
                    return leg.schedules.schedules.some((schedule) => {
                        const departureTime = schedule.departure?.time;

                        if (departureTime) {
                            const [hour, minute] = departureTime.split(':');
                            const currentHour = parseInt(hour);

                            return selectedDepartureTime.some((selectedTime) => {
                                const [startHour, endHour] = selectedTime.split('-');
                                return currentHour >= parseInt(startHour) && currentHour <= parseInt(endHour);
                            });
                        }

                        return false;
                    });
                });
            });
        const filteredArrivalTimeData = selectedArrivalTime.length === 0
            ? filteredDepartureTimeData
            : filteredDepartureTimeData.filter((itineraryGroup) => {
                return itineraryGroup.legs.every((leg) => {
                    return leg.schedules.schedules.some((schedule) => {
                        const arrivalTime = schedule.arrival?.time;

                        if (arrivalTime) {
                            const [hour, minute] = arrivalTime.split(':');
                            const currentHour = parseInt(hour);

                            return selectedArrivalTime.some((selectedTime) => {
                                const [startHour, endHour] = selectedTime.split('-');
                                return currentHour >= parseInt(startHour) && currentHour <= parseInt(endHour);
                            });
                        }

                        return false;
                    });
                });
            });

        setFilteredArrrays(filteredArrivalTimeData)
    }, [itinerariesDatas, selectedAvailableFlightsFilter, selectedStopsCounts, selectedWeights, maxPrice, minPrice, selectedDepartureTime, selectedArrivalTime])




    // Create Pagination MUI Start
    const itemsPerPage = 10; // Number of items to display per page
    const totalItems = filteredArrays && filteredArrays.length; // Total number of items
    const totalPages = filteredArrays && Math.ceil(totalItems / itemsPerPage); // Total number of pages

    const [currentPage, setCurrentPage] = useState(1); // Current page state

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = filteredArrays && filteredArrays.slice(startIndex, endIndex);

    // Create Pagination MUI End

    return (
        <Box>

            {/*   <Navigation />  selectedArrivalTime, setSelectedArrivalTime */ }
            <Navbar />

            {/* { showPopup && (
                <BookingTimeOutModal />
            ) } */}
            { remainingTime !== null && remainingTime <= 0 && (
                <BookingTimeOutModal />
            ) }

            {/* <ModifySearch /> */ }
            <Container sx={ {
                py: 7
                // py: 3
            } }>

                <Grid container spacing={ 2 } >
                    <Grid item xs={ 12 } sm={ 12 } md={ 3 } lg={ 3 }>
                        <SearchSidebar data={ data } />
                    </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 9 } lg={ 9 }>
                        <AvailableFlights
                            itinerariesDatas={ itinerariesDatas }
                            filteredArrays={ filteredArrays } />
                        { loading ?
                            // <Skeleton variant="rectangular" width={ 210 } height={ 118 } /> 
                            <Stack sx={ { width: '100%', color: 'grey.500' } } spacing={ 2 }>
                                <LinearProgress color="secondary" />
                                <LinearProgress color="success" />
                                <LinearProgress color="inherit" />
                            </Stack>
                            : displayedItems?.map((itinerariesData, index) => (
                                <FlightCardsv4 key={ index } itinerariesData={ itinerariesData } data={ data } itinerariesDatas={ itinerariesDatas } index={ index } />
                                // <h5>{ itinerariesData.id }</h5>
                            ))
                            // : 
                            // <Box><h1> NO FLIGHT FOUND! </h1>
                            //     <Link style={ { background: 'black', color: 'white' } } to={ `/` }>SEARCH AGAIN!</Link>
                            // </Box> 

                        }
                        {/* { loading ? 'LOADING' :
                            data?.data?.map((sFlight) => (
                                <FlightCards sFlight={ sFlight } />
                            ))
                        } */}
                        {/* // Render pagination buttons */ }
                        <div style={ { textAlign: 'center', margin: '0 auto' } }>
                            {/* Basic Pagination  */ }
                            {/* { pageNumbers.map((pageNumber) => (
                                <button key={ pageNumber } onClick={ () => handlePageChange(pageNumber) }>
                                    { pageNumber }
                                </button>
                            )) } */}
                            {/* MUI Pagination  */ }
                            { filteredArrays &&
                                <Pagination
                                    count={ totalPages }
                                    page={ currentPage }
                                    onChange={ handlePageChange }
                                /> }
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};


export default FlightSearchsv4;