import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FilledInput,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

// countries
import Autocomplete from "@mui/material/Autocomplete";

// MuiPhoneNumber
import MuiPhoneNumber from "material-ui-phone-number";
import useValue from "../../../../hooks/useValue";

const img_hosting_token = process.env.REACT_APP_APP_ID;

const TravellerFormV3 = ({
  travellerIndex,
  input,
  handleFormChange,
  setPassengerPhoneNumber,
  passengerPhoneNumber,
  passengerPassportImage,
  setPassengerPassportImage,
  passengerCountry,
  setPassengerCountry,
  passengerDetailsForm,
  handleVisaImageChange,
  handlePassportImageChange,
  savedPassengerForSelect,
  setSavedPassengerForSelect,
  handlePassengerSelection,
  filteredPassengers,
}) => {
  const { isInternationalFlight } = useValue();
  // calculate the minimum date 6 months from today's date
  const today = new Date().toISOString().substr(0, 10);
  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() + 6);
  const minDateString = minDate.toISOString().substr(0, 10);

  return (
    <Box sx={ { minWidth: 120 } }>
      <select
        style={ { width: "100%", fontSize: "17px" } }
        onChange={ (event) => handlePassengerSelection(event, travellerIndex) }
      >
        <option value="">
          { input?.selectedPassenger
            ? `${input?.firstname} ${input?.lastname} `
            : "Select Passenger" }
        </option>
        { input?.selectedPassenger && (
          <option value="">{ "Add new Passenger Passenger" }</option>
        ) }
        { filteredPassengers.map((passenger) => (
          <option key={ passenger._id } value={ passenger._id }>
            { passenger.firstname } { passenger.lastname } (
            { passenger.PassengerType })
          </option>
        )) }
      </select>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sx={ { mt: 2 } }>
          <h4>Personal Details </h4>

          <p>@ As mentioned on your passport or government approved IDs</p>
        </Grid>
        <Grid item xs={ 12 } sx={ { mt: 2 } }>
          <div style={ {} }>
            {/* sx={ { color: input.title !== '' ? '' : 'red' } } */ }
            <FormLabel id="select-title">Select Title</FormLabel>
            {
              // input.title !== '' ? '' : '(It is required!)'
            }
            { input.PassengerType === "ADT" ? (
              <RadioGroup
                row
                sx={ {} }
                name="title"
                aria-labelledby="select-title"
                // onChange={ event => handleFormChange(travellerIndex, event) }
                onChange={ (event) =>
                  handleFormChange(travellerIndex, "title", event.target.value)
                }
                value={ input?.selectedPassenger?.title || input?.title || "" }
              >
                <FormControlLabel
                  value="MR"
                  control={ <Radio required={ true } /> }
                  label="MR."
                  disabled={ input?.selectedPassenger ? true : false }
                />
                <FormControlLabel
                  value="MRS"
                  control={ <Radio required={ true } /> }
                  label="MRS."
                  disabled={ input?.selectedPassenger ? true : false }
                />
                <FormControlLabel
                  value="MS"
                  control={ <Radio required={ true } /> }
                  label="MS."
                  disabled={ input?.selectedPassenger ? true : false }
                />
              </RadioGroup>
            ) : (
              <RadioGroup
                row
                sx={ {} }
                name="title"
                aria-required
                aria-labelledby="select-title"
                // onChange={ event => handleFormChange(travellerIndex, event) }
                onChange={ (event) =>
                  handleFormChange(travellerIndex, "title", event.target.value)
                }
                value={ input?.selectedPassenger?.title || input?.title || "" }
              >
                <FormControlLabel
                  value="MSTR"
                  control={ <Radio required={ true } /> }
                  label="MASTER."
                  disabled={ input?.selectedPassenger ? true : false }
                />
                <FormControlLabel
                  value="MISS"
                  control={ <Radio required={ true } /> }
                  label="MISS."
                  disabled={ input?.selectedPassenger ? true : false }
                />
              </RadioGroup>
            ) }
          </div>
        </Grid>
        <Grid item xs={ 6 }>
          <TextField
            required
            id="firstname"
            label="First Name"
            variant="outlined"
            sx={ { width: "100%" } }
            name="firstname"
            value={
              input?.selectedPassenger?.firstname || input?.firstname || ""
            }
            onChange={ (event) =>
              handleFormChange(travellerIndex, "firstname", event.target.value)
            }
            disabled={ input?.selectedPassenger ? true : false }
          />{ " " }
          <br></br>
          <br></br>
        </Grid>

        <Grid item xs={ 6 }>
          <TextField
            required
            id="lastname"
            label="Last Name"
            variant="outlined"
            sx={ { width: "100%" } }
            name="lastname"
            value={ input?.selectedPassenger?.lastname || input?.lastname || "" }
            onChange={ (event) =>
              handleFormChange(travellerIndex, "lastname", event.target.value)
            }
            disabled={ input?.selectedPassenger ? true : false }
          />
          <br></br>
          <br></br>
        </Grid>
        {/* { isInternationalFlight && */ }
        {/* <> */ }
        <Grid item xs={ 6 }>
          <TextField
            required
            id="date"
            label="Date of Birth"
            type="date"
            sx={ { width: "100%" } }
            InputLabelProps={ {
              shrink: true,
            } }
            name="dateOfBirth"
            value={
              input?.selectedPassenger?.dateOfBirth || input.dateOfBirth || ""
            }
            onChange={ (event) =>
              handleFormChange(
                travellerIndex,
                "dateOfBirth",
                event.target.value
              )
            }
            disabled={ input?.selectedPassenger ? true : false }
            inputProps={ {
              min: undefined,
              max: today, // You can set a max date if needed
            } }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <Autocomplete
            isOptionEqualToValue={ (option, value) =>
              option.value === value.value
            }
            id="country-select-demo"
            sx={ { width: "100%" } }
            options={ countries }
            // defaultValue={ input?.Country
            //     || {
            //     code: 'BD',
            //     label: 'Bangladesh',
            //     phone: '880'
            // } }
            value={
              input?.Country || {
                code: "BD",
                label: "Bangladesh",
                phone: "880",
              }
            }
            disabled={ input?.selectedPassenger ? true : false }
            autoHighlight
            getOptionLabel={ (option) => option.label }
            renderOption={ (props, option) => (
              <Box
                component="li"
                sx={ { "& > img": { mr: 2, flexShrink: 0 } } }
                { ...props }
              >
                <img
                  loading="lazy"
                  width="20"
                  src={ `https://flagcdn.com/w20/${option.code.toLowerCase()}.png` }
                  srcSet={ `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x` }
                  alt=""
                />
                { option.label } ({ option.code }) +{ option.phone }
              </Box>
            ) }
            renderInput={ (params) => (
              <TextField
                { ...params }
                label="Nationality"
                inputProps={ {
                  ...params.inputProps,

                  autoComplete: "new-password",
                } }
              />
            ) }
            // onChange={ (e, newValue) => newValue && setPassengerCountry(newValue) }
            onChange={ (event, newValue) =>
              handleFormChange(travellerIndex, "Country", newValue)
            }
          />
        </Grid>
        {/* </> } */ }
        <Grid item xs={ 12 } sx={ { mt: 2 } }>
          <h4>Contact Details</h4>
          <p>@ Receive booking confirmation & updates</p>
        </Grid>
        <Grid item xs={ 6 }>
          <TextField
            required
            type="email"
            id="email"
            label="Email Address"
            autoComplete="email"
            variant="outlined"
            sx={ { width: "100%" } }
            name="email"
            value={ input?.selectedPassenger?.email || input.email || "" }
            // onChange={ event => handleFormChange(travellerIndex, event) }
            onChange={ (event) =>
              handleFormChange(travellerIndex, "email", event.target.value)
            }
            disabled={ input?.selectedPassenger ? true : false }
          />
        </Grid>
        <Grid item xs={ 6 }>
          <MuiPhoneNumber
            defaultCountry={
              input?.Country?.code
                ? `${input?.Country?.code.toLowerCase()}`
                : "bd"
            } //'bd'
            variant="outlined"
            sx={ { width: "100%" } }
            label="Phone Number"
            required={ true }
            // value={ passengerPhoneNumber.phone }
            // onChange={ value => this.setPassengerPhoneNumber({
            //     phone: value
            // }) }

            // name="phoneNumber"
            value={
              input?.selectedPassenger?.phoneNumber || input.phoneNumber || ""
            }
            // onChange={ event => handleFormChange(travellerIndex, event) }

            disabled={ input?.selectedPassenger ? true : false }
            // onChange={ value => setPassengerPhoneNumber(value) }
            onChange={ (value) =>
              handleFormChange(travellerIndex, "phoneNumber", value)
            }
          />
        </Grid>

        { isInternationalFlight && (
          <>
            <Grid item xs={ 12 } sx={ { mt: 2 } }>
              <h4>Passport Information</h4>
              <p>
                @ Passport should be valid for 6 months from the date of entry
              </p>
            </Grid>

            <Grid item xs={ 6 }>
              <TextField
                required
                id="passportnumber"
                label="Passport Number"
                autoComplete="passportnumber"
                variant="outlined"
                sx={ { width: "100%" } }
                name="passportnumber"
                value={
                  input?.selectedPassenger?.passportnumber ||
                  input.passportnumber ||
                  ""
                }
                // onChange={ event => handleFormChange(travellerIndex, event) }
                onChange={ (event) =>
                  handleFormChange(
                    travellerIndex,
                    "passportnumber",
                    event.target.value
                  )
                }
                disabled={ input?.selectedPassenger ? true : false }
              />
            </Grid>
            <Grid item xs={ 6 }>
              <TextField
                required
                id="date"
                label="Passport Expiry Date"
                type="date"
                sx={ { width: "100%" } }
                InputLabelProps={ {
                  shrink: true,
                } }
                // onBlur={ handleBlur }
                name="passengerPassportExpirtyDate"
                value={
                  input?.selectedPassenger?.passengerPassportExpirtyDate ||
                  input.passengerPassportExpirtyDate ||
                  ""
                }
                onChange={ (event) =>
                  handleFormChange(
                    travellerIndex,
                    "passengerPassportExpirtyDate",
                    event.target.value
                  )
                }
                disabled={ input?.selectedPassenger ? true : false }
                inputProps={ {
                  min: minDateString,
                  max: undefined, // You can set a max date if needed
                } }
              />
            </Grid>
            <Grid item xs={ 6 }>
              {/* <FilledInput onChange={ (file) => handlePassportImageChange(travellerIndex, file) }>
                                Passport Image e.target.files[0])
                            </FilledInput>   */}
              {/* <input onChange={ (e) => handlePassportImageChange(travellerIndex, e.target.files[0]) } type='file' /> */ }
              <input
                onChange={ (e) =>
                  handleFormChange(
                    travellerIndex,
                    "passengerPassportImage",
                    e.target.files[0]
                  )
                }
                type="file"
              />
            </Grid>
            <Grid item xs={ 6 }>
              <input
                onChange={ (e) =>
                  handleFormChange(
                    travellerIndex,
                    "passengerVisaImage",
                    e.target.files[0]
                  )
                }
                type="file"
              />
            </Grid>
          </>
        ) }
      </Grid>

      <FormLabel sx={ { mt: 3 } } id="select-title">
        Save Passenger
      </FormLabel>

      <RadioGroup
        row
        sx={ {} }
        name="SavePassenger"
        aria-required
        aria-labelledby="select-title"
        // onChange={ event => handleFormChange(travellerIndex, event) }
        onChange={ (event) =>
          handleFormChange(travellerIndex, "SavePassenger", event.target.value)
        }
        value={
          input?.selectedPassenger?.SavePassenger || input?.SavePassenger || ""
        }
      >
        <FormControlLabel
          value={ true }
          control={ <Radio required={ true } /> }
          label="Yes."
          disabled={ input?.selectedPassenger ? true : false }
        />
        <FormControlLabel
          value={ false }
          control={ <Radio required={ true } /> }
          label="No"
          disabled={ input?.selectedPassenger ? true : false }
        />
      </RadioGroup>
    </Box>
  );
};

export default TravellerFormV3;

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const SavedPassengerForSelect = [
  {
    id: 1,
    NameNumber: "1.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MR",
    firstname: "Syed Hasib",
    lastname: "Rahman",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1994-04-19",
    passportnumber: "HASIB123",
    passengerPassportExpirtyDate: "2023-11-24",
  },
  {
    id: 2,
    NameNumber: "2.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MRS",
    firstname: "Syeda Asma",
    lastname: "Khatun",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1993-11-11",
    passportnumber: "Asma",
    passengerPassportExpirtyDate: "2023-11-27",
  },
  {
    id: 3,
    NameNumber: "3.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MR",
    firstname: "Syed Nazmus",
    lastname: "Sakib",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1997-03-07",
    passportnumber: "Sakib",
    passengerPassportExpirtyDate: "2023-11-30",
  },
  {
    id: 4,
    NameNumber: "4.1",
    Infant: false,
    PassengerType: "C03",
    NameReference: "C03",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MSTR",
    firstname: "Syed Fahim",
    lastname: "Hossain",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "2020-03-12",
    passportnumber: "Fahim123",
    passengerPassportExpirtyDate: "2023-11-23",
  },
  {
    id: 5,
    NameNumber: "1.1",
    Infant: true,
    PassengerType: "INF",
    NameReference: "I01",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MISS",
    firstname: "Syeda",
    lastname: "Asma",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1993-04-19",
    passportnumber: "ASMA123",
    passengerPassportExpirtyDate: "2023-11-24",
  },
];
