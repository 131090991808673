import axios from 'axios';
import Cookies from 'js-cookie';

// const BASE_URL = process.env.REACT_APP_baseURL;
const BASE_URL = process.env.REACT_APP_NODE_API_baseURL;

export default axios.create({
    baseURL: BASE_URL
});

const accessToken = Cookies.get('accessToken');
// Set headers with access token
/* const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }
} */
export const axiosPrivate = axios.create({

    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    },
    withCredentials: true
});  