import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import AuthProvider from './context/AuthProvider';
import { ValueProvider } from './context/ValueProvider';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

// Create a theme
// const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={ theme }> */}
    <BrowserRouter>
      <CssBaseline />
      <ValueProvider>
        <AuthProvider>
          <App />
        </AuthProvider>

      </ValueProvider>
    </BrowserRouter>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
