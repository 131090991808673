import { Alert, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import styles from "./UserLogin.module.css";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../../components/Loading/Loading";

import CancelIcon from '@mui/icons-material/Cancel';

const LoginPopupComponent = ({ handleClose }) => {
  const {
    register,
    formState: { errors },
  } = useForm();

  // const [open, setOpen] = useState(false);
  const [loginOrRegister, setLoginOrRegister] = useState("login");
  const [loginData, setLoginData] = useState({});
  const { user, loginUser, signInWithGoogle, isLoading } = useAuth();

  let signInError;
  const location = useLocation();
  const navigate = useNavigate();
  // let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user?.email) {
      navigate({ replace: true });
    }
  }, [user, navigate]);

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginRegisterData = { ...loginData };
    newLoginRegisterData[field] = value;
    // console.log(newLoginRegisterData);
    setLoginData(newLoginRegisterData);
  };

  const handleLoginSubmit = (e) => {
    loginUser(loginData.email, loginData.password, location, navigate);
    e.preventDefault();
  };

  const handleGoogleSignIn = () => {
    signInWithGoogle(location, navigate);
  };

  // const handleClose = () => setOpen(!open);
  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div style={ {} } >
      <div style={ { textAlign: 'right' } }>
        <CancelIcon onClick={ handleClose } style={ { fontSize: '45px', marginTop: '-10px', color: 'red', cursor: 'pointer' } } />
      </div>
      <Grid
        container
        spacing={ 0 }
        sx={ { my: 0, display: "flex", justifyContent: "center" } }
      >


        <Grid item sx={ { maxWidth: "320px" } }>
          <Grid container spacing={ 2 } sx={ { my: 2 } }>
            <Grid item xs={ 6 }>
              <Button
                fullWidth
                onClick={ (e) => setLoginOrRegister("login") }
                variant={ loginOrRegister === "login" ? "contained" : "outlined" }
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={ 6 }>
              <Button
                fullWidth
                onClick={ (e) => setLoginOrRegister("register") }
                variant={
                  loginOrRegister === "register" ? "contained" : "outlined"
                }
              >
                Registration
              </Button>
            </Grid>
          </Grid>
          { loginOrRegister === "login" && (
            <div>
              <input
                { ...register("email", {
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                    message: "Provide a valid Email",
                  },
                }) }
                type="email"
                onChange={ handleOnChange }
                className={ `${styles.field}` }
                placeholder="Your Email "
              />
              <Typography>
                { errors.email?.type === "required" && (
                  <Alert severity="error">{ errors.email.message }</Alert>
                ) }
                { errors.email?.type === "pattern" && (
                  <Alert severity="error">{ errors.email.message }</Alert>
                ) }
              </Typography>
              <p>Never share your password.</p>
              <input
                { ...register("password", {
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 6,
                    message: "Must be 6 characters or longer",
                  },
                }) }
                type="password"
                onChange={ handleOnChange }
                className={ `${styles.field}` }
                placeholder="Password"
              />

              <Typography>
                { errors.password?.type === "required" && (
                  <Alert severity="error">{ errors.password.message }</Alert>
                ) }
                { errors.password?.type === "minLength" && (
                  <Alert severity="error">{ errors.password.message }</Alert>
                ) }
              </Typography>
              { signInError }
              <br />
              <Button
                fullWidth
                onClick={ handleLoginSubmit }
                value="Login"
                variant="contained"
                color="success"
              >
                LogIn
              </Button>
              <br />
              <br />
              <Button
                fullWidth
                variant="contained"
                onClick={ handleGoogleSignIn }
                startIcon={ <GoogleIcon /> }
              >
                Google SignIn
              </Button>
            </div>
          ) }
          { loginOrRegister === "register" && <Resister /> }
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPopupComponent;

export const Resister = () => {
  const {
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  let signInError;

  const [loginData, setLoginData] = useState({});
  const { registeredUser, } = useAuth();
  const handleRegisterSubmit = (e) => {
    if (loginData.password !== loginData.password2) {
      alert("Password did not match");
      return;
    }
    registeredUser(
      loginData.email,
      loginData.password,
      loginData.name,
      navigate
    );
    e.preventDefault();
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginRegisterData = { ...loginData };
    newLoginRegisterData[field] = value;
    // console.log(newLoginRegisterData);
    setLoginData(newLoginRegisterData);
  };

  return (
    <div>
      {/* onSubmit={handleSubmit(onSubmit)} */ }
      <div>
        <input
          { ...register("name", {
            required: {
              value: true,
              message: "Name is required",
            },
          }) }
          type="text"
          className={ `${styles.field}` }
          placeholder="Name"
          onChange={ handleOnChange }
        />

        <Typography sx={ { mb: "5px" } }>
          { errors.name?.type === "required" && (
            <Alert severity="error">{ errors.name.message }</Alert>
          ) }
        </Typography>

        {/* Email */ }
        <input
          { ...register("email", {
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
              message: "Provide a valid Email",
            },
          }) }
          type="email"
          className={ `${styles.field}` }
          placeholder="Your Email "
          onChange={ handleOnChange }
        />

        <Typography sx={ { mb: "5px" } }>
          { errors.email?.type === "required" && (
            <Alert severity="error">{ errors.email.message }</Alert>
          ) }
          { errors.email?.type === "pattern" && (
            <Alert severity="error">{ errors.email.message }</Alert>
          ) }
        </Typography>

        {/* password */ }
        <input
          { ...register("password", {
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 6,
              message: "Must be 6 characters or longer",
            },
          }) }
          type="password"
          className={ `${styles.field}` }
          placeholder="Your Password "
          onChange={ handleOnChange }
        />
        {/* retype password */ }

        <input
          { ...register("password2", {
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 6,
              message: "Must be 6 characters or longer",
            },
          }) }
          type="password"
          className={ `${styles.field}` }
          placeholder="Re-type Password "
          onChange={ handleOnChange }
        />

        <Typography sx={ { mb: "5px" } }>
          { errors.password?.type === "required" && (
            <Alert severity="error">{ errors.password.message }</Alert>
          ) }
          { errors.password?.type === "minLength" && (
            <Alert severity="error">{ errors.password.message }</Alert>
          ) }
        </Typography>
        { signInError }

        <Button
          fullWidth
          onClick={ handleRegisterSubmit }
          value="Login"
          variant="contained"
          color="success"
        >
          REGISTER
        </Button>
      </div>
    </div>
  );
};
