export const slidesData = [
  {
    id: "001",
    img: "https://images.unsplash.com/photo-1603535410790-5ad1a0ba6c6d?q=80&w=1399&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Shara Desert",
    cta: "Go Now",
  },
  {
    id: "002",
    img: "https://i.ibb.co/nL9NP19/banner.png",
    title: "Get all the Accessories you need",
    cta: "Go Now",
  },
  {
    id: "003",
    img: "https://images.unsplash.com/photo-1615412704911-55d589229864?q=80&w=1582&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Wide  range of colors",
    cta: "Go Now",
  },
];