
import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import useValue from '../../../../../../../../hooks/useValue';


const Schedules = ({ data }) => {

    const {
        selectedDepartureTime, setSelectedDepartureTime,
        selectedArrivalTime, setSelectedArrivalTime,
    } = useValue({});

    const departureArrivalTimes = ['00:00-05:59', '06:00-11:59', '12:00-17:59', '18:00-23:59']
    const handleDepartureTimeChange = (event) => {
        if (selectedDepartureTime.includes(event)) {
            setSelectedDepartureTime(selectedDepartureTime.filter(code => code !== event));
        } else {
            setSelectedDepartureTime([...selectedDepartureTime, event]);
        }
    };
    const handleArrivalTimeChange = (event) => {
        if (selectedArrivalTime.includes(event)) {
            setSelectedArrivalTime(selectedArrivalTime.filter(code => code !== event));
        } else {
            setSelectedArrivalTime([...selectedArrivalTime, event]);
        }
    };

    /*     const filteredData = data?.itineraryGroups && data?.itineraryGroups.filter((itineraryGroup) => {
            return itineraryGroup.legs.every((leg) => {
                return leg.schedules.schedules.some((schedule) => {
                    const departureTime = schedule.departure?.time;
    
                    if (departureTime) {
                        const [hour, minute] = departureTime.split(':');
                        const currentHour = parseInt(hour);
    
                        return selectedDepartureTime.some((selectedTime) => {
                            const [startHour, endHour] = selectedTime.split('-');
                            return currentHour >= parseInt(startHour) && currentHour <= parseInt(endHour);
                        });
                    }
    
                    return false;
                });
            });
        }); */

    return (
        <Paper sx={ {
            p: 2,
            mt: 1
        } }>

            <Grid>
                <Typography variant='body1' sx={ {
                    py: 1
                } }>
                    Departure Time
                </Typography>
                {
                    departureArrivalTimes.map((departureTime, index) => (
                        <Button key={ index } variant="outlined" onClick={ e => handleDepartureTimeChange(departureTime) } startIcon={ <LightModeIcon /> } sx={ {
                            width: '100%',
                            color: selectedDepartureTime.includes(departureTime) ? 'red' : '#333',
                            border: selectedDepartureTime.includes(departureTime) ? '1px solid green' : '1px solid #333',
                            margin: '3px 0px'

                        } }

                        >
                            { departureTime }
                        </Button>
                    ))
                }


                {/* <Button variant="outlined" onClick={ e => handleDepartureTimeChange('06:00-11:59') } startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    06:00 - 11:59
                </Button>
                <Button variant="outlined" onClick={ e => handleDepartureTimeChange('12:00-17:59') } startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    12:00 - 17:59
                </Button>
                <Button variant="outlined" onClick={ e => handleDepartureTimeChange('18:00-23:59') } startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    18:00 - 23:59
                </Button> */}
            </Grid>
            <Grid>
                <Typography variant='body1' sx={ {
                    py: 1
                } }>
                    Arrival Time
                </Typography>

                {
                    departureArrivalTimes.map((arrivalTime, index) => (
                        <Button key={ index } variant="outlined" onClick={ e => handleArrivalTimeChange(arrivalTime) } startIcon={ <LightModeIcon /> } sx={ {
                            width: '100%',
                            color: selectedArrivalTime.includes(arrivalTime) ? 'red' : '#333',
                            border: selectedArrivalTime.includes(arrivalTime) ? '1px solid green' : '1px solid #333',
                            margin: '3px 0px'

                        } }

                        >
                            { arrivalTime }
                        </Button>
                    ))
                }
                {/* <Button variant="outlined" startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    00:00 - 05:59
                </Button>
                <Button variant="outlined" startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    06:00 - 11:59
                </Button>
                <Button variant="outlined" startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    12:00 - 17:59
                </Button>
                <Button variant="outlined" startIcon={ <LightModeIcon /> } sx={ buttonStyle }>
                    18:00 - 23:59
                </Button> */}
            </Grid>
        </Paper>
    );
};

export default Schedules;
