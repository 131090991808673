import React from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Packages from '../../components/Packages/Packages';
import Footer from '../../shared/Footer/Footer';
const Services = () => {

    return (
        <div>
            {/* <Navigation /> */ }
            <Navbar />
            <Packages />
            <Footer />
        </div>
    );
};

export default Services;