import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import ReviewDetailsV2 from "./ReviewDetailsV2";
import TravellerFormV3 from "./TravellerFormV3";
import ReviewDetailsV3 from "./ReviewDetailsV3";
import axios, { axiosPrivate } from "../../../../api/axios";
import useValue from "../../../../hooks/useValue";
import useAuth from "../../../../hooks/useAuth";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TravellerDetails = ({ flightSegments }) => {
  const [expandedOne, setExpandedOne] = React.useState(true);
  const [expandedTwo, setExpandedTwo] = React.useState(true);
  const [expandedThree, setExpandedThree] = React.useState(true);
  const [expandedFour, setExpandedFour] = React.useState(true);
  const [expandedFive, setExpandedFive] = React.useState(true);
  const [expandedSix, setExpandedSix] = React.useState(true);
  const [expandedSeven, setExpandedSeven] = React.useState(true);
  const [expandedEight, setExpandedEight] = React.useState(true);
  const [expandedNine, setExpandedNine] = React.useState(true);
  const [expandedTen, setExpandedTen] = React.useState(true);
  const [expandedEleven, setExpandedEleven] = React.useState(true);
  const [expandedTwelve, setExpandedTwelve] = React.useState(true);
  const [expandedThirteen, setExpandedThirteen] = React.useState(true);

  // const [selectedGender, setSelectedGender] = React.useState(-1);
  const { user } = useAuth({});

  const { passengersTotal, passengerQuantittyForForm } = useValue({});
  const passengersTotalData = JSON.stringify(passengersTotal);

  // console.log(passengersTotal)

  const handleTravellerDetailsOne = () => {
    setExpandedOne(!expandedOne);
    // console.log('One')
  };
  const handleTravellerDetailsTwo = () => {
    setExpandedTwo(!expandedTwo);
    // console.log('Two')
  };
  const handleTravellerDetailsThree = () => {
    setExpandedThree(!expandedThree);
    // console.log('Three')
  };
  const handleTravellerDetailsFour = () => {
    setExpandedFour(!expandedFour);
    // console.log('Four')
  };

  const handleTravellerDetailsFive = () => {
    setExpandedFive(!expandedFive);
    // console.log('Four')
  };
  const handleTravellerDetailsSix = () => {
    setExpandedSix(!expandedSix);
    // console.log('Four')
  };
  const handleTravellerDetailsSeven = () => {
    setExpandedSeven(!expandedSeven);
    // console.log('Four')
  };
  const handleTravellerDetailsEight = () => {
    setExpandedEight(!expandedEight);
    // console.log('Four')
  };
  const handleTravellerDetailsNine = () => {
    setExpandedNine(!expandedNine);
    // console.log('Four')
  };
  const handleTravellerDetailsTen = () => {
    setExpandedTen(!expandedTen);
    // console.log('Four')
  };

  const handleTravellerDetailsEleven = () => {
    setExpandedEleven(!expandedEleven);
    // console.log('Four')
  };
  const handleTravellerDetailsTwelve = () => {
    setExpandedTwelve(!expandedTwelve);
    // console.log('Four')
  };
  const handleTravellerDetailsThirteen = () => {
    setExpandedThirteen(!expandedThirteen);
    // console.log('Four')
  };
  // Form Data
  const [passengerPhoneNumber, setPassengerPhoneNumber] = useState("");
  const [passengerPassportImage, setPassengerPassportImage] = useState("");

  const [passengerCountry, setPassengerCountry] = useState({
    code: "BD",
    label: "Bangladesh",
    phone: "880",
  });
  // passengerDetailsForm, setPassengerDetailsForm
  const newPassengerQuantittyForForm = passengerQuantittyForForm.map((el) => ({
    ...el,
    phoneNumber: "",
    passengerPassportImage: "",
    passengerVisaImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    dateOfBirth: "",
    passportnumber: "",
    passengerPassportExpirtyDate: "",
  }));
  const [showWarning, setShowWarning] = useState(false);
  const [passengerDetailsForm, setPassengerDetailsForm] = useState(
    newPassengerQuantittyForForm
  );
  const handleFormChange = (travellerIndex, field, value) => {
    let data = [...passengerDetailsForm];
    data[travellerIndex][field] = value;
    // data[index]['passengerPassportImage'] = passengerPassportImage;
    // data[index]['SavePassenger'] = true || event.target.value;
    // data[index]['Country'] = newValue || {
    //     code: 'BD',
    //     label: 'Bangladesh',
    //     phone: '880'
    // };
    // data[index][event.target.name] = event.target.value;
    setPassengerDetailsForm(data);
  };
  function handlePassportImageChange(index, file) {
    const newPassengers = [...passengerDetailsForm];
    newPassengers[index].passportImage = file;
    setPassengerDetailsForm(newPassengers);
  }

  function handleVisaImageChange(index, file) {
    const newPassengers = [...passengerDetailsForm];
    newPassengers[index].visaImage = file;
    setPassengerDetailsForm(newPassengers);
  }

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const travellerInfo = JSON.stringify(passengerDetailsForm);
    const flightSegment = JSON.stringify(flightSegments);
    // const response = await axios.get(`api/createPNR?passengersTotalData=${passengersTotalData}&travellerInfo=${travellerInfo}&flightSegment=${flightSegment}`);
    const response = await axiosPrivate.get(
      `api/v1/createPNR?passengersTotalData=${passengersTotalData}&travellerInfo=${travellerInfo}&flightSegment=${flightSegment}`
    );
    if (response.data) {
      // const response2 = [response?.data, { ...passengerDetailsForm }];
      // console.log('response2', response2);
      // console.log('response', response?.data);
    } else {
      // console.log('response', response);
      // console.log('responseData', response.data);
    }

    setLoading(false);

    // navigate("/");
  };

  // const [savedPassengerForSelect, setSavedPassengerForSelect] = useState(SavedPassengerForSelectLocal)
  const [savedPassengerForSelect, setSavedPassengerForSelect] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // const response = await axios.get(`/api/v1/utickets/usertraveller${user.email}`);
        const response = await axiosPrivate.get(
          `/api/v1/utickets/usertravellers?email=${user?.email}`
        );
        // console.log(response.data);
        setSavedPassengerForSelect(response.data);

        // window.localStorage.removeItem('selectedFlight');
      } catch (err) {
        // setError(err)
      }
      setLoading(false);
    };
    fetchData();
    // return () => console.log("first")
  }, [user?.email]);

  const [selectedPassengers, setSelectedPassengers] = useState([]); // State variable for selected passengers
  // console.log(savedPassengerForSelect, selectedPassengers)
  const handlePassengerSelection = (event, travellerIndex) => {
    const selectedPassengerId = event.target.value;
    // console.log(selectedPassengerId)
    const selectedPassenger = savedPassengerForSelect.find(
      (passenger) => passenger._id === selectedPassengerId
    );

    // Do something with the selected passenger object
    /* const updatedOtherPassengers = SavedPassengerForSelect.filter((passenger) => passenger.id !== parseInt(selectedPassengerId)); */

    // Update the selected passenger for the specific select option
    setSelectedPassengers((prevSelectedPassengers) => {
      const updatedSelectedPassengers = [...prevSelectedPassengers];
      updatedSelectedPassengers[travellerIndex] = selectedPassenger;
      return updatedSelectedPassengers;
    });

    // setSelectedPassenger(selectedPassenger)
    // setSavedPassengerForSelect(updatedOtherPassengers);
    // passengerDetailsForm[travellerIndex] = selectedPassenger;

    if (selectedPassenger) {
      // Update the selectedPassenger property for the specific form
      const updatedForm = [...passengerDetailsForm];
      updatedForm[travellerIndex].Country = selectedPassenger.Country;
      updatedForm[travellerIndex].dateOfBirth = selectedPassenger.dateOfBirth;
      updatedForm[travellerIndex].email = selectedPassenger.email;
      updatedForm[travellerIndex].firstname = selectedPassenger.firstname;
      updatedForm[travellerIndex].lastname = selectedPassenger.lastname;
      updatedForm[travellerIndex].passengerPassportExpirtyDate =
        selectedPassenger.passengerPassportExpirtyDate;
      updatedForm[travellerIndex].passportnumber =
        selectedPassenger.passportnumber;
      updatedForm[travellerIndex].phoneNumber = selectedPassenger.phoneNumber;
      updatedForm[travellerIndex].title = selectedPassenger.title;
      // updatedForm[travellerIndex].passengerPassportImage = selectedPassenger.passengerPassportImage;
      // updatedForm[travellerIndex].passengerVisaImage = selectedPassenger.passengerVisaImage;
      updatedForm[travellerIndex].SavePassenger = "false";
      updatedForm[travellerIndex].selectedPassenger = true;
      setPassengerDetailsForm(updatedForm);
    } else {
      const updatedForm = [...passengerDetailsForm];
      updatedForm[travellerIndex] =
        newPassengerQuantittyForForm[travellerIndex];
      updatedForm[travellerIndex].selectedPassenger = false;
      setPassengerDetailsForm(updatedForm);
    }
  };
  // console.log('newPassengerQuantittyForForm', newPassengerQuantittyForForm)
  const filteredPassengers = savedPassengerForSelect.filter((passenger) => {
    return !selectedPassengers.some(
      (selectedPassenger) => selectedPassenger?._id === passenger?._id
    );
  });

  return loading ? (
    <Box sx={ { mt: 5 } }></Box>
  ) : (
    <Box sx={ { mt: 5 } }>
      <Typography
        variant="h6"
        color="text.secondary"
        fontWeight={ 900 }
        fontSize={ 15 }
      >
        Enter Traveller Details
      </Typography>
      {/* onSubmit={ handleSubmit } */ }

      <form onSubmit={ submit }>
        { passengerDetailsForm.map((input, travellerIndex) => {
          return (
            <Card sx={ { my: 2 } } key={ travellerIndex }>
              <CardActions
                disableSpacing
                sx={ { borderBottom: "2px solid #333" } }
                // expand={ expandedTwo }

                onClick={
                  travellerIndex === 0
                    ? handleTravellerDetailsOne
                    : travellerIndex === 1
                      ? handleTravellerDetailsTwo
                      : travellerIndex === 2
                        ? handleTravellerDetailsThree
                        : travellerIndex === 3
                          ? handleTravellerDetailsFour
                          : travellerIndex === 4
                            ? handleTravellerDetailsFive
                            : travellerIndex === 5
                              ? handleTravellerDetailsSix
                              : travellerIndex === 6
                                ? handleTravellerDetailsSeven
                                : travellerIndex === 7
                                  ? handleTravellerDetailsEight
                                  : travellerIndex === 8
                                    ? handleTravellerDetailsNine
                                    : travellerIndex === 9
                                      ? handleTravellerDetailsTen
                                      : travellerIndex === 10
                                        ? handleTravellerDetailsEleven
                                        : travellerIndex === 11
                                          ? handleTravellerDetailsTwelve
                                          : travellerIndex === 12
                                            ? handleTravellerDetailsThirteen
                                            : handleTravellerDetailsTen
                }
                aria-expanded={ expandedTwo }
                aria-label="show more"
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={ 700 }
                  fontSize={ 15 }
                >
                  {/* Passenger { index + 1 } Adult Primary Contact */ }
                  { travellerIndex === 0
                    ? `Passenger ${travellerIndex + 1} ${input.PassengerType
                    } Primary Contact`
                    : `Passenger ${travellerIndex + 1} ${input.PassengerType} ` }
                </Typography>
                <ExpandMore>
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>

              <Collapse
                in={
                  travellerIndex === 0
                    ? expandedOne
                    : travellerIndex === 1
                      ? expandedTwo
                      : travellerIndex === 2
                        ? expandedThree
                        : travellerIndex === 3
                          ? expandedFour
                          : travellerIndex === 4
                            ? expandedFive
                            : travellerIndex === 5
                              ? expandedSix
                              : travellerIndex === 6
                                ? expandedSeven
                                : travellerIndex === 7
                                  ? expandedEight
                                  : travellerIndex === 8
                                    ? expandedNine
                                    : travellerIndex === 9
                                      ? expandedTen
                                      : travellerIndex === 10
                                        ? expandedEleven
                                        : travellerIndex === 11
                                          ? expandedTwelve
                                          : expandedThirteen
                }
                timeout="auto"
                unmountOnExit
              >
                <CardContent>
                  <TravellerFormV3
                    travellerIndex={ travellerIndex }
                    input={ input }
                    handleFormChange={ handleFormChange }
                    setPassengerPhoneNumber={ setPassengerPhoneNumber }
                    passengerPassportImage={ passengerPassportImage }
                    setPassengerPassportImage={ setPassengerPassportImage }
                    passengerCountry={ passengerCountry }
                    setPassengerCountry={ setPassengerCountry }
                    passengerDetailsForm={ passengerDetailsForm }
                    handleVisaImageChange={ handleVisaImageChange }
                    handlePassportImageChange={ handlePassportImageChange }
                    savedPassengerForSelect={ savedPassengerForSelect }
                    filteredPassengers={ filteredPassengers }
                    setSavedPassengerForSelect={ setSavedPassengerForSelect }
                    handlePassengerSelection={ handlePassengerSelection }
                  />
                  {/* <TestFormData /> */ }
                </CardContent>
              </Collapse>
            </Card>
          );
        }) }

        {/*  <ReviewDetailsV2 passengerDetailsForm={ passengerDetailsForm } flightSegments={ flightSegments } /> */ }
        <ReviewDetailsV3
          passengerDetailsForm={ passengerDetailsForm }
          flightSegments={ flightSegments }
        />

        {/* <Button type='submit' variant="contained" color="success" fullWidth='true'>Continue</Button> */ }

        {/* <Button type='submit' variant="contained" color="success" fullWidth='true' onClick={ 'handleBooking' } >Continue</Button> */ }

        {/* <Button type='submit' variant="contained" color="success" fullWidth='true' onClick={ 'handleBooking' }><Link style={ { background: 'transparent', color: 'white', fontSize: '18px', fontWeight: 'bolder' } } to={ `/` }>Continue</Link></Button> */ }
      </form>
    </Box>
  );
};

export default TravellerDetails;

const SavedPassengerForSelectLocal = [
  {
    id: 1,
    NameNumber: "1.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: { code: "AF", label: "Afghanistan", phone: "93" },
    title: "MR",
    firstname: "Syed Hasib",
    lastname: "Rahman",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1994-04-19",
    passportnumber: "HASIB123",
    passengerPassportExpirtyDate: "2023-11-24",
  },
  {
    id: 2,
    NameNumber: "2.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MRS",
    firstname: "Syeda Asma",
    lastname: "Khatun",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1993-11-11",
    passportnumber: "Asma",
    passengerPassportExpirtyDate: "2023-11-27",
  },
  {
    id: 3,
    NameNumber: "3.1",
    Infant: false,
    PassengerType: "ADT",
    NameReference: "",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MR",
    firstname: "Syed Nazmus",
    lastname: "Sakib",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1997-03-07",
    passportnumber: "Sakib",
    passengerPassportExpirtyDate: "2023-11-30",
  },
  {
    id: 4,
    NameNumber: "4.1",
    Infant: false,
    PassengerType: "C03",
    NameReference: "C03",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MSTR",
    firstname: "Syed Fahim",
    lastname: "Hossain",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "2020-03-12",
    passportnumber: "Fahim123",
    passengerPassportExpirtyDate: "2023-11-23",
  },
  {
    id: 5,
    NameNumber: "1.1",
    Infant: true,
    PassengerType: "INF",
    NameReference: "I01",
    phoneNumber: "+8801521312761",
    passengerPassportImage: "",
    SavePassenger: true,
    Country: {
      code: "BD",
      label: "Bangladesh",
      phone: "880",
    },
    title: "MISS",
    firstname: "Syeda",
    lastname: "Jannatul",
    email: "syedhasib01@gmail.com",
    dateOfBirth: "1993-04-19",
    passportnumber: "ASMA123",
    passengerPassportExpirtyDate: "2023-11-24",
  },
];
