import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import useValue from '../../hooks/useValue';

const CountdownTimerV3 = ({ apiCall }) => {
    // const [targetTimestamp, setTargetTimestamp] = useState(null);
    const { targetTimestamp, setTargetTimestamp, remainingTime, setRemainingTime } = useValue({})

    const targetTimestampKey = 'targetTimestamp';

    // useEffect(() => {
    /* const fetchData = () => {

        const timestamp = Date.now() + 1 * 60 * 1000; // Assuming the API response includes a 'timestamp' field
        // setTargetTimestamp(timestamp);
        localStorage.removeItem(targetTimestampKey)
        localStorage.setItem(targetTimestampKey, timestamp.toString());

        const storedTimestamp = localStorage.getItem(targetTimestampKey);
        setTargetTimestamp(storedTimestamp)

    }; */

    // fetchData();
    // }, [apiCall]);

    // console.log('targetTimestamp', targetTimestamp)
    // console.log('remainingTime', remainingTime)
    useEffect(() => {
        const storedTimestamp = localStorage.getItem(targetTimestampKey);
        setTargetTimestamp(storedTimestamp)
        let interval = null;
        if (targetTimestamp) {
            const calculateRemainingTime = () => {
                const currentTime = Date.now();
                // const elapsedTime = currentTime - targetTimestamp;
                // const remainingTime = Math.max(1 * 60 * 1000 - elapsedTime, 0); // 30 minutes in milliseconds
                const remainingTime = Math.max(targetTimestamp - currentTime, 0); // 30 minutes in milliseconds

                setRemainingTime(remainingTime);

                if (remainingTime === 0) {
                    clearInterval(interval);
                }
            };

            calculateRemainingTime();
            interval = setInterval(calculateRemainingTime, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [targetTimestamp, setRemainingTime, setTargetTimestamp]);

    const minutes = Math.floor(remainingTime / (1000 * 60));
    const seconds = Math.floor((remainingTime / 1000) % 60);


    //    <div>
    //         {/* <button onClick={ fetchData }>RESET TIME</button> */ }
    //         { remainingTime !== null && remainingTime > 0 ? (
    //             <span>{ `${minutes}:${seconds < 10 ? '0' : ''}${seconds}` }</span>
    //         ) : (
    //             <span>Time Out</span>
    //         ) }
    //     </div> 


    return (


        remainingTime &&
        <Paper sx={ {
            textAlign: 'center',
            border: '2px solid silver',
            p: 1,
            mb: 1
        } }>
            <Typography variant='caption'>
                Book Now before it gets sold out.
            </Typography>
            <Paper>
                { remainingTime !== null && remainingTime > 0 ? (
                    <span style={ { fontSize: '30px', color: 'red', fontWeight: '900' } }>
                        { `${minutes}:${seconds < 10 ? '0' : ''}${seconds}` }
                    </span>
                ) : (
                    <span style={ { fontSize: '30px', color: 'red', fontWeight: '900' } }>Time Out</span>
                ) }
            </Paper>
        </Paper>

    );
};

export default CountdownTimerV3;
