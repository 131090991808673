// discountUtils.js
const cardDiscounts = [
    {
        cardType: 'Visa',
        discountPercentage: 5,
        aitPercentage: 0.3,
    },
    {
        cardType: 'MasterCard',
        discountPercentage: 15,
        aitPercentage: 0.25,
    },
    {
        cardType: 'American Express',
        discountPercentage: 12,
        aitPercentage: 0.28,
    },
    // Add more card types and their corresponding discounts
];

export function calculateTotalPrice(baseFare, taxFare, discountPercentage = 5, aitPercentage = 0.3) {
    const cardDiscount = cardDiscounts.find((card) => card.cardType === 'Visa');
    const discount = Math.floor(((baseFare * discountPercentage) / 100));
    const discountedBaseFare = (baseFare - discount);
    const discountedFare = parseInt(discountedBaseFare + taxFare);

    const aitPrice = Math.ceil(((baseFare + taxFare) * aitPercentage) / 100);
    const totalPayableFare = Math.ceil(discountedFare + aitPrice);

    return { totalPayableFare, aitPrice, discount, cardDiscount };
}
