import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import useValue from '../../../../../../hooks/useValue';

const SortAndFilter = ({ data }) => {
    const itinerariesDatas = data?.itineraryGroups;
    const { selectedStopsCounts, setSelectedStopsCounts, selectedWeights, setSelectedWeights } = useValue({});
    const [stopsCount, setStopsCounts] = useState([]);
    const [weightCount, setWightCounts] = useState([]);
    useEffect(() => {
        const stopsCounts = itinerariesDatas && itinerariesDatas.reduce((counts, data) => {
            const countedStops = [];
            // console.log(counts)
            data.legs.forEach(leg => {
                const numberOfStops = leg.schedules.schedules.length;
                if (!countedStops.includes(numberOfStops)) {
                    const index = counts.findIndex(count => count.numberOfStops === numberOfStops);
                    if (index === -1) {
                        counts.push({ numberOfStops: numberOfStops, count: 1 });
                    } else {
                        counts[index].count++;
                    }
                    countedStops.push(numberOfStops);
                }
            });
            return counts;
        }, []);

        setStopsCounts(stopsCounts)
    }, [itinerariesDatas])
    // console.log(stopsCount)

    const handleCheckboxChange = (event, stopsCountData) => {
        const selectedStops = [...selectedStopsCounts];
        if (event.target.checked) {
            selectedStops.push(stopsCountData.numberOfStops);
        } else {
            const index = selectedStops.indexOf(stopsCountData.numberOfStops);
            if (index > -1) {
                selectedStops.splice(index, 1);
            }
        }
        setSelectedStopsCounts(selectedStops);
    }
    // console.log(selectedStopsCounts)


    // =============  weightCount, setWightCounts
    useEffect(() => {

        const baggageCount = itinerariesDatas && itinerariesDatas.reduce((acc, itinerary) => {
            itinerary.pricingInformation.forEach((pricingInfo) => {
                pricingInfo.fare.passengerInfoList.forEach((passengerInfo) => {
                    passengerInfo.passengerInfo.baggageInformation.forEach((baggage) => {
                        const { weight, pieceCount } = baggage.allowance.baggageAllowance;
                        const itemIndex = acc.findIndex((item) => {
                            if (pieceCount) {
                                return item.weight === weight && item.pieceCount === pieceCount;
                            }
                            return item.weight === weight;
                        });
                        if (itemIndex === -1) {
                            const newItem = pieceCount
                                ? { pieceCount, count: 1 }
                                : { weight, count: 1 };
                            acc.push(newItem);
                        } else {
                            acc[itemIndex].count += 1;
                        }
                    });
                });
            });
            return acc;
        }, []);


        setWightCounts(baggageCount)
    }, [itinerariesDatas]);

    // const [selectedWeights, setSelectedWeights] = useState([]);

    const handleWeightChange = (e, weight) => {
        if (e.target.checked) {
            setSelectedWeights([...selectedWeights, weight]);
        } else {
            setSelectedWeights(selectedWeights.filter(selectedWeight => selectedWeight !== weight));
        }
    };
    // console.log(selectedWeights)
    return (
        <Paper sx={ {
            p: 2,
            mt: 2
        } }>




            <FormGroup>
                <Typography variant='body1' sx={ {
                    py: 2,
                } }>
                    Number of Stops
                </Typography>


                { stopsCount && stopsCount.map((stopsCountData, index) => (
                    <FormControlLabel
                        key={ index }
                        control={ <Checkbox checked={ selectedStopsCounts.includes(stopsCountData.numberOfStops) } onChange={ (event) => handleCheckboxChange(event, stopsCountData) } /> }
                        label={
                            stopsCountData.numberOfStops === 1 ? `Direct Flight (${stopsCountData.count})` :
                                stopsCountData.numberOfStops === 2 ? `One Stop Flight (${stopsCountData.count})` :
                                    stopsCountData.numberOfStops === 3 ? `Two Stops Flight (${stopsCountData.count})` : 'Something Went Wrong'
                        }
                    />
                )) }



                <Typography variant='body1' sx={ {
                    py: 2
                } }>
                    Weights
                </Typography>
                { weightCount && weightCount.map((weightData, index) => (
                    weightData.weight !== undefined &&
                    <FormControlLabel
                        key={ index }
                        // control={ <Checkbox onChange={ (e) => handleWeightChange(e, weightData.weight) } /> }
                        control={ <Checkbox checked={ selectedWeights.includes(weightData.weight) } onChange={ (e) => handleWeightChange(e, weightData.weight) } /> }
                        label={ `${weightData.weight} kg (${weightData.count})` }
                    />
                )) }
            </FormGroup>
        </Paper>
    );
};

export default SortAndFilter;