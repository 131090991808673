import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import useValue from "../../../hooks/useValue";

const FlightTypeOption = () => {
  const { flightType, setFlightType } = useValue({});
  // console.log(flightType)

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins", // Replace with your desired font family
    },
  });

  return (
    <ThemeProvider theme={ theme }>
      <Box
        sx={ {
          display: "flex",
          justifyContent: "space-between",
          marginTop: "50px",
          backgroundColor: "#28e5284f",
          border: "2px solid #FFF",
          boxShadow: "#FFF #FFF 0 3px",
        } }
      >
        <Box
          sx={ {
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
          } }
        >
          {/* Flight Way Selector  */ }

          <Box>
            <Typography
              sx={ {
                fontSize: "12px",
                color: flightType === "oneWay" ? "purple" : "#212121",
                cursor: "pointer",
              } }
              onClick={ (e) => setFlightType("oneWay") }
            >
              <PlaylistAddCheckCircleIcon />
              One Way
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={ {
                fontSize: "12px",
                color: flightType === "roundTrip" ? "purple" : "#212121",
                cursor: "pointer",
              } }
              onClick={ (e) => setFlightType("roundTrip") }
            >
              <PlaylistAddCheckCircleIcon />
              Round Trip
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={ {
                fontSize: "12px",
                color: flightType === "multiCity" ? "purple" : "#212121",
                cursor: "pointer",
              } }
              onClick={ (e) => setFlightType("multiCity") }
            >
              <PlaylistAddCheckCircleIcon />
              Multi City
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={ {
              color: "#212121",
              fontSize: { xs: 15, md: 12, lg: 12 },
              marginLeft: { xs: 2 },
              padding: "5px",
            } }
          >
            { " " }
            Book Flight Tickets
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FlightTypeOption;
