import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";
import styles from "./TopAirlines.module.css";
import { useState } from "react";
import { useEffect } from "react";

const airlinesData = [
  {
    name: "Biman Bangladesh",
    img: "https://i.ibb.co/bPYJSnf/download.png",
  },
  {
    img: "10pm",
    name: "US Bangla",
  },
  // Add more airline names as needed
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TopAirlines = () => {
  const [airlines, setAirlines] = useState([]);
  useEffect(() => {
    fetch("airlinedata.json")
      .then((res) => res.json())
      .then((data) => setAirlines(data));
  }, []);
  // console.log(airlines);

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins", // Replace with your desired font family
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box sx={{ flexGrow: 1, marginTop: "50px", marginBottom: "30px" }}>
          <Typography sx={{ textAlign: "center" }}>
            <h4 style={{ fontSize: "2rem", fontWeight: "bold" }}>
              Top Airlines
            </h4>

            <p style={{ fontSize: "15px", textAlign: "center" }}>
              Othooy user-friendly platform
              <br />
              connects you to top airlines
            </p>
          </Typography>
          <Grid container spacing={2}>
            {airlines.map((airline, index) => (
              <Grid key={index} item xs={12} sm={12} md={3} lg={3}>
                <Item className={styles.itemCard}>
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={airline.img}
                    alt=""
                  />
                  {airline.name}{" "}
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TopAirlines;
