import { Box, Divider, Grid, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { calculateTotalPrice } from '../../Flight/FlightSearch/FlightDiscount/FlightDiscount';
import CountdownTimerV3 from '../../../components/TimerComponents/CountdownTimerV3';
import useValue from '../../../hooks/useValue';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton { ...other } />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const PaymentFareSummaryAfterBooked = ({ bookedData }) => {
    const [expandedSummary, setExpandedSummary] = React.useState(false);
    const { baseFare, setBaseFare, taxFare, setTaxFare, subTotalPrice, setSubTotalPrice, setDiscountPercentage, aitPercentage, setAitPercentage, setCalculatedFares, discountPercentage } = useValue({})
    const handleExpandClickSummary = () => {
        setExpandedSummary(!expandedSummary);
    };
    // console.log(bookedData);

    useEffect(() => {
        setBaseFare(Number(bookedData?.getATickets?.payments.flightTotals[0].subtotal))
        setTaxFare(Number(bookedData?.getATickets?.payments.flightTotals[0].taxes))
        setSubTotalPrice(Number(bookedData?.getATickets?.payments.flightTotals[0].total))
        setDiscountPercentage(5)
        setAitPercentage(0.3)

    }, [bookedData?.getATickets?.payments.flightTotals, setAitPercentage, setBaseFare, setDiscountPercentage, setSubTotalPrice, setTaxFare, setCalculatedFares, baseFare, taxFare])

    const fareData = bookedData?.getATickets?.payments.flightTotals[0];
    const currencyCode = bookedData?.getATickets?.payments.flightTotals[0].currencyCode;
    /* 
     const baseFare = Number(bookedData?.getATickets?.payments.flightTotals[0].subtotal);
      const taxFare = Number(bookedData?.getATickets?.payments.flightTotals[0].taxes);
    const subtotal = Number(bookedData?.getATickets?.payments.flightTotals[0].total);
     const totalFares = calculateTotalPrice(baseFare, taxFare) */
    const totalFares = calculateTotalPrice(baseFare, taxFare, discountPercentage, aitPercentage)
    // console.log(calculatedFares)

    // console.log(typeof baseFare);

    const theFlight = JSON.parse(window.localStorage.getItem('selectedFlight'));;

    // console.log(theFlight);


    return (
        <Box sx={ { px: 1, position: 'sticky', top: 55, } }>
            <Paper variant="outlined" sx={ { px: 1, my: 2 } }>
                {/* <Clock /> */ }
                <CountdownTimerV3 />
            </Paper>
            <Paper variant="outlined" sx={ { px: 1 } }>
                <Card sx={ {} }>

                    <Grid container rowSpacing={ 0 } columnSpacing={ { xs: 1, sm: 2, md: 3 } } sx={ { px: 1, alignItems: 'center', fontSize: '1em' } } >
                        <Grid item xs={ 6 }>

                            <Avatar alt="Remy Sharp" src={ `https://pics.avs.io/250/80/${bookedData?.getATickets?.flights[0].airlineCode}@2x.png` } variant="square" sx={ { width: '70%', height: '70%' } } aria-label="recipe" />

                        </Grid>
                        <Grid item xs={ 6 }>
                            {
                                bookedData?.getATickets?.journeys.map((journy, index) => (

                                    <Box style={ { display: 'flex' } } key={ index }>
                                        <p>{ `${journy.firstAirportCode}-` }</p>
                                        <p>{ `${journy.lastAirportCode}` },</p>
                                    </Box>
                                ))
                            }

                        </Grid>
                    </Grid>
                    <Divider />
                    <CardActions disableSpacing
                        onClick={ handleExpandClickSummary }
                        aria-expanded={ expandedSummary }
                        aria-label="show more"
                    >

                        <p style={ {} }>
                            <b> Fare Summary</b>
                        </p>


                        <ExpandMore >
                            <ExpandMoreIcon />
                        </ExpandMore>


                    </CardActions>
                    <Collapse in={ expandedSummary } timeout="auto" unmountOnExit>
                        <CardContent>

                            {
                                theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                    pricingInformationData.fare.passengerInfoList.map((passengerInfoListData, index) => (

                                        `${passengerInfoListData.passengerInfo.passengerType} (${passengerInfoListData.passengerInfo.passengerNumber}), `
                                    ))
                                )) }

                            <Grid container rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 2, md: 3 } } sx={ { display: 'flex', alignItems: 'center', fontSize: '1em' } } >
                                <Grid item xs={ 6 } sx={ { textAlign: 'left' } }>
                                    <p>Base Fare</p>
                                    <p>Tax</p>
                                    {/* <Divider /> */ }
                                </Grid>
                                <Grid item xs={ 6 } sx={ { textAlign: 'right' } }>
                                    <p>
                                        {/* { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                            pricingInformationData.fare.totalFare.equivalentAmount.toLocaleString()
                                        )) } */}
                                        <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                        <span style={ { fontWeight: '700' } }> { Number(fareData?.subtotal).toLocaleString() }</span> /=
                                    </p>
                                    <p>
                                        {/* { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                            pricingInformationData.fare.totalFare.totalTaxAmount.toLocaleString()
                                        )) }  */}
                                        <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                        <span style={ { fontWeight: '700' } } > { Number(fareData?.taxes).toLocaleString() } </span> /=

                                    </p>
                                </Grid>

                            </Grid>

                        </CardContent>

                        <Divider />
                    </Collapse>

                    <CardContent>

                        <Grid container>
                            <Grid item xs={ 6 } sx={ { textAlign: 'left' } }>
                                <p>SubTotal:</p>
                                <p>Discount:</p>
                                <p>AIT:</p>
                                <p> <span style={ { fontWeight: '700' } } > Total Payable: </span></p>
                            </Grid>
                            <Grid item xs={ 6 } sx={ { textAlign: 'right' } }>
                                <p>
                                    {/*  { theFlight?.pricingInformation.map((pricingInformationData, index) => (
                                        pricingInformationData.fare.totalFare.totalPrice.toLocaleString()
                                    )) } */}
                                    <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                    <span style={ { fontWeight: '700' } } >  { subTotalPrice.toLocaleString() } </span> /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                    <span style={ { fontWeight: '700' } } >   - { totalFares?.discount.toLocaleString() }</span>  /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                    <span style={ { fontWeight: '700' } } >  { totalFares?.aitPrice.toLocaleString() }  </span> /=
                                </p>
                                <p>
                                    <span style={ { fontSize: '13px' } }>  { currencyCode }</span>
                                    <span style={ { fontWeight: '700' } } >  { totalFares?.totalPayableFare.toLocaleString() } </span> /=
                                </p>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
            </Paper>
        </Box>
    );
};

export default PaymentFareSummaryAfterBooked;