


import { Alert, Button, CircularProgress, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import styles from './UserLogin.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Navbar from '../../../shared/Navbar/Navbar';
import Footer from '../../../shared/Footer/Footer';
import useAuth from '../../../hooks/useAuth';
import Loading from '../../../components/Loading/Loading';
import Cookies from 'js-cookie';

const UserLogin = () => {
    const [loginData, setLoginData] = useState({});
    const { user, loginUser, signInWithGoogle, isLoading, authError, token } = useAuth();


    console.log('UserLogin-token:', token);

    const location = useLocation();
    const navigate = useNavigate();
    let from = location?.state?.from?.pathname || '/';
    useEffect(() => {
        if (user?.email) {
            navigate(from, { replace: true });
        }
    }, [user, from, navigate])




    const handleOnChange = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = { ...loginData };
        newLoginData[field] = value;
        // console.log(newLoginData);
        setLoginData(newLoginData);
    }
    const handleLoginSubmit = e => {
        loginUser(loginData.email, loginData.password, location, navigate);
        e.preventDefault();
        // Set access token in a cookie 

        Cookies.set('accessToken', token, { secure: true, sameSite: 'false' });
        // Set access token in a cookie
    }

    const handleGoogleSignIn = () => {
        signInWithGoogle(location, navigate)
        // Set access token in a cookie 

        Cookies.set('accessToken', token, { secure: true, sameSite: 'false' });
        // Set access token in a cookie
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            <Navbar />
            <Container className={ `${'my-5'} ${styles.body1}` }>
                <div className={ `${styles.loginContainer}` }>
                    <div className={ `${styles.loginBox}` }>
                        <div className={ `${styles.left1}` }></div>
                        <div className={ `${styles.right1}` }>
                            <h2>Login</h2>
                            <form onSubmit={ handleLoginSubmit }>
                                {/* <input type="email" className={`${styles.field}`} placeholder="Your Email " /> */ }
                                <input

                                    type="email" name="email" onChange={ handleOnChange } className={ `${styles.field}` } placeholder="Your Email " />
                                <Typography>
                                    {/* {errors.email?.type === 'required' && <Alert severity="error">{errors.email.message}</Alert>}
                                    {errors.email?.type === 'pattern' && <Alert severity="error">{errors.email.message}</Alert>} */}
                                </Typography>
                                {/* 
                                <Alert variant="outlined" severity="error">
                                This is an error alert — check it out!
                                            </Alert>
                                 */}
                                {/* password */ }
                                <p>Never share your password with anyone.</p>
                                <input

                                    type="password" name="password" onChange={ handleOnChange } className={ `${styles.field}` } placeholder="Password" />

                                <Typography>
                                    {/* {errors.password?.type === 'required' && <Alert severity="error">{errors.password.message}</Alert>}
                                    {errors.password?.type === 'minLength' && <Alert severity="error">{errors.password.message}</Alert>} */}
                                </Typography>

                                <br />
                                <Button type="submit" value='Login' variant="contained" color="success">LogIn</Button>
                                <br />
                                <br />
                                { isLoading && <CircularProgress /> }
                                { user?.email && <Alert severity="success">Login Successfully</Alert> }
                                { authError && <Alert severity="error">{ authError }</Alert> }
                            </form>
                            <Button
                                variant="contained" onClick={ handleGoogleSignIn } startIcon={ <GoogleIcon /> }>
                                Google SignIn
                            </Button>

                            <Link to="/account/signup"><Button variant="text" className={ `${styles.linkButton}` }>
                                New User? Register Here
                            </Button></Link>
                            <hr />
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default UserLogin;


