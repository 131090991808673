import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ createdAt }) => {
    function calculateRemainingTime(createdAt) {
        const currentTime = new Date().getTime();
        const expirationTime = new Date(createdAt).getTime() + 30 * 60 * 1000; // Add 20 minutes in milliseconds

        if (currentTime >= expirationTime) {
            // Time has expired
            return { minutes: 0, seconds: 0 };
        } else {
            const remainingTime = expirationTime - currentTime;
            const minutes = Math.floor(remainingTime / (1000 * 60));
            const seconds = Math.floor((remainingTime / 1000) % 60);

            return { minutes, seconds };
        }
    }

    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(createdAt));

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(calculateRemainingTime(createdAt));
        }, 1000);

        return () => clearInterval(interval);
    }, [createdAt]);

    return (
        <div>
            { remainingTime.minutes }:{ remainingTime.seconds < 10 ? '0' : '' }
            { remainingTime.seconds }
        </div>
    );
};

export default CountdownTimer;
