import React, { useState } from 'react';
import styles from './TravellerTest.module.css';
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import img from '../../../../../assets/images/logov2.png';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MuiPhoneNumber from 'material-ui-phone-number';
import useAuth from '../../../../../hooks/useAuth';
import Loading from '../../../../../components/Loading/Loading';


const TravellerTest = () => {
    const { user, isLoading } = useAuth();
    const [inputValue, setInputValue] = useState({});

    const currencies = [
        { value: 'Male' },
        { value: 'Female' },
        { value: 'Others' },
    ];

    const maritalStatus = [
        { value: 'Single' },
        { value: 'Married' },
        { value: 'Widowed' },
        { value: 'Divorced' },

    ];

    const religion = [
        { value: 'Islam' },
        { value: 'Christianity' },
        { value: 'Hinduism' },
        { value: 'Buddhism' },

    ];

    const languagePreference
        = [
            { value: 'Good' },
            { value: 'Average' },
            { value: 'Excelent' },
            { value: 'Outstanding' },

        ];

    const handleOnChange = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newInputValue = { ...inputValue };
        newInputValue[field] = value;
        console.log(newInputValue);
        setInputValue(newInputValue);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <Container sx={ { width: '80%' } } >
            {/* Profile */ }
            <Box className={ `${styles.topContainer}` } sx={ { display: "flex", my: 3 } }>
                <Typography>
                    <AssignmentIcon className={ `${styles.assignmentIcon}` } />{ " " }
                </Typography>
                <Typography>
                    <span style={ { fontSize: "26px" } }>My Profile</span> <br />{ " " }
                    {/* <span style={{ color: "#969494" }}>Basic info, for a faster booking experience</span> */ }
                </Typography>
            </Box>
            <Link to='/user/EditProfile'>
                <Box sx={ { textAlign: "right", my: 2 } }>
                    <Button startIcon={ <EditIcon /> } className={ `${styles.addEmployeeBtn}` }>Edit</Button>
                </Box>
            </Link>
            <Box sx={ { mb: '40px' } } className={ `${styles.tableContainer}` }>
                <Typography sx={ { fontSize: '25px', fontWeight: 'bold' } }>Profile</Typography>
                {/* <p>Basic info, for a faster booking experience</p> */ }

                {/* <Typography>
                    <img style={{ borderRadius: '50%', border: '1px solid black' }} src={user?.photoURL || img} alt="" />
                    <p>Mahfuz Rahman</p>
                </Typography> */}

                <Box sx={ { flexGrow: 1 } }>
                    <Grid container spacing={ 2 }>
                        <Grid item xs={ 5 }>
                            <img style={ { borderRadius: '50%', border: '1px solid black' } } src={ user?.photoURL || img } alt="" />
                        </Grid>
                        <Grid sx={ { display: 'flex' } } item xs={ 7 }>
                            <Typography sx={ { paddingRight: '5px' } }>
                                <ErrorOutlineIcon />
                            </Typography>
                            {/* <p style={{ fontWeight: 'bolder' }}>Basic info, for a faster booking experience</p> */ }
                            <Typography sx={ { fontWeight: 'bolder' } }>
                                Basic info, for a faster booking experience
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/*   <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Box sx={{ display: 'flex' }}>
                            <Typography>
                                <img style={{ borderRadius: '50%', border: '1px solid black', maxWidth: '100%' }} src={user?.photoURL || img} alt="" />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >
                        <Typography >
                            {user?.email}
                        </Typography>
                    </Grid>
                </Grid> */}

                <Grid sx={ { mt: 5 } } item xs={ 12 } md={ 6 }>
                    <form  >
                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="Your Name"
                            name="travellerName"
                            type="text"
                            // onBlur={handleOnBlur}
                            defaultValue={ user?.displayName }
                            variant="standard"
                            onChange={ handleOnChange }
                        // value={inputValue}

                        ></TextField>
                        {/* <Typography>{user?.displayName}</Typography> */ }
                        <TextField
                            sx={ { width: '100%', m: 1, } }
                            id="standard-basic"
                            label="Your Email"
                            name="email"
                            type="text"
                            defaultValue={ user?.email }
                            onChange={ handleOnChange }
                            variant="standard"

                        />
                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="Your Address"
                            name="address"
                            type="text"
                            variant="standard"
                            onChange={ handleOnChange }
                        />

                        {/* gender text field start */ }
                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-select-currency-native"
                            name="gender"
                            type="text"
                            select
                            label="Your Gender"
                            onChange={ handleOnChange }
                            // defaultValue="EUR"
                            SelectProps={ {
                                native: true,
                            } }
                            variant="standard"
                        >
                            { currencies.map((option) => (
                                <option key={ option.value } value={ option.value }>
                                    { option.value }
                                </option>
                            )) }
                        </TextField>
                        {/* gender text field end */ }

                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-select-currency-native"
                            select
                            label="Marital status"
                            name="martialStatus"
                            type="text"
                            onChange={ handleOnChange }
                            SelectProps={ {
                                native: true,
                            } }
                            variant="standard"
                        >
                            { maritalStatus.map((option) => (
                                <option key={ option.value } value={ option.value }>
                                    { option.value }
                                </option>
                            )) }
                        </TextField>

                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="Date Of Birth"
                            type="date"
                            name="dateOfBirth"
                            InputLabelProps={ {
                                shrink: true,
                            } }
                            variant="standard"
                            onChange={ handleOnChange }
                        />

                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="Passport Number"
                            type="text"
                            name="passport number"
                            variant="standard"
                            onChange={ handleOnChange }
                        />
                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="Passport Expiry Date"
                            type="date"
                            name="passportExpiryDate"
                            InputLabelProps={ {
                                shrink: true,
                            } }
                            variant="standard"
                            onChange={ handleOnChange }
                        />
                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-basic"
                            label="National Id Number"
                            type="text"
                            name="idNum"
                            variant="standard"
                            onChange={ handleOnChange }
                        />

                        <MuiPhoneNumber
                            sx={ { width: '100%', m: 1 } }
                            defaultCountry={ 'bd' }
                            type="text"
                            name="phoneNumber"
                            label="Phone Number"
                        />


                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-select-currency-native"
                            select
                            label="Religon"
                            name="religon"
                            type="text"
                            onChange={ handleOnChange }
                            SelectProps={ {
                                native: true,
                            } }
                            variant="standard"
                        >
                            { religion.map((option) => (
                                <option key={ option.value } value={ option.value }>
                                    { option.value }
                                </option>
                            )) }
                        </TextField>

                        <TextField
                            sx={ { width: '100%', m: 1 } }
                            id="standard-select-currency-native"
                            select
                            label="Language Preference
                            "
                            name="languagePreference
                            "
                            type="text"
                            onChange={ handleOnChange }
                            SelectProps={ {
                                native: true,
                            } }
                            variant="standard"
                        >
                            { languagePreference.map((option) => (
                                <option key={ option.value } value={ option.value }>
                                    { option.value }
                                </option>
                            )) }
                        </TextField>

                    </form>

                </Grid>
                {/* <Grid item xs={12} md={6}>
                        <img style={{ width: '30%', borderRadius: '50%', marginLeft: '200px', border: '1px solid black' }} src={user?.photoURL || img} alt="" />
                    </Grid> */}

            </Box>

        </Container>
    );
};

export default TravellerTest;