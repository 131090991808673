import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookingShcedules from './BookingShcedules';
import FlightDetails from './FlightDetails';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton { ...other } />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const BookingCard = () => {
    const [expandedOne, setExpandedOne] = React.useState(true);
    const [expandedTwo, setExpandedTwo] = React.useState(true);


    // const { selectedFlight } = useValue()
    // const selectedFlight = JSON.parse(window.localStorage.getItem('selectedFlight'));
    // const allData = JSON.parse(window.localStorage.getItem('allData'));
    // const theFlight = selectedFlight;
    // const allFlightData = allData;
    // const itinerariesData = allFlightData?.groupedItineraryResponse?.itineraryGroups[0]?.itineraries;
    // const legDescs = allFlightData?.groupedItineraryResponse?.legDescs;

    // const scheduleDescs = allFlightData?.groupedItineraryResponse?.scheduleDescs;

    // console.log(theFlight, allFlightData)

    const handleExpandClick = () => {
        setExpandedOne(!expandedOne);
    };
    const handleFlightDetailsExpandClick = () => {
        setExpandedTwo(!expandedTwo);
    };






    return (<>
        <Card sx={ { mb: 3 } }>
            <CardActions disableSpacing
                sx={ { borderBottom: '2px solid #333' } }

                onClick={ handleExpandClick }
                aria-expanded={ expandedOne }
                aria-label="show more"
            >
                <Typography variant="body2" color="text.secondary" fontWeight={ 900 } fontSize={ 15 }>
                    Shcedules Details
                </Typography>
                <ExpandMore
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={ expandedOne } timeout="auto" unmountOnExit>
                <CardContent >
                    <BookingShcedules />

                    <Typography paragraph>



                    </Typography>

                </CardContent>
            </Collapse>
        </Card>
        <Card sx={ {} }>
            <CardActions disableSpacing
                sx={ { borderBottom: '2px solid #333' } }
                // expand={ expandedTwo }
                onClick={ handleFlightDetailsExpandClick }
                aria-expanded={ expandedTwo }
                aria-label="show more"
            >
                <Typography variant="body2" color="text.secondary" fontWeight={ 900 } fontSize={ 15 }>
                    Flight Details
                </Typography>
                <ExpandMore >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={ expandedTwo } timeout="auto" unmountOnExit>
                <CardContent>
                    <FlightDetails />

                </CardContent>
            </Collapse>
        </Card>
    </>
    );
};




export default BookingCard;