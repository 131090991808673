import { Avatar, Box, CardActionArea, Grid, Link, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { textAlign } from '@mui/system';
import FlightOptions from '../FlightOption/FlightOptions/FlightOptions';
import AvailableFlight from '../AvailableFlight/AvailableFlight';
import { useLocation } from 'react-router-dom';
import useValue from '../../../../../hooks/useValue';

const AvailableFlights = ({ itinerariesDatas, filteredArrays }) => {

    // console.log(pathDir)
    const { availableFlightsFilter, selectedAvailableFlightsFilter, setSelectedAvailableFlightsFilter, setAvailableFlightsFilter } = useValue({});
    // console.log(selectedAvailableFlightsFilter)

    useEffect(() => {
        const marketingCounts = itinerariesDatas && itinerariesDatas.reduce((counts, data) => {
            const countedMarketing = [];
            data.legs.forEach(leg => {
                leg.schedules.schedules.forEach(schedule => {
                    const marketing = schedule.carrier.marketing;
                    if (!countedMarketing.includes(marketing)) {
                        const index = counts.findIndex(count => count.marketing === marketing);
                        if (index === -1) {
                            counts.push({ marketing: marketing, count: 1 });
                        } else {
                            counts[index].count++;
                        }
                        countedMarketing.push(marketing);
                    }
                });
            });
            return counts;
        }, []);

        setAvailableFlightsFilter(marketingCounts)
    }, [itinerariesDatas, setAvailableFlightsFilter])

    const handleMarketingClick = (marketing) => {
        if (selectedAvailableFlightsFilter.includes(marketing)) {
            setSelectedAvailableFlightsFilter(selectedAvailableFlightsFilter.filter(code => code !== marketing));
        } else {
            setSelectedAvailableFlightsFilter([...selectedAvailableFlightsFilter, marketing]);
        }
    }




    return (
        <Box>
            <Box sx={ {
                width: '100%',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'space-between',
                height: '30px'


            } }>
                <Typography variant="body1" gutterBottom sx={ {
                    fontSize: { xs: '.7rem', sm: '.8rem', md: '1rem', lg: '1rem' }
                } }>
                    { filteredArrays && filteredArrays?.length } Out Of { itinerariesDatas && itinerariesDatas?.length } Available Flights
                </Typography>
                <Typography variant="caption" gutterBottom sx={ {
                    fontSize: { xs: '.6rem', sm: '.7rem', md: '.8rem', lg: '.9rem' }
                } }>
                    *Price includes VAT & Tax
                </Typography>
            </Box>

            {/* <AvailableFlight /> */ }
            <Paper elevation={ 3 } sx={ {
            } } >

                <Box sx={ {
                    display: 'flex',
                    overflowX: 'scroll'
                } }>

                    { availableFlightsFilter &&
                        availableFlightsFilter.map((availableFlightsFilterData, index) => (
                            <Grid key={ index } container sx={ {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                minWidth: '110px',
                                maxWidth: '120px',
                                border: `${selectedAvailableFlightsFilter.includes(availableFlightsFilterData.marketing) ? '1px solid #333' : '1px solid rgba(166, 166, 175, 0.40)'}`,
                                height: '50px',
                                p: 0.5,

                                "&:hover": {
                                    border: '1px solid #333',
                                },
                            } }
                                onClick={ () => handleMarketingClick(availableFlightsFilterData.marketing) }
                                className={ selectedAvailableFlightsFilter.includes(availableFlightsFilterData.marketing) ? 'selected' : '' }
                            >
                                <Grid item xs={ 4 }>
                                    <Typography variant="body1" gutterBottom component="p" sx={ { p: 0, m: 0 } }>
                                        { availableFlightsFilterData.marketing }
                                    </Typography>
                                </Grid>
                                <Grid item xs={ 8 }>
                                    <Avatar alt="Remy Sharp" src={ `https://pics.avs.io/250/80/${availableFlightsFilterData.marketing}@2x.png` } variant="square" sx={ { width: '100%', height: 24 } } />
                                    <Typography variant="caption" gutterBottom component="p" sx={ { p: 0, m: 0 } }>
                                        { availableFlightsFilterData.count } Flight
                                    </Typography>
                                </Grid>

                            </Grid>
                        ))
                    }





                </Box>

            </Paper>

            {/* <FlightOptions /> */ }

        </Box >
    );
};

export default AvailableFlights;