import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import img from '../../../assets/images/fevicon.png';
import EditIcon from "@mui/icons-material/Edit";
import { Box } from '@mui/system';
import styles from './ManageProfileList.module.css';
import AssignmentIcon from "@mui/icons-material/Assignment";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MuiPhoneNumber from 'material-ui-phone-number';
import Loading from '../../../components/Loading/Loading';
import useAuth from '../../../hooks/useAuth';
import axios, { axiosPrivate } from '../../../api/axios';
import useFetch from '../../../context/useFetch';
const ManageProfileList = () => {
    const { user, isLoading } = useAuth();
    const [inputValue, setInputValue] = useState({});
    const [editAndUpdate, setEditAndUpdate] = useState('edit');
    // console.log(user.email)
    const { privateData } = useFetch(`/api/v1/othooyUsers/userProfile/${user.email}`);

    const data = privateData;
    // console.log('privateData', privateData);
    const genderType = [
        { value: 'Male' },
        { value: 'Female' },
        { value: 'Others' },
    ];

    const maritalStatus = [
        { value: 'Single' },
        { value: 'Married' },
        { value: 'Widowed' },
        { value: 'Divorced' },

    ];

    const religion = [
        { value: 'Islam' },
        { value: 'Christianity' },
        { value: 'Hinduism' },
        { value: 'Buddhism' },

    ];

    const languagePreference
        = [
            { value: 'Good' },
            { value: 'Average' },
            { value: 'Excelent' },
            { value: 'Outstanding' },

        ];

    const handleOnChange = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newInputValue = { ...inputValue, ...data };
        newInputValue[field] = value;
        // console.log(newInputValue);
        setInputValue(newInputValue);
    }

    const handlePhoneNumberChange = (field, value) => {
        const newInputValue = { ...data, ...inputValue };
        newInputValue[field] = value;

        setInputValue(newInputValue);
        // console.log(newInputValue);
        // console.log(value)
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();

        // Send the data to the backend
        /*  axios.put(`/api/v1/othooyUsers/userProfile/${user.email}`, inputValue)
             .then((response) => {
                 console.log('Object updated or inserted successfully:', response.data);
             })
             .catch((error) => {
                 console.error('Error updating or inserting object:', error);
             }); */
        try {
            // Send update request to the backend API
            const response = await axiosPrivate.put(`/api/v1/othooyUsers/userProfileUpdate/${user.email}`, inputValue);
            // console.log(response.data);
            setInputValue(response.data.profile);
            setEditAndUpdate('edit');
            // Update UI or perform any necessary actions
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };
    return (

        <Container sx={{ width: '80%' }} >
            {/* Profile */}
            <Box className={`${styles.topContainer}`} sx={{ display: "flex", my: 3 }}>
                <Typography>
                    <AssignmentIcon className={`${styles.assignmentIcon}`} />{" "}
                </Typography>
                <Typography>
                    <span style={{ fontSize: "26px" }}>My Profile </span> <br />{" "}
                    {/* <span style={{ color: "#969494" }}>Basic info, for a faster booking experience</span> */}
                </Typography>
            </Box>
            {/* <Link to='/user/EditProfile'> */}
            <Box sx={{ textAlign: "right", my: 2 }}>
                {
                    editAndUpdate === 'edit' ? <Button onClick={(e) => setEditAndUpdate('update')} startIcon={<EditIcon />} className={`${styles.addEmployeeBtn}`}>Edit</Button> :
                        editAndUpdate === 'update' ?
                            <Button onClick={handleUpdateSubmit} startIcon={<EditIcon />} className={`${styles.addEmployeeBtn}`}>Update</Button> : ''
                }

            </Box>
            {/* </Link> */}
            <Box sx={{ mb: '40px' }} className={`${styles.tableContainer}`}>
                <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }}>Profile</Typography>


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <img src={user?.photoURL || img} alt="" width={'70px'} />
                            {/* style={{ borderRadius: '50%', border: '1px solid black', }} */}
                        </Grid>
                        <Grid sx={{ display: 'flex' }} item xs={12} sm={4}>
                            <Typography sx={{ paddingRight: '5px', textAlign: 'center' }}>
                                <ErrorOutlineIcon sx={{ display: { xs: "none", sm: "block" } }} />
                            </Typography>
                            <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                                Basic info, for a faster booking experience
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>



                <Grid sx={{ mt: 5 }} item xs={12} md={6}>
                    <form  >
                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="Your Name"
                            name="userName"
                            type="text"

                            defaultValue={user?.displayName}

                            variant="standard"
                            onChange={handleOnChange}
                            // value={inputValue}
                            // disabled={ editAndUpdate !== 'update' && true }
                            disabled={true}

                        >

                        </TextField>
                        {/* <Typography>{user?.displayName}</Typography> */}
                        <TextField
                            sx={{ width: '100%', m: 1, }}
                            id="standard-basic"
                            label="Your Email"
                            name="email"
                            type="text"
                            defaultValue={user?.email}
                            onChange={handleOnChange}
                            variant="standard"
                            // disabled={ editAndUpdate !== 'update' && true }
                            disabled={true}

                        />
                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="Your Address"
                            name="address"
                            type="text"
                            variant="standard"
                            value={inputValue.address || data.address}
                            onChange={handleOnChange}
                            disabled={editAndUpdate !== 'update' && true}
                        />

                        {/* gender text field start */}
                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-select-currency-native"
                            name="gender"
                            type="text"
                            select
                            label="Your Gender"
                            value={inputValue.gender || data.gender}
                            onChange={handleOnChange}
                            // defaultValue="EUR"
                            SelectProps={{
                                native: true,
                            }}
                            variant="standard"
                            disabled={editAndUpdate !== 'update' && true}
                        >
                            {genderType.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </TextField>
                        {/* gender text field end */}

                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-select-currency-native"
                            select
                            label="Marital status"
                            name="martialStatus"
                            type="text"
                            value={inputValue.martialStatus || data.martialStatus}
                            onChange={handleOnChange}
                            SelectProps={{
                                native: true,
                            }}
                            variant="standard"
                            disabled={editAndUpdate !== 'update' && true}
                        >
                            {maritalStatus.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="Date Of Birth"
                            type="date"
                            name="dateOfBirth"
                            value={inputValue.dateOfBirth || data.dateOfBirth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            onChange={handleOnChange}
                            disabled={editAndUpdate !== 'update' && true}
                        />

                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="Passport Number"
                            type="text"
                            name="passportNumber"
                            variant="standard"
                            onChange={handleOnChange}
                            value={inputValue.passportNumber || data.passportNumber}
                            disabled={editAndUpdate !== 'update' && true}
                        />
                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="Passport Expiry Date"
                            type="date"
                            name="passportExpiryDate"
                            value={inputValue.passportExpiryDate || data.passportExpiryDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            onChange={handleOnChange}
                            disabled={editAndUpdate !== 'update' && true}
                        />
                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-basic"
                            label="National Id Number"
                            type="text"
                            name="NationalIdNumber"
                            value={inputValue.NationalIdNumber || data.NationalIdNumber}
                            variant="standard"
                            onChange={handleOnChange}
                            disabled={editAndUpdate !== 'update' && true}
                        />

                        <MuiPhoneNumber
                            defaultCountry={'bd'} //'bd'
                            variant="standard"
                            sx={{ width: '100%', ml: '10px' }}
                            label="Phone Number"
                            required={true}
                            value={inputValue.phoneNumber || data.phoneNumber}
                            disabled={editAndUpdate !== 'update' && true}
                            onChange={value => handlePhoneNumberChange('phoneNumber', value)}
                        />


                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-select-currency-native"
                            select
                            label="Religon"
                            name="religon"
                            type="text"
                            onChange={handleOnChange}
                            value={inputValue.religon || data.religon}
                            SelectProps={{
                                native: true,
                            }}
                            variant="standard"
                            disabled={editAndUpdate !== 'update' && true}
                        >
                            {religion.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            sx={{ width: '100%', m: 1 }}
                            id="standard-select-currency-native"
                            select
                            label="Language Preference"
                            name="languagePreference"
                            value={inputValue.languagePreference || data.languagePreference}
                            type="text"
                            onChange={handleOnChange}
                            SelectProps={{
                                native: true,
                            }}
                            variant="standard"
                            disabled={editAndUpdate !== 'update' && true}
                        >
                            {languagePreference.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                        </TextField>

                    </form>

                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <img style={{ width: '30%', borderRadius: '50%', marginLeft: '200px', border: '1px solid black' }} src={user?.photoURL || img} alt="" />
                </Grid> */}

            </Box>

        </Container>

    );
};

export default ManageProfileList;

// <Container>
//     <Grid container spacing={2}>
//         <Grid sx={{ mt: "10px" }} item xs={12} md={6}>
//             <img style={{ width: '20%', borderRadius: '50%', mt: '10px' }} src={img} alt="" />
//         </Grid>
//         <Grid item xs={12} md={6}>

//         </Grid>

//     </Grid>
// </Container >