import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import Loading from "../../../../components/Loading/Loading";

const AdminRoute = ({ children }) => {
    const { user, admin, isLoading } = useAuth();
    const location = useLocation();
    if (isLoading) {
        return <Loading></Loading>
    }
    if (user?.email && !admin) {
        return <Navigate to='/' state={ { from: location } } replace></Navigate>
    }
    return children;

};

export default AdminRoute;
