import { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, updateProfile, getIdToken, signOut, onIdTokenChanged } from "firebase/auth";
import initializeFirebase from "../components/UserLogin/firebase.init";
import Cookies from "js-cookie";

//initalizeFirebase

initializeFirebase();


const BASE_URL = process.env.REACT_APP_NODE_API_baseURL;

const useFirebase = () => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [authError, setAuthError] = useState('');
    const [token, setToken] = useState('');
    const [admin, setAdmin] = useState(false);

    const auth = getAuth();
    const googleProvider = new GoogleAuthProvider();

    const registeredUser = (email, password, name, navigate) => {
        setIsLoading(true);
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setAuthError('');
                const newUser = { email, displayName: name };
                setUser(newUser);
                //save user to database
                saveUser(email, name, 'PUT');
                //send name to firebase after creation
                updateProfile(auth.currentUser, {
                    displayName: name
                    //displayName: "Jane Q. User", photoURL: "https://example.com/jane-q-user/profile.jpg"
                }).then(() => {

                }).catch((error) => {

                });
                navigate('/');

            })
            .catch((error) => {
                setAuthError(error.message);
                console.log(error);

            })
            .finally(() => setIsLoading(false));
    }


    const loginUser = (email, password, location, navigate) => {
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                const loggedUser = {
                    email: user.email
                }
                // console.log(user.accessToken);

                fetch(`http://localhost:7000/user-jwt`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(loggedUser)
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log('JWT RESPONSE :', data);
                        localStorage.setItem('othooy-user-token', data.token);
                    })
                // const destination = location?.state?.from || '/';
                // navigate(destination);
                // const destination = location.state?.from || '/';
                // navigate.replace(destination)
                // navigate(destination, { replace: true });
                setAuthError('');
            })
            .catch((error) => {
                setAuthError(error.message);
            })
            .finally(() => setIsLoading(false));
    }

    const signInWithGoogle = (location, navigate) => {
        setIsLoading(true);
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                const user = result.user;
                saveUser(user.email, user.displayName, 'PUT')
                setAuthError('');
                //save user to database

            }).catch((error) => {
                setAuthError(error.message)
                    .finally(() => setIsLoading(false));
            });
    }

    //observe user state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                getIdToken(user)
                    .then(idToken => {
                        // console.log(idToken);
                        setToken(idToken);
                        Cookies.set('accessToken', idToken, { secure: true, sameSite: 'false' });
                    })
                setUser(user);
            }
            else {
                setUser({})
            }
            setIsLoading(false);
        });
        return () => unsubscribe;
    }, [auth])


    /*     useEffect(() => {
            fetch(`${process.env.REACT_APP_NODE_API_baseURL}/login/${user?.email}`)
                .then(res => res.json())
                .then(data => setAdmin(data.admin))
        }, [user.email]) */



    const logout = () => {
        setIsLoading(true);
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.removeItem('othooy-user-token');
        }).catch((error) => {
            // An error happened.
        })
            .finally(() => setIsLoading(false));
        // Clear the access token cookie
        Cookies.remove('accessToken');
    }
    // const baseUrl = 'http://localhost:7000';
    const baseUrl = BASE_URL;
    const saveUser = (email, displayName, method) => {
        const user = { email, displayName };
        // console.log(user)
        // fetch(`${process.env.REACT_APP_NODE_API_baseURL}/api/v1/othooyUsers/login/${email}`, {
        fetch(`${baseUrl}/api/v1/othooyUsers/login/${email}`, {
            // fetch(`${baseUrl}/api/v1/othooyUsers/login/syedhasib01@gmail.com`, {
            method: method,
            headers: {
                // 'authorization': `Bearer ${token} ${email}`,
                'authorization': `Bearer ${token}`,
                // 'authorization': `Bearer ${localStorage.getItem('idToken')}`,
                'content-type': 'application/json'
            },
            body: JSON.stringify(user)
        })
            .then(response => {
                // Handle the response
                // console.log(response)
            })
            .catch(error => {
                // Handle the error
                // console.log(error)
            });
    }
    useEffect(() => {
        const auth = getAuth();

        const unsubscribeAuthState = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                // Set the user state
                setUser(firebaseUser);

                // Get the ID token
                const idToken = await getIdToken(firebaseUser);
                // console.log('idToken', idToken)
                // Save the ID token to cookies
                Cookies.set('accessToken', idToken, { secure: true, sameSite: 'none' });
            } else {
                // Clear the user state and cookies
                setUser(null);
                Cookies.remove('accessToken');
            }
        });

        const unsubscribeIdToken = onIdTokenChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                // Get the new ID token
                const idToken = await getIdToken(firebaseUser);

                // Save the new ID token to cookies
                Cookies.set('accessToken', idToken, { secure: true, sameSite: 'none' });
            }
        });

        return () => {
            unsubscribeAuthState();
            unsubscribeIdToken();
        };
    }, [])



    return {
        user,
        admin,
        isLoading,
        token,
        authError,
        registeredUser,
        signInWithGoogle,
        loginUser,
        logout
    }
}

export default useFirebase;

