import React from 'react';
import styles from './ETicket.module.css';
import logo from '../../../../../assets/images/logo.png';
import logo1 from '../../../../../assets/images/logo.png';

const ETicket = () => {
    return (
        <div>
            <section className={ `${styles.mainPdWrapper}` }>
                <main>
                    <div className={ `${styles.topper}` }>
                        <div className={ `${styles.subTop1}` }>
                            <img style={ { height: '50px', width: '140px' } } src={ logo } alt="" />
                            <p>www.othooy.com</p>
                        </div>
                        <hr />
                    </div>

                    <div className={ `${styles.topperNext}` }>
                        <div>
                            <p>E Ticket</p>
                        </div>
                        <div>
                            <p>Emergency Contact</p>
                            <p>+88656546465</p>
                        </div>
                    </div>

                    {/* passenger information start */ }
                    <div className={ `${styles.passengerCollection}` }>
                        <p style={ { fontSize: '10px' } }>Passenger Information</p>

                        <div className={ `${styles.passangerInormation}` }>
                            <p>Name</p>
                            <p>Passenger Type</p>
                            <p>e-Ticket Number</p>
                            <p>Airline Pnr</p>
                            <p>Booking ID</p>
                            <p>Ticket Issue Date</p>
                        </div>
                        <div className={ `${styles.passangerProperties1}` }>
                            <p>MR. MD ARIFUR RAHMAN</p>
                            <p>Adult</p>
                            <p>OSYQHT</p>
                            <p>OSYQHT</p>
                            <p>FB-SV2304100852</p>
                            <p>Mon, 10 Apr, 23</p>
                        </div>
                        <div className={ `${styles.passangerProperties1}` }>
                            <p>MR. MD ARIFUR RAHMAN</p>
                            <p>Adult</p>
                            <p>OSYQHT</p>
                            <p>OSYQHT</p>
                            <p>FB-SV2304100852</p>
                            <p>Mon, 10 Apr, 23</p>
                        </div>
                    </div>




                    {/* passenger information end */ }
                    <br />

                    {/* flight informatio start */ }
                    <div className={ `${styles.passengerCollection}` }>
                        <div className={ `${styles.flightInfo}` }>
                            <div>
                                <p>Ticket Itinerary</p>
                                <p>Kolkata (CCU) → Jaipur (JAI)</p>
                            </div>
                            <div>
                                <img style={ { height: '50px', width: '80px' } } src={ logo1 } alt="" />
                            </div>
                        </div>

                        <div class={ `${styles.passangerInormation}` }>
                            <p>Name</p>
                            <p>Passenger Type</p>
                            <p>e-Ticket Number</p>
                            <p>Airline Pnr</p>
                            <p>Booking ID</p>
                            <p>Ticket Issue Date</p>
                        </div>
                        <div class={ `${styles.passangerProperties1}` }>
                            <p>MR. MD ARIFUR RAHMAN</p>
                            <p>Adult</p>
                            <p>OSYQHT</p>
                            <p>OSYQHT</p>
                            <p>FB-SV2304100852</p>
                            <p>Mon, 10 Apr, 23</p>
                        </div>

                        <div className={ `${styles.passangerProperties1}` }>
                            <p>MR. MD ARIFUR RAHMAN</p>
                            <p>Adult</p>
                            <p>OSYQHT</p>
                            <p>OSYQHT</p>
                            <p>FB-SV2304100852</p>
                            <p>Mon, 10 Apr, 23</p>
                        </div>

                    </div>
                    <br /><br />

                    {/* sub total start */ }
                    <div className={ `${styles.paymentCollection}` }>
                        <div class={ `${styles.payment}` }>
                            <p>Sub Total: <span className={ `${styles.amount}` }>BDT 25,039</span></p>
                            <p>Discount: <span className={ `${styles.amount}` }>BDT 1,278</span></p>
                            <p>Convience Charge <span className={ `${styles.amount}` }>BDT 501</span></p>
                            <hr />
                            <p>Total Payment</p>
                            <span className={ `${styles.amount}` }>BDT 24,262</span>
                        </div>
                    </div>
                    {/* sub total end */ }


                    <hr />
                    {/* flight informatio end */ }
                </main>
            </section>
        </div>
    );
};

export default ETicket;